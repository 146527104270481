import { Config } from '@backstage/config';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { stringify as stringifyQueryString } from 'qs';

export const licenseUsageApiRef = createApiRef<LicenseUsageApiInterface>({
  id: 'plugin.chargeback.licenseusage',
});

type LicenseUsageApiOptions = {
  configApi: Config;
  identityApi: IdentityApi;
};

enum filter {
  'jira',
  'confluence',
  'github',
  'ghe',
}
export interface ILicenseData {
  start_time: string;
  end_time: string;
  page: number;
  size: number;
  filter: filter;
}

export interface LicenseUsageApiInterface {
  getLicenseUsage(
    id: any,
    idToken: Object,
    paramsData?: ILicenseData,
  ): Promise<any>;
}

export class LicenseUsageApi implements LicenseUsageApiInterface {
  private configApi: Config;
  private identityApi: IdentityApi;
  private backendUrl: string;

  constructor(options: LicenseUsageApiOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
    this.backendUrl = `${this.configApi.getString(
      'backend.baseUrl',
    )}/api/chargeback`;
  }

  public async getLicenseUsage(
    id: any,
    idToken: Object,
    paramsData?: ILicenseData,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(`${this.backendUrl}/license/project/${id}`);
    requestUrl.search = stringifyQueryString(paramsData);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(idToken),
    });

    const data = await response.json();
    return data;
  }
}
