/**
 * returns the details of companies from their id
 * @param participantCompanies
 * @param allCompanies
 * @returns {[]}
 */
export const getCompaniesList = (
  participantCompanies: any[],
  allCompanies: any[],
) => {
  return participantCompanies.map(item => {
    const item2 =
      allCompanies.length > 0 &&
      allCompanies.find(i2 => i2.id === item.company_id);
    return item2 ? { ...item, ...item2 } : item;
  });
};
