import React from 'react';
import { Achievement as AchievementType } from 'usg-types';
import { logos, grayscaleLogos } from './assets/logos';
import { Typography, Tooltip } from '@material-ui/core';
import { useStyles } from './styles';

interface AchievementProps {
  achievement: AchievementType;
}

export const Achievement = (props: AchievementProps) => {
  const classes = useStyles();
  const { achievement } = props;
  const achievementImage = achievement.has
    ? logos[achievement.id]
    : grayscaleLogos[achievement.id];

  const tooltipBody = (
    <>
      <Typography variant="h4">
        {achievement.has ? achievement.title : 'Unachieved'}
      </Typography>
      <Typography>{props.achievement.description}</Typography>
    </>
  );

  return (
    <Tooltip title={tooltipBody}>
      <div className={classes.achievement}>
        <div className={classes.showblock}>
          {achievementImage ? (
            <img
              className={classes.image}
              src={achievementImage}
              alt={achievement.title}
            />
          ) : (
            <Typography>{achievement.title[0]}</Typography>
          )}
        </div>
      </div>
    </Tooltip>
  );
};
