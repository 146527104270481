import React from 'react';
import { useAsync } from 'react-use';
import { Typography } from '@material-ui/core';
import { Achievement as AchievementType } from 'usg-types';
import { useApi, errorApiRef } from '@backstage/core-plugin-api';
import { yakubiqueApiRef } from '@internal/plugin-yakubique';
import { Achievement } from '../Achievement';
import { useStyles } from './styles';

export const AchievementsPanel = () => {
  const yakubiqueApi = useApi(yakubiqueApiRef);
  const errorApi = useApi(errorApiRef);
  const classes = useStyles();

  const { value, loading, error } = useAsync(async (): Promise<
    AchievementType[]
  > => {
    const data = await yakubiqueApi.getAchievementsList();
    return data;
  }, []);

  if (loading) {
    return null;
  } else if (error) {
    errorApi.post(new Error(`${error}`));
  }

  return (
    <div className={classes.container}>
      <Typography variant="h6">Achievements</Typography>
      <div className={classes.wrapperDiv}>
        {value?.map(achievement => (
          <Achievement achievement={achievement} key={achievement.id} />
        ))}
      </div>
    </div>
  );
};
