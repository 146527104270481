import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles<USGTheme>(theme => ({
  container: {
    minHeight: '290px',
  },
  icon: {
    width: '44px',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: 'rgba(196, 196, 196, 0.8)',
    boxShadow: '0 0px 0px 6px rgba(196, 196, 196, 0.4)',
    margin: '6px',
    color: theme.palette.usgColors?.primary?.white,
  },
}));
