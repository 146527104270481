/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { AnalyticsTracker } from '@backstage/core-plugin-api';
import type { Transformer } from './transformer';
import { redactGAEventAttributes } from 'sg-utils-frontend';

type AddLinkClickListenerOptions = {
  baseUrl: string;
  onClick: (e: MouseEvent, newUrl: string) => void;
  analytics?: AnalyticsTracker;
};

export const addLinkClickListener = ({
  baseUrl,
  onClick,
  analytics,
}: AddLinkClickListenerOptions): Transformer => {
  return dom => {
    Array.from(dom.getElementsByTagName('a')).forEach(elem => {
      elem.addEventListener('click', (e: MouseEvent) => {
        const target = elem as HTMLAnchorElement;
        const href = target.getAttribute('href');
        if (!href) return;

        if (!elem.hasAttribute('download')) {
          if (href.startsWith(baseUrl)) {
            e.preventDefault();
            onClick(e, href);
          } else if (analytics) {
            const [processedHref, processedTextLabel] = redactGAEventAttributes(
              href,
              target.text,
            );

            analytics.captureEvent('Outbound Click', 'techdoclinks', {
              attributes: {
                href: processedHref,
                textLabel: processedTextLabel,
              },
            });
          }
        }
      });
    });

    return dom;
  };
};
