import React, { ReactElement } from 'react';
import { ContentHeader } from '@backstage/core-components';
import { PageLayout, Content } from '@internal/sg-ui-kit';

type LayoutProps = {
  title: string;
  children: ReactElement;
  className?: string;
};

export const Layout = (props: LayoutProps) => {
  return (
    <PageLayout
      type="entity"
      title="Stargate Multicloud"
      subtitle="Kubernetes Platform"
    >
      <Content>
        <ContentHeader title={props.title} />
        <div className={props.className}>{props.children}</div>
      </Content>
    </PageLayout>
  );
};
