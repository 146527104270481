import React, { useEffect, useMemo, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useAsyncFn, useEffectOnce } from 'react-use';

import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import { isAttachResourcesRoleRequired } from 'sg-utils-frontend';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import { VAULT_DEV_TOOL_ID, ADD_USER_WARNING_MESSAGE } from 'usg-types';

import { projectApiRef } from '../../api';
import { useViewEditResourceContext } from '../ViewEditResources/ViewEditResourcePage/context';
import { WarningMessage } from '../WarningMessage/WarningMessage';
import { getTotalUserCountByGroupAndProject } from '../../common/getTotalUserCountByGroupAndProject';
import { useStyles } from './styles';

type TUserGroupAttachment = {
  usergroup_id: string;
  role: string;
  usersCount: number;
};

const emptyAttachUserGroup = {
  usergroup_id: '',
  role: '',
  usersCount: 0,
};

const initalAttachUserGroups = [emptyAttachUserGroup];

export const AttachUserGroupToResource = (props: {
  isVisible: boolean;
  handleClose: () => void;
  projectId: string;
  userGroups: any;
  isAdmin: boolean;
  resourceData: any;
  handleSuccess: () => void;
  isGithubEMU?: boolean;
}) => {
  const {
    isVisible,
    handleClose,
    projectId,
    userGroups,
    handleSuccess,
    resourceData,
    isGithubEMU = false,
  } = props;

  const classes = useStyles();
  const projectApi = useApi(projectApiRef);
  const authRef = useApi(microsoftAuthApiRef);
  const errorApi = useApi(errorApiRef);
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const [attachError, setAttachError] = useState('');
  const [duplicateError, setDuplicateError] = useState('');
  const [isWarningMessageFlagEnabled, setIsWarningMessageFlagEnabled] =
    useState(false);

  const resourceContext = useViewEditResourceContext();

  const [userGroupAttachments, setUserGroupAttachments] = useState<
    TUserGroupAttachment[]
  >(initalAttachUserGroups);
  const [totalUsersCount, setTotalUsersCount] = useState<number>(0);

  const userGroupSortedByName = useMemo(
    () =>
      [...userGroups].sort((a: any, b: any) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      ),
    [userGroups],
  );

  const [{ loading: reqLoading = false as boolean }, attachResources] =
    useAsyncFn(
      async (request: any) => {
        const token = await authRef.getIdToken();
        // new api
        const userGroupRolesValues = request?.map((ug: any) => {
          return { id: parseInt(ug.id, 10), role: ug.role };
        });
        const paramsdata = {
          idToken: token,
          userGroupRoles: [
            {
              op: 'add',
              value: userGroupRolesValues,
            },
          ],
        };

        const res = await projectApi.assignUserGroupToResources(
          paramsdata,
          projectId,
          resourceData?.id,
        );
        if (res?.status === 200) {
          handleSuccess();
          setUserGroupAttachments(initalAttachUserGroups);
        } else {
          setAttachError(res?.data?.message);
        }

        handleClose();
      },
      [resourceData],
    );

  const hasDuplicateUserGroupIds = (
    attachedUserGroups: TUserGroupAttachment[],
  ) => {
    const seenUserGroupsSet = new Set();

    for (const userGroup of attachedUserGroups) {
      const { usergroup_id: usergroupId } = userGroup;

      if (seenUserGroupsSet.has(usergroupId)) {
        return true;
      }

      seenUserGroupsSet.add(usergroupId);
    }

    return false;
  };

  const handleResourceTypeChange = async (event: any, i: number) => {
    const updatedUserGroupAttachmentRequest = [...userGroupAttachments];
    updatedUserGroupAttachmentRequest[i] = {
      ...updatedUserGroupAttachmentRequest[i],
      [event.target.name]: event.target.value,
    };
    if (event.target.name === 'usergroup_id') {
      setDuplicateError('');
      if (hasDuplicateUserGroupIds(updatedUserGroupAttachmentRequest)) {
        setDuplicateError('You cannot assign the same resource twice.');
      } else {
        setUserGroupAttachments(updatedUserGroupAttachmentRequest);
        const isDynamicGroup =
          event.currentTarget.getAttribute('data-isDynamicGroup') === 'true';
        const currentUserCount = userGroupAttachments[i].usersCount;
        let usersCount = 0;

        if (!isDynamicGroup) {
          usersCount = await getTotalUserCountByGroupAndProject(
            projectApi,
            authRef,
            errorApi,
            event.target.value,
            projectId,
          );
        }

        setTotalUsersCount(
          prevTotalUsersCount =>
            prevTotalUsersCount - currentUserCount + usersCount,
        );

        updatedUserGroupAttachmentRequest[i] = {
          ...updatedUserGroupAttachmentRequest[i],
          usersCount,
        };
        setUserGroupAttachments(updatedUserGroupAttachmentRequest);
      }
    } else {
      setUserGroupAttachments(updatedUserGroupAttachmentRequest);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAttachError('');
      setUserGroupAttachments(initalAttachUserGroups);
      setTotalUsersCount(0);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [isVisible]);

  useEffectOnce(() => {
    (async () => {
      const warningMessageFlagRes = await featureFlagsApi.getBinaryFlag(
        ADD_USER_WARNING_MESSAGE,
      );
      setIsWarningMessageFlagEnabled(warningMessageFlagRes.data);
    })();
  });

  const resourceSelectRow = (
    <TransitionGroup>
      {userGroupAttachments.map(({ usergroup_id, role }, userGroupIndex) => (
        <Collapse>
          <div
            key={userGroupIndex}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '16px',
            }}
          >
            <FormControl variant="outlined" fullWidth>
              <Typography
                variant="button"
                component="div"
                style={{ marginBottom: '8px', textTransform: 'none' }}
                id="user-group-label"
              >
                User Group
              </Typography>
              <Select
                labelId="user-group-label"
                id="select-user-group"
                value={usergroup_id}
                name="usergroup_id"
                onChange={e => handleResourceTypeChange(e, userGroupIndex)}
                disabled={userGroups?.length === 0}
                displayEmpty
              >
                {userGroupSortedByName?.map((r: any) => (
                  <MenuItem
                    key={`ug_id_${r.id}`}
                    value={r.id}
                    title={r.name}
                    className={classes.resourceName}
                    data-isDynamicGroup={r.rule_based}
                  >
                    {r.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {resourceData?.roles?.length !== 0 && (
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginLeft: '16px' }}
              >
                <Typography
                  variant="button"
                  component="div"
                  style={{ marginBottom: '8px', textTransform: 'none' }}
                  id="role-label"
                >
                  Role
                </Typography>
                <Select
                  labelId="role-label"
                  id="select-role"
                  name="role"
                  value={role}
                  onChange={e => handleResourceTypeChange(e, userGroupIndex)}
                  displayEmpty
                  disabled={!usergroup_id}
                >
                  {resourceData?.roles?.map((r: any) => {
                    const { display_name, name } = r;

                    return (
                      <MenuItem key={`role_${name}`} value={name}>
                        {display_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}

            <IconButton
              disabled={reqLoading}
              className={classes.deleteIcon}
              onClick={() => {
                const { usersCount: deletedUserGroupCount } =
                  userGroupAttachments[userGroupIndex];
                setTotalUsersCount(
                  prevTotalUserCount =>
                    prevTotalUserCount - deletedUserGroupCount,
                );
                const updatedUserGroupAttachmentRequest = [
                  ...userGroupAttachments,
                ].filter((_, fi) => userGroupIndex !== fi);
                setUserGroupAttachments(updatedUserGroupAttachmentRequest);
                setAttachError('');
              }}
            >
              <DeleteIcon
                className={[
                  reqLoading ? classes.disabledIconColor : classes.iconColor,
                ].join(' ')}
              />
            </IconButton>
          </div>
        </Collapse>
      ))}
    </TransitionGroup>
  );

  const addResourceHandler = () => {
    setUserGroupAttachments([...userGroupAttachments, emptyAttachUserGroup]);
    setAttachError('');
  };

  // check if all roles are selected properly with required data
  const isRoleSelected = userGroupAttachments.every((item: any) => {
    // For non vault or when New vault ui feature flag is disabled then use the default checking for role requirement
    if (
      !resourceContext.isNewVaultUiEnabled ||
      resourceData?.dev_tool_id !== VAULT_DEV_TOOL_ID
    ) {
      return isAttachResourcesRoleRequired(
        resourceData?.dev_tool_id,
        item.usergroup_id,
        item.role,
      );
    }

    // for new vault ui feature flag enabled and the resource is vault then role and ug must exists
    return item.usergroup_id !== '' && item.role !== '';
  });

  const isAssignResourceDisable =
    userGroupAttachments.length === 0 ||
    !isRoleSelected ||
    reqLoading ||
    duplicateError !== '';

  return (
    <Dialog fullWidth maxWidth="md" open={isVisible} onClose={handleClose}>
      <DialogTitle>
        <div className={classes.dialogHeader}>
          Assign User groups
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
            arrow
            placement="top"
            title="Close Button"
          >
            <IconButton
              aria-label="close"
              className={classes.iconContainer}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent>
        {resourceSelectRow}
        {userGroups?.length === 0 && (
          <div className={classes.errorMsg}>
            There are no usergroups available to be assigned.
          </div>
        )}
        <Button
          fullWidth
          className={classes.hyperButton}
          color="default"
          onClick={addResourceHandler}
          disabled={reqLoading}
        >
          Add user group +
        </Button>
        {(attachError || duplicateError) && (
          <Typography
            style={{ marginTop: '8px', marginBottom: '8px', color: 'red' }}
          >
            Error: {attachError || duplicateError}
          </Typography>
        )}
        {isWarningMessageFlagEnabled && isGithubEMU && (
          <WarningMessage totalUsersCount={totalUsersCount} />
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          size="small"
          onClick={handleClose}
          disabled={reqLoading}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            const accessible_usergroups = userGroupAttachments.map(
              ({ usergroup_id, role }) => {
                return {
                  id: usergroup_id,
                  role,
                };
              },
            );
            attachResources(accessible_usergroups);
          }}
          disabled={isAssignResourceDisable}
        >
          ASSIGN User group
        </Button>
      </DialogActions>
    </Dialog>
  );
};
