import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useAsyncFn } from 'react-use';
import { useApi, microsoftAuthApiRef } from '@backstage/core-plugin-api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  IconButton,
  DialogTitle,
  Typography,
} from '@material-ui/core';

import { projectApiRef } from '../../api';
import { useStyles } from './styles';

export const DetachResource = (props: {
  isVisible: boolean;
  handleClose: any;
  handleSuccess: any;
  projectId: string;
  groupId: string;
  resourceId: number;
  resourceName: string;
  groupData: any;
  tab: string;
}) => {
  const {
    isVisible,
    handleClose,
    handleSuccess,
    projectId,
    groupId,
    resourceId,
    resourceName,
    groupData,
    tab,
  } = props;

  const classes = useStyles();
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const [detachError, setDetachError] = useState('');

  const [{ loading: reqLoading = false as boolean }, detachResources] =
    useAsyncFn(async () => {
      const token = await authref.getIdToken();
      const paramsdata = {
        idToken: token,
        userGroupRoles: [
          {
            op: 'remove',
            value: [{ id: parseInt(groupId, 10) }],
          },
        ],
      };
      try {
        const res: any = await projectApi.assignUserGroupToResources(
          paramsdata,
          projectId,
          resourceId.toString(),
        );

        const code = res?.status;
        if (code === 200) {
          handleSuccess();
          // close modal
          handleClose();
        } else {
          setDetachError(res?.data?.message);
        }
      } catch (e) {
        setDetachError(e.message);
      }
    }, [groupId, resourceId]);

  useEffect(() => {
    setTimeout(() => {
      setDetachError('');
    }, 1000);
  }, [isVisible]);

  const confirmMessage =
    tab === 'resources' ? (
      <Typography data-testid="confirm-message-1">
        Do you want to detach <b>“{resourceName}”</b> from group{' '}
        <b>“{groupData.name}”</b>?{' '}
      </Typography>
    ) : (
      <Typography data-testid="confirm-message-1">
        Do you want to detach <b>“{groupData.name}”</b> from resource{' '}
        <b>“{resourceName}”</b>?{' '}
      </Typography>
    );

  return (
    <Dialog open={isVisible} onClose={handleClose}>
      <DialogTitle>
        <div className={classes.dialogHeader}>
          Detach {tab === 'resources' ? 'Resource' : 'Usergroup'}
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
            arrow
            placement="top"
            title="Close Button"
          >
            <IconButton
              aria-label="close"
              className={classes.iconContainer}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {confirmMessage}
        <Typography>
          All members of this group may lose access to this resources.
        </Typography>
        {detachError && (
          <Typography
            style={{
              marginTop: '8px',
              marginBottom: '8px',
              color: 'red',
            }}
          >
            Error: {detachError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          size="small"
          onClick={handleClose}
          disabled={reqLoading}
        >
          CANCEL
        </Button>
        <Button
          id="btn-detach-resource-confirm"
          variant="contained"
          size="small"
          onClick={() => detachResources()}
          disabled={reqLoading}
        >
          DETACH {tab === 'resources' ? 'Resource' : 'Usergroup'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
