/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {
  Typography,
  //   ListItem,
  //   ListItemSecondaryAction,
  //   ListItemText,
  //   Switch,
  //   Tooltip,
} from '@material-ui/core';
import { useSidebarPinState } from '@backstage/core-components';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useStyles } from './styles';

/** @public */
export const UserSettingsPinToggle = () => {
  const { isPinned, toggleSidebarPinState } = useSidebarPinState();
  const classes = useStyles();

  return (
    <div className={classes.pinBarDesign}>
      {isPinned ? (
        <div
          onClick={() => toggleSidebarPinState()}
          className={classes.pinBar}
          role="button"
          tabIndex={0}
          aria-hidden="true"
        >
          <ArrowBackIosIcon />
          <Typography variant="h4">Un-Pin Sidebar</Typography>
        </div>
      ) : (
        <div
          className={classes.pinBar}
          onClick={() => toggleSidebarPinState()}
          role="button"
          tabIndex={0}
          aria-hidden="true"
        >
          <ArrowForwardIosIcon />
          <Typography variant="h4">Pin Sidebar</Typography>
        </div>
      )}
    </div>
  );
};
