import React from 'react';
import { Chip } from '@material-ui/core';
import { OperationalStatuses } from '../types';
import { usgTheme } from 'usg-theme';

type StatusChipProps = {
  status: OperationalStatuses;
};

export const statusStylesMap: {
  [value in OperationalStatuses]: Record<string, string>;
} = {
  under_maintenance: {
    background: usgTheme.palette.infoBackground,
    color: usgTheme.palette.infoText,
  },
  operational: {
    background: usgTheme.palette.success.dark,
    color: usgTheme.palette.common.white,
  },
  degraded_performance: {
    background: usgTheme.palette.warningBackground,
    color: usgTheme.palette.warningText,
  },
  partial_outage: {
    background: usgTheme.palette.warningBackground,
    color: usgTheme.palette.warningText,
  },
  major_outage: {
    background: usgTheme.palette.errorBackground,
    color: usgTheme.palette.errorText,
  },
};

const formatStatusString = (status: string): string =>
  status
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const StatusChip = ({ status }: StatusChipProps) => (
  <Chip
    style={{ ...statusStylesMap[status], margin: '0 8px' }}
    size="small"
    label={formatStatusString(status)}
  />
);
