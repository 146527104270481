import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  identityApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';

import { registerServiceRouteRef, rootRouteRef } from './routes';
import { ServiceCatalogApi, serviceCatalogApiRef } from './api';

export const catalogManagerPlugin = createPlugin({
  id: 'catalog-manager',
  routes: {
    root: rootRouteRef,
    registerService: registerServiceRouteRef,
  },
  apis: [
    createApiFactory({
      api: serviceCatalogApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
        gacApi: microsoftAuthApiRef,
      },
      factory: ({ configApi, identityApi, gacApi }) =>
        new ServiceCatalogApi({ configApi, identityApi, gacApi }),
    }),
  ],
});

export const RegisterServicePage = catalogManagerPlugin.provide(
  createRoutableExtension({
    name: 'RegisterServicePage',
    component: () =>
      import('./pages/RegisterServicePage').then(m => m.RegisterServicePage),
    mountPoint: registerServiceRouteRef,
  }),
);
