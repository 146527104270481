import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

const boxySize = 32;

export const useStyles = makeStyles((theme: USGTheme) => ({
  achievement: {
    display: 'flex',
    width: boxySize,
    height: boxySize,
    boxSizing: 'border-box',
  },
  showblock: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: 4,
    alignItems: 'center',
    backgroundColor: theme.palette.usgColors?.secondary?.silverGray,
    borderRadius: 4,
  },
  image: {
    display: 'flex',
    maxWidth: '100%',
    maxHeight: '100%',
    boxSizing: 'border-box',
  },
}));
