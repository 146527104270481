import React, { useEffect } from 'react';
import { useFieldArray, Controller, useForm } from 'react-hook-form';
import { Grid, Link, TextField, Typography } from '@material-ui/core';
import { ApiConfigResourceQuotas, KeyValue } from './types';
import AddIcon from '@material-ui/icons/Add';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import { Autocomplete } from '@material-ui/lab';
import { isEmptyOrNil } from './FormHelpers';
import { RESOURCE_QUOTA_KEYS } from './Constants';

type ResourceQuota = { key: string; value: string };
type EnvironmentQuotas = {
  [env: string]: ResourceQuota[];
};

// props schema
export type ResourceQuotasProps = {
  validationSetter: (isValid: boolean) => void;
  onChange: (value: KeyValue) => void;
  value: KeyValue;
  disabled?: boolean;
};

// Validation regex for resource quota values used in Kubernetes
// https://github.com/kubernetes/apimachinery/blob/master/pkg/api/resource/quantity.go#L149
const testValue = new RegExp(/^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$/);

export const ResourceQuotas = (props: ResourceQuotasProps) => {
  const {
    validationSetter,
    onChange: onHandleChange,
    value: resourceQuotaValues,
    disabled: isDisabled,
  } = props;

  const mapToQuotaArray: (
    quotas: ApiConfigResourceQuotas,
  ) => ResourceQuota[] = (quotas: ApiConfigResourceQuotas) => {
    return Object.entries(quotas).map(([key, value]) => {
      return { key, value };
    });
  };

  const quotaArrayToMap: (
    quotas: ResourceQuota[],
  ) => ApiConfigResourceQuotas = (quotas: ResourceQuota[]) => {
    return quotas.reduce((acc, o) => {
      if (!isEmptyOrNil(o.key) && !isEmptyOrNil(o.value)) {
        acc[o.key] = o.value;
      }
      return acc;
    }, {} as ApiConfigResourceQuotas);
  };

  const {
    control,
    register,
    getValues,
    getFieldState,
    formState: { isValid },
  } = useForm<EnvironmentQuotas>({
    defaultValues: { resource_quotas: mapToQuotaArray(resourceQuotaValues) },
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const { fields, insert, remove, append } = useFieldArray({
    control,
    name: 'resource_quotas',
  });

  useEffect(() => {
    validationSetter(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">Resource Quotas</Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer
                  sx={{ maxHeight: 440, height: 440, overflowY: 'scroll' }}
                >
                  <Table
                    size="small"
                    id="mtfuji-field-array-table"
                    stickyHeader
                    className="mtfuji-field-array-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Key</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell style={{ width: 160 }}>
                          <IconButton
                            id="mtfuji-field-array-table-button-append-row"
                            aria-label="add"
                            onClick={() => append({ key: '', value: '' })}
                            disabled={isDisabled}
                          >
                            <AddIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fields.map((field, index) => (
                        <TableRow key={field.id}>
                          <TableCell align="center" sx={{ display: 'flex' }}>
                            <Controller
                              control={control}
                              {...register(`resource_quotas.${index}.key`, {
                                onChange: e => {
                                  const newQuotas: ResourceQuota[] = getValues(
                                    'resource_quotas',
                                  ).map((f, i) => {
                                    return {
                                      key: index === i ? e.target.value : f.key,
                                      value: f.value,
                                    };
                                  });
                                  onHandleChange(quotaArrayToMap(newQuotas));
                                },
                              })}
                              // eslint-disable-next-line no-shadow
                              render={({ field: formField }) => (
                                <Autocomplete
                                  {...formField}
                                  fullWidth
                                  options={RESOURCE_QUOTA_KEYS}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      placeholder="Select a Resource Quota"
                                    />
                                  )}
                                  onChange={(_, data) => {
                                    formField.onChange(data);
                                  }}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              id={`mtfuji-field-array-input-${index}-value`}
                              type="text"
                              fullWidth
                              {...register(`resource_quotas.${index}.value`, {
                                pattern: {
                                  value: testValue,
                                  message:
                                    'Invalid resource quota value syntax',
                                },
                                onChange: e => {
                                  const newQuotas: ResourceQuota[] = getValues(
                                    'resource_quotas',
                                  ).map((f, i) => {
                                    return {
                                      key: f.key,
                                      value:
                                        index === i ? e.target.value : f.value,
                                    };
                                  });
                                  onHandleChange(quotaArrayToMap(newQuotas));
                                },
                              })}
                              disabled={isDisabled}
                              helperText={
                                getFieldState(`resource_quotas.${index}.value`)
                                  .error?.message || '\u00A0'
                              }
                              error={
                                getFieldState(`resource_quotas.${index}.value`)
                                  .error !== undefined
                              }
                            />
                          </TableCell>
                          <TableCell style={{ width: 160 }}>
                            <Grid container>
                              <Grid item>
                                <IconButton
                                  id={`mtfuji-field-array-button-${index}-insert-row`}
                                  aria-label="add"
                                  onClick={() =>
                                    insert(index + 1, { key: '', value: '' })
                                  }
                                  disabled={isDisabled}
                                >
                                  <AddIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <IconButton
                                  id={`mtfuji-field-array-button-${index}-remove-row`}
                                  aria-label="delete"
                                  disabled={isDisabled}
                                  onClick={() => {
                                    remove(index);
                                    onHandleChange(
                                      Object.fromEntries(
                                        getValues('resource_quotas')
                                          .filter(f => f.key.length > 0)
                                          .map(f => [f.key, f.value]),
                                      ),
                                    );
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="overline"
                component="p"
                color="textSecondary"
              >
                Refer to{' '}
                <Link
                  target="_new"
                  href="https://kubernetes.io/docs/concepts/policy/resource-quotas/"
                >
                  Kubernetes Documentation
                </Link>{' '}
                for more information on Resource Quotas.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
