import React from 'react';
import { Typography } from '@material-ui/core';

export const ArtifactoryGitHubWorkflowAccessNoteSection = (props: {
  name: string;
}) => {
  const { name } = props;
  return (
    <div style={{ margin: '20px' }}>
      <Typography component="div">
        Access to this Artifact ({name}) is provided through the following
        GitHub Workflows for either a GitHub Organization or GitHub Repository:
      </Typography>
      <Typography
        component="div"
        style={{ marginTop: '10px', fontWeight: 'bold' }}
      >
        Note: To utilize this feature, you must use the GitHub Action
        artifactory-access available{' '}
        <a
          href="/actions-catalog/artifactory-access"
          style={{ textDecoration: 'underline', color: '#307FFD' }}
          target="_blank"
        >
          here
        </a>
        .
      </Typography>
    </div>
  );
};
