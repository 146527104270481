import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    padding: theme.spacing(5),
    minHeight: 'calc(100vh - 310px)',
  },
  resourceFilterSection: {
    marginBottom: '10px',
    alignItems: 'center',
  },
  noteText: {
    fontSize: '12px',
  },
  downloadIcon: {
    marginLeft: 'auto',
    '& svg': {
      float: 'right',
      color: '#0D6AFF',
      cursor: 'pointer',
    },
  },
  downloadProgress: {
    marginLeft: 'auto',
    float: 'right',
    color: '#0D6AFF',
  },
  noteDiv: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    justifyContent: 'space-between',
  },
  monthDate: {
    top: '-1px',
    position: 'relative',
    '& input': {
      width: '230px',
      height: '39px',
      padding: '10px',
      fontSize: '16px',
    },
  },
  footerStyle: {
    borderTop: '1px solid #d5d5d5',
    borderBottom: '1px solid #d5d5d5',
    '& td': {
      fontSize: '13px',
      fontWeight: 700,
      color: 'black',
    },
  },
  applyBtn: {
    maxWidth: 'fit-content',
    display: 'flex',
    alignItems: 'center',
  },
  totalDiv: {
    display: 'flex',
    marginBottom: '10px',
  },
  totalText: {
    paddingRight: '50px',
  },
  totalTitle: {
    color: '#0D6AFF',
    fontWeight: 700,
  },
  resourceType: {
    marginBottom: '19px',
  },
  tooltip: {
    color: '#000000',
    backgroundColor: '#E6E6E6',
  },
  tooltipArrow: {
    color: '#E6E6E6',
  },
  navButton: {
    marginBottom: '19px',
  },
}));
