import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useAsyncFn } from 'react-use';
import {
  useApi,
  microsoftAuthApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { projectApiRef } from '../../api';
import { Progress } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router-dom';
import { projectPageRouteRef } from '../../routes';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles({
  successMsg: {
    color: 'green',
  },
  cancelBtn: {
    color: '#0F59CD',
    backgroundColor: 'white',
    outline: '1px solid #0F59CD',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  },
  requestAccessBtn: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '20px',
  },
});

type Props = {
  selectedGroupItems?: any;
  projectId: string;
};
export const OwnerPageActions = ({ selectedGroupItems, projectId }: Props) => {
  const classes = useStyles();
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const errorApi = useApi(errorApiRef);
  const projectPageRoute = useRouteRef(projectPageRouteRef);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  // Calling the create user membership request api
  const [{ loading, error }, fetchContent] = useAsyncFn(async () => {
    const token = await authref.getIdToken();
    const params = {
      idToken: token,
      user_memberships: selectedGroupItems,
      project_id: parseInt(projectId, 10),
    };
    try {
      await projectApi.createUserMembershipRequestsV2Data(params, projectId);
      setOpen(true);
      navigate(projectPageRoute({ projectId: projectId }));
    } catch (err) {
      errorApi.post(new Error(`${err?.message}`));
    }
  }, [selectedGroupItems]);

  if (loading) {
    return <Progress />;
  }

  if (error) {
    errorApi.post(new Error(`${error}`));
  }

  const handleApprove = async () => {
    fetchContent();
  };

  // Redirecting to project details page
  const handleClose = () => {
    navigate(projectPageRoute({ projectId: projectId }));
  };

  const handleOkButton = () => {
    navigate(projectPageRoute({ projectId: projectId }));
  };
  return (
    <Grid container spacing={2} style={{ paddingLeft: '2%', paddingTop: '2%' }}>
      <Grid item>
        <Button
          variant="contained"
          disabled={!selectedGroupItems.length}
          onClick={handleApprove}
        >
          APPROVE AND ADD TO GROUPS
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          className={classes.cancelBtn}
          href={`/projects/${projectId}`}
        >
          Close
        </Button>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.successMsg}>
          The request has been successfully approved.
        </DialogTitle>
        <DialogContent />
        <DialogActions>
          <Button
            variant="contained"
            className={classes.requestAccessBtn}
            onClick={handleOkButton}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
