import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  button: {
    marginLeft: theme.spacing(0.5),
  },
  lightButton: {
    color: theme.palette.usgColors.primary.white,
    '&:hover': {
      backgroundColor: 'rgba(230, 230, 230, 0.3)',
    },
  },
}));
