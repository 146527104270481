import React from 'react';
import { FieldInputProps, FormikProps, FormikValues } from 'formik';
import { INVALID_EMAIL_ERROR } from 'usg-types';
import { SGChip, SGChipInput } from '../SGChipInput';
import { getTechDocsLink } from 'sg-utils-frontend';

interface EmailChip extends SGChip {
  value: string;
  isValid?: boolean;
  invalidFromApi?: boolean;
}

type Props = {
  field: FieldInputProps<EmailChip[]>;
  form: FormikProps<FormikValues>;
  required?: boolean;
  patternMatch?: RegExp;
  checkValidity?: (chip: string) => Promise<boolean>;
  helperText?: string;
  errorText?: any;
  invalid?: boolean;
  handleDeleteCallBack?: (isError: boolean) => void;
  handleBeforeAddCallBack?: (isError: boolean) => void;
  handleLoadingCallBack?: (loading: boolean) => void;
  handleValidityCallback?: (isValid: boolean) => void;
  handleBlurInputCallBack?: (isValid: boolean) => void;
  handleChangeCallBack?: (emails: EmailChip[]) => void;
  // All other props
  [prop: string]: any;
};

export const SGEmailChipInput = ({
  field: { name, value },
  form: { setFieldValue },
  required,
  patternMatch,
  checkValidity,
  helperText,
  errorText,
  invalid = false,
  handleDeleteCallBack,
  handleBeforeAddCallBack,
  handleLoadingCallBack,
  handleValidityCallback,
  handleBlurInputCallBack,
  handleChangeCallBack,
  ...props
}: Props) => {
  return (
    <SGChipInput
      field={{ name, value } as FieldInputProps<EmailChip[]>}
      onChange={setFieldValue}
      required={required}
      patternMatch={patternMatch}
      checkValidity={checkValidity}
      helperText={helperText}
      errorText={errorText || INVALID_EMAIL_ERROR}
      learnMoreLink={getTechDocsLink('gac-accounts')}
      invalid={invalid}
      handleDeleteCallBack={handleDeleteCallBack}
      handleBeforeAddCallBack={handleBeforeAddCallBack}
      handleLoadingCallBack={handleLoadingCallBack}
      handleValidityCallback={handleValidityCallback}
      handleBlurInputCallBack={handleBlurInputCallBack}
      handleChangeCallBack={handleChangeCallBack}
      {...props}
    />
  );
};
