import React, { useState } from 'react';
import { Button, Collapse, Link } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { getLocale } from 'sg-utils-frontend';
import { useStyles } from './styles';

const GITHUB_EMU_COST_PER_USER = 21;

export const WarningMessage = (props: {
  totalUsersCount: number;
  isAttachResource?: boolean;
}) => {
  const { totalUsersCount, isAttachResource = false } = props;
  const classes = useStyles();
  const [isWarningMessageExpanded, setIsWarningMessageExpanded] =
    useState(false);
  const lang = getLocale();

  return (
    <Alert
      severity="warning"
      icon={<WarningAmberIcon fontSize="inherit" color="warning" />}
      sx={{ mt: 3 }}
    >
      <Collapse
        collapsedSize={60}
        in={isWarningMessageExpanded}
        className={classes.warningMessageCollapse}
      >
        {lang === 'ja' ? (
          <>
            「{isAttachResource ? 'Assign Resource' : 'Assign User Group'}
            」をクリックいたしますと、そのグループのすべてのメンバーにGitHub
            EMUライセンスが即座に付与されます。たとえメンバーが一度もログインしてない場合でも同様です(
            {totalUsersCount}
            ユーザーを割り当てることで、ワークスペースに約$
            {totalUsersCount * GITHUB_EMU_COST_PER_USER}
            のコストが発生する場合があります）。 GitHub
            EMUに大規模なユーザーグループが割り当てられ、ライセンスの消費量が増加した場合、Stargateチームは、すべてのStargateユーザーがGitHub
            EMUに継続的にアクセスできるように、ユーザーグループからEMUを一時的に割り当て解除することがあります。詳細については、
            <Link href="/docs/default/component/STARGATE-WELCOME-GUIDES/ja/non-mandatory-docs/portal-docs/project-management/cloud-license-usage/">
              ドキュメント
            </Link>
            をご参照ください。気になる点などがございましたら、
            <Link href=" /docs/default/component/STARGATE-WELCOME-GUIDES/ja/contact-us/">
              Stargate Support
            </Link>
            までご連絡ください。
          </>
        ) : (
          <>
            Clicking{' '}
            <b>
              “{isAttachResource ? 'Assign Resource' : 'Assign User Group'}”
            </b>{' '}
            immediately grants GitHub EMU license to all members in the group,
            even if they never log in (assigning {totalUsersCount} users may
            cost your workspace approximately $
            {totalUsersCount * GITHUB_EMU_COST_PER_USER}
            ). If large user groups are assigned to GitHub EMU and lead to high
            license usage, Stargate team may temporarily unassign EMU from the
            user group to ensure continued access to GitHub EMU for all Stargate
            users. For more information see{' '}
            <Link href="/docs/default/component/stargate-welcome-guides/non-mandatory-docs/portal-docs/project-management/cloud-license-usage/#github-emu-licensing-mechanism">
              documentation
            </Link>{' '}
            and please contact{' '}
            <Link href="/docs/default/component/stargate-welcome-guides/contact-us/">
              Stargate Support
            </Link>{' '}
            if you have concerns.
          </>
        )}
      </Collapse>
      <Button
        onClick={() => setIsWarningMessageExpanded(!isWarningMessageExpanded)}
        className={classes.readMore}
      >
        {isWarningMessageExpanded ? 'read less' : '... read more'}
      </Button>
    </Alert>
  );
};
