import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    padding: theme.spacing(1),
    minHeight: '100px',
    minWidth: '370px',
    backgroundColor: '#009C0D14',
    justifyContent: 'space-between',
  },
  msg: {
    width: '85%',
  },
}));
