type specificPropsType = {
  environmentsData: any;
  resourceID: string;
  setResourceID: React.Dispatch<React.SetStateAction<string>>;
  copyButtonLabel: string;
  setCopyButtonLabel: React.Dispatch<React.SetStateAction<string>>;
  projectId: string;
};

const environmentTypes = [
  { name: 'Production', id: 1, value: 'prod' },
  { name: 'Stage', id: 2, value: 'stg' },
  { name: 'Development', id: 3, value: 'dev' },
];

export type { specificPropsType };

export { environmentTypes };
