import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  identityApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { StargateInsightsApi, stargateInsightsApiRef } from './api';

export const stargateInsightsPlugin = createPlugin({
  id: 'stargate-insights',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: stargateInsightsApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
        gacApi: microsoftAuthApiRef,
      },
      factory: ({ configApi, identityApi, gacApi }) =>
        new StargateInsightsApi({ configApi, identityApi, gacApi }),
    }),
  ],
});

export const StargateInsightsPage = stargateInsightsPlugin.provide(
  createRoutableExtension({
    name: 'StargateInsightsPage',
    component: () =>
      import('./components/StargateInsightsComponent').then(
        m => m.StargateInsightsComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);
