import React, {
  Fragment,
  MouseEventHandler,
  PropsWithChildren,
  useState,
} from 'react';
import cn from 'classnames';
import { Button } from '@material-ui/core';
import { SupportPopover } from './components';

import { useStyles } from './styles';

type Props = {
  isSticky?: boolean;
  color?: 'transparent' | 'light';
};

export const SupportButton = ({
  children,
  color = 'transparent',
}: PropsWithChildren<Props>) => {
  const classes = useStyles();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element>();

  const onClickHandler: MouseEventHandler = event => {
    setAnchorEl(event.currentTarget);
    setIsPopoverVisible(true);
  };

  const popoverCloseHandler = () => {
    setIsPopoverVisible(false);
  };

  return (
    <Fragment>
      <Button
        onClick={onClickHandler}
        className={cn(classes.button, {
          [classes.lightButton]: color === 'light',
        })}
      >
        Support
      </Button>
      <SupportPopover
        isOpened={isPopoverVisible}
        onClose={popoverCloseHandler}
        anchorEl={anchorEl}
      >
        {children}
      </SupportPopover>
    </Fragment>
  );
};
