import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputLabel,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { EMAIL_REGEX, INVALID_EMAIL_ERROR } from 'usg-types';
import { Field, Formik } from 'formik';
import { SGEmailChipInput } from '@internal/sg-ui-kit';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { projectApiRef } from '../../api';
import { isResponseStatus2XX } from '@internal/sg-utils-common';
import { useStyles } from './styles';

interface ProjectOwnersProps {
  projectId: string;
  isEditEnabled: boolean;
  projectData: any;
  isEditMode: boolean;
  isRoleEditMode: boolean;
  updateIsEditMode: (value: boolean) => void;
  fetchContent: () => void;
  checkUserValidity: (email: string) => Promise<boolean>;
}

export const ProjectDetailOwners = ({
  projectId,
  isEditEnabled,
  projectData,
  isEditMode,
  isRoleEditMode,
  fetchContent,
  updateIsEditMode,
  checkUserValidity,
}: ProjectOwnersProps) => {
  const classes = useStyles();
  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);

  const [secondaryOwners, setSecondaryOwners] = useState([] as any);
  const [primaryOwners, setPrimaryOwners] = useState([] as any);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isPrimaryOwnerValid, setIsPrimaryOwnerValid] = useState(true);
  const [isPrimaryLoading, setIsPrimaryLoading] = useState(false);

  const [isSecondaryOwnerValid, setIsSecondaryOwnerValid] = useState(true);
  const [isSecondaryLoading, setIsSecondaryLoading] = useState(false);

  useEffect(() => {
    const secondaryOwnersList = [] as any;
    const primaryList = [] as any;
    projectData?.owners.map((res: any) =>
      res?.role === 'PRIMARY'
        ? primaryList.push({
            value: res.user_email,
            isValid: true,
          })
        : secondaryOwnersList.push({
            value: res.user_email,
            isValid: true,
          }),
    );
    setPrimaryOwners(primaryList);
    setSecondaryOwners(secondaryOwnersList);
  }, [projectData]);

  const onSubmit = async (formik: any) => {
    const ownersWithRoles = [] as any;
    for (const email of formik?.values?.primary_owners) {
      ownersWithRoles.push({ user_email: email.value, role: 'PRIMARY' });
    }
    for (const email of formik?.values?.project_owners) {
      ownersWithRoles.push({ user_email: email.value, role: 'SECONDARY' });
    }

    try {
      const token = await authref.getIdToken();

      const editData = await projectApi.editById(projectId, {
        idToken: token,
        name: projectData?.name,
        description: projectData?.project_description,
        management_type: projectData?.management_type,
        owners: ownersWithRoles,
        ip_owner_companies: projectData?.ip_owner_companies,
        participant_companies: projectData?.participant_companies,
      });

      const code = editData?.response?.status;
      if (!isResponseStatus2XX(code)) {
        const errorMsg = editData?.response?.data?.message;
        errorApi.post(
          new Error(`Couldn't update project owners due to ${errorMsg}`),
        );
      } else {
        setDialogOpen(true);
        updateIsEditMode(false);
      }
    } catch (err) {
      errorApi.post(
        new Error(`Couldn't update project owners due to ${err?.message}`),
      );
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    fetchContent();
  };

  const checkPrimaryOwnersValidity = async (email: string) => {
    const isValid = await checkUserValidity(email);
    setIsPrimaryOwnerValid(isValid && isPrimaryOwnerValid);
    return isValid;
  };

  const checkSecondaryOwnersValidity = async (email: string) => {
    setIsSecondaryLoading(true);
    const isValid = await checkUserValidity(email);
    setIsSecondaryOwnerValid(isValid && isSecondaryOwnerValid);
    setIsSecondaryLoading(false);
    return isValid;
  };

  return (
    <Grid container justifyContent="space-between" spacing={0}>
      {!isEditMode ? (
        <>
          <Grid item xs={6}>
            <Typography variant="h2">Project Owners</Typography>

            <p style={{ fontSize: '90%' }}>
              <Grid container spacing={2}>
                {projectData?.owners.map((res: any) => {
                  return (
                    <Grid item xs={6}>
                      {res.user_email}
                    </Grid>
                  );
                })}
              </Grid>
            </p>
          </Grid>
          {isEditEnabled && !isRoleEditMode && (
            <Button
              variant="contained"
              data-testid="button-edit-project-owners"
              onClick={() => updateIsEditMode(true)}
              id="edit-project-owners-button"
            >
              EDIT
            </Button>
          )}
        </>
      ) : (
        <Grid item xs={6}>
          <Formik
            initialValues={{
              primary_owners: primaryOwners,
              project_owners: secondaryOwners,
            }}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {formik => (
              <>
                <InputLabel style={{ paddingTop: '1%' }}>
                  Primary Owner*
                </InputLabel>

                <Field
                  name="primary_owners"
                  component={SGEmailChipInput}
                  required
                  variant="outlined"
                  patternMatch={EMAIL_REGEX}
                  helperText="Provide One Primary Owner"
                  className={classes.search}
                  invalid={formik?.values?.primary_owners?.length !== 1}
                  errorText={
                    formik?.values?.primary_owners?.length !== 1
                      ? 'Please provide one primary owner only'
                      : INVALID_EMAIL_ERROR
                  }
                  checkValidity={checkPrimaryOwnersValidity}
                  handleDeleteCallBack={(isError: boolean) => {
                    setIsPrimaryOwnerValid(!isError);
                  }}
                  handleBeforeAddCallBack={(isError: boolean) => {
                    setIsPrimaryOwnerValid(!isError);
                  }}
                  handleLoadingCallBack={(loading: boolean) => {
                    setIsPrimaryLoading(loading);
                  }}
                  InputProps={{
                    'data-testid': 'textfield-project-primary-owner',
                  }}
                  handleBlurInputCallBack={(isError: boolean) => {
                    setIsPrimaryOwnerValid(!isError);
                  }}
                />

                <InputLabel style={{ paddingTop: '1%' }}>
                  Secondary Owners
                </InputLabel>

                <Field
                  name="project_owners"
                  component={SGEmailChipInput}
                  variant="outlined"
                  patternMatch={EMAIL_REGEX}
                  errorText={INVALID_EMAIL_ERROR}
                  helperText="Provide Secondary Owners"
                  className={classes.search}
                  checkValidity={checkSecondaryOwnersValidity}
                  handleDeleteCallBack={(isError: boolean) => {
                    setIsSecondaryOwnerValid(!isError);
                  }}
                  handleBeforeAddCallBack={(isError: boolean) => {
                    setIsSecondaryOwnerValid(!isError);
                  }}
                  loading={isSecondaryLoading}
                  handleLoadingCallBack={(loading: boolean) => {
                    setIsSecondaryLoading(loading);
                  }}
                  InputProps={{
                    'data-testid': 'textfield-project-secondary-owners',
                  }}
                  handleBlurInputCallBack={(isError: boolean) => {
                    setIsSecondaryOwnerValid(!isError);
                  }}
                />
                <Grid container spacing={2} style={{ paddingTop: '3%' }}>
                  <Grid item>
                    <Button
                      id="btn-cancel-edit-owners"
                      variant="outlined"
                      onClick={() => {
                        updateIsEditMode(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      id="btn-submit-edit-owners"
                      variant="contained"
                      type="submit"
                      disabled={
                        isPrimaryLoading ||
                        isSecondaryLoading ||
                        !isPrimaryOwnerValid ||
                        !isSecondaryOwnerValid ||
                        formik?.values?.primary_owners?.length !== 1
                      }
                      onClick={() => {
                        onSubmit(formik);
                      }}
                      data-testid="button-save-owners"
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Formik>
        </Grid>
      )}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="xs"
        style={{ top: '5' }}
        className={classes.dialog}
      >
        <DialogContent
          id="owner-update-confirmed-dialog"
          style={{ fontWeight: 'bold' }}
        >
          Your project owners are being edited, please wait whilst we add it to
          your project workspace.
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            id="dialog-close-button"
            variant="contained"
            size="small"
            onClick={handleDialogClose}
            style={{ backgroundColor: 'black' }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
