import { Config } from '@backstage/config';
import { NotFoundError } from '@backstage/errors';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { generateHeaders } from 'sg-utils-frontend';
import {
  CatalogItemResponseData,
  DockerImageItemResponseData,
  DockerImageItemsResponseData,
  DockerImageItemTagsResponseData,
} from './pages/types';

export const actionsCatalogApiRef = createApiRef<ActionsCatalogInterface>({
  id: 'plugin.actions-catalog',
});

interface ActionsCatalogInterface {
  getCatalogList(): Promise<any>;
  getCatalogItem(
    params: getCatalogItemParams,
  ): Promise<CatalogItemResponseData>;
  getCatalogItemReadme(
    catalogKey: string,
    source: string,
    ref: string,
  ): Promise<any>;
  getDockerImageList(): Promise<DockerImageItemsResponseData>;
  getDockerImageItem(
    params: getDockerImageItemParams,
  ): Promise<DockerImageItemResponseData>;
  getDockerImageItemTags(
    params: getDockerImageItemParams,
  ): Promise<DockerImageItemTagsResponseData>;
}

interface ActionsCatalogApiOptions {
  configApi: Config;
  identityApi: IdentityApi;
  gacApi: any;
}

interface getCatalogItemParams {
  catalogKey: string;
  ref?: string;
}

interface getDockerImageItemParams {
  namespace: string;
  name: string;
}

export class ActionsCatalogApi implements ActionsCatalogInterface {
  private configApi: Config;
  private identityApi: IdentityApi;
  private gacApi: any;
  private backendUrl: string;

  constructor(options: ActionsCatalogApiOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
    this.gacApi = options.gacApi;
    this.backendUrl = this.configApi.getString('backend.baseUrl');
  }

  public async getCatalogList(): Promise<any> {
    const tokenData = await this.identityApi.getCredentials();
    const token = tokenData?.token;
    const gacToken = await this.gacApi.getIdToken();
    const requestUrl = new URL(`${this.backendUrl}/api/actions/catalogs`);
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      {
        gacIdToken: gacToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }

  public async getCatalogItem(
    params: getCatalogItemParams,
  ): Promise<CatalogItemResponseData> {
    const tokenData = await this.identityApi.getCredentials();
    const token = tokenData?.token;
    const gacToken = await this.gacApi.getIdToken();
    const requestUrl = new URL(
      `${this.backendUrl}/api/actions/catalogs/${params.catalogKey}`,
    );
    if (params.ref) {
      requestUrl.searchParams.set('ref', params.ref);
    }

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      {
        gacIdToken: gacToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }

  public async getCatalogItemReadme(
    catalogKey: string,
    source: string,
    ref: string,
  ): Promise<string> {
    const tokenData = await this.identityApi.getCredentials();
    const token = tokenData?.token;
    const gacToken = await this.gacApi.getIdToken();
    const requestUrl = new URL(
      `${this.backendUrl}/api/actions/catalogs/${catalogKey}/readmes?source=${source}&ref=${ref}`,
    );
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      {
        gacIdToken: gacToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (!data) {
      throw new NotFoundError('Not Found');
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }

  public async getDockerImageList(): Promise<DockerImageItemsResponseData> {
    const tokenData = await this.identityApi.getCredentials();
    const token = tokenData?.token;
    const gacToken = await this.gacApi.getIdToken();
    const requestUrl = new URL(
      `${this.backendUrl}/api/actions/artifactory/repositories`,
    );
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      {
        gacIdToken: gacToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }

  public async getDockerImageItem(
    params: getDockerImageItemParams,
  ): Promise<DockerImageItemResponseData> {
    const tokenData = await this.identityApi.getCredentials();
    const token = tokenData?.token;
    const gacToken = await this.gacApi.getIdToken();
    const { namespace, name } = params;
    const requestUrl = new URL(
      `${this.backendUrl}/api/actions/artifactory/repositories/${namespace}/${name}`,
    );
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      {
        gacIdToken: gacToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }

  public async getDockerImageItemTags(
    params: getDockerImageItemParams,
  ): Promise<DockerImageItemTagsResponseData> {
    const tokenData = await this.identityApi.getCredentials();
    const token = tokenData?.token;
    const gacToken = await this.gacApi.getIdToken();
    const { namespace, name } = params;
    const requestUrl = new URL(
      `${this.backendUrl}/api/actions/artifactory/repositories/${namespace}/${name}/tags`,
    );
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      {
        gacIdToken: gacToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }
}
