/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Note: This is copied from the original backstage ErrorPage.
// To be more flexible, we move this to the usg-ui-kit library as a common library component.
import { BackstageTheme } from '@backstage/theme';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { MicDrop } from './MicDrop';

interface IErrorPageProps {
  status: string;
  statusMessage: string;
  additionalInfo?: React.ReactNode;
  supportUrl?: string;
}

/** @public */
export type CustomErrorPageClassKey = 'container' | 'title' | 'subtitle';

const useStyles = makeStyles<BackstageTheme>(
  theme => ({
    container: {
      padding: theme.spacing(8),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
      },
    },
    title: {
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        paddingBottom: theme.spacing(4),
        fontSize: theme.typography.h3.fontSize,
      },
    },
    subtitle: {
      color: theme.palette.textSubtle,
    },
  }),
  { name: 'BackstageErrorPage' },
);

/**
 * Error page with status and description
 *
 * @public
 *
 */
export function CustomErrorPage(props: IErrorPageProps) {
  const { status, statusMessage, additionalInfo } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid item xs={12} sm={8} md={4}>
        <Typography
          data-testid="error"
          variant="body1"
          className={classes.subtitle}
        >
          ERROR {status} {statusMessage}
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          {additionalInfo}
        </Typography>
        <Typography variant="h2" className={classes.title}>
          Something went wrong...
        </Typography>
        <Typography variant="body1">
          Please try one of the following below:
        </Typography>
        <br />
        <Typography variant="body1">
          - Refresh the browser by pressing: Ctrl+F5 (Windows), Cmd+Shift+R
          (Mac)
        </Typography>
        <Typography variant="body1">- Click on Home page.</Typography>
        <br />
        <br />
        <Typography variant="h6">
          If the issue still persists, please contact us.
        </Typography>
        <br />
        <br />
      </Grid>
      <MicDrop />
    </Grid>
  );
}
