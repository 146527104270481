import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { SGPeopleAvatarGroup } from '@internal/sg-ui-kit';

type elementsType = { user_email: string; role: 'SECONDARY' | 'PRIMARY' }[];

type OwnerProps = {
  owners: elementsType;
};

type SGPeopleAvatarGroupValuesType = { value: string; stringForShade: string };

type sortElementsType = (
  elements: elementsType,
) => SGPeopleAvatarGroupValuesType[];

export interface ProjectOwnerProps extends OwnerProps {}

const useStyles = makeStyles({
  ownerCard: {
    width: 'max-content',
    marginLeft: 'auto',
  },
  title: {
    marginBottom: '8px',
  },
});

export const ProjectOwners = ({ owners }: ProjectOwnerProps) => {
  const classes = useStyles();

  const sortElements: sortElementsType = elements => {
    const withPrimaryOwnerAtTop: elementsType = [];
    elements.sort((a, b) =>
      a.user_email.toLowerCase() > b.user_email.toLowerCase() ? 1 : -1,
    );
    elements.forEach(element => {
      if (element.role === 'PRIMARY') {
        withPrimaryOwnerAtTop.unshift(element);
      }
      if (element.role === 'SECONDARY') {
        withPrimaryOwnerAtTop.push(element);
      }
    });
    const withStringForShade = withPrimaryOwnerAtTop.map(element => {
      return {
        value: element.user_email,
        stringForShade: element.user_email.split('@')[1],
      };
    });

    return withStringForShade;
  };

  return (
    <>
      {owners?.length > 0 && (
        <div className={classes.ownerCard}>
          <Typography variant="body2" className={classes.title}>
            <b>Owners</b>
          </Typography>
          <SGPeopleAvatarGroup
            values={sortElements(owners)}
            copyable
            expand
            title="Project Owners"
          />
        </div>
      )}
    </>
  );
};
