import React from 'react';
import { useStyles } from './styles';
import { PageLayout } from '@internal/sg-ui-kit';
import { Typography } from '@material-ui/core';
import { rootRouteRef } from '../../routes';
import { useRouteRef } from '@backstage/core-plugin-api';

export function CreateProject() {
  const classes = useStyles();
  const rootLink = useRouteRef(rootRouteRef);

  const backToLink = React.useMemo(
    () => ({
      to: rootLink(),
      label: 'Back to Projects',
    }),
    [rootLink],
  );

  return (
    <PageLayout
      title="Projects"
      headerBackgroundImg="assets/welcome_bg.webp"
      backToLink={backToLink}
    >
      <div className={classes.container}>
        <Typography variant="h3" paragraph>
          Create New Project
        </Typography>
      </div>
    </PageLayout>
  );
}
