import React from 'react';
import { Tabs as MaterialUiTabs, Tab } from '@material-ui/core';
import { useStyles } from './styles';

interface TabIProp {
  label: string;
  value: string;
  content: React.ReactNode;
}

interface TabsProps {
  tabs: TabIProp[];
  onTabChange?: (tabIndex: number) => void;
  initialActiveTabIndex?: number;
}

const tabIndicatorStyle = {
  background: 'linear-gradient(to right, #0D6AFF 100%, #0E4295 100%)',
  height: '3px',
};

export const Tabs = ({
  tabs,
  onTabChange,
  initialActiveTabIndex = 0,
}: TabsProps) => {
  const classes = useStyles();

  const [activeTabIndex, setActiveTabIndex] = React.useState(
    initialActiveTabIndex,
  );

  React.useEffect(() => {
    setActiveTabIndex(initialActiveTabIndex);
  }, [initialActiveTabIndex]);

  const handleChange = (event: React.ChangeEvent<{}>, tabValue: string) => {
    event.preventDefault();
    const activeTabIndexValue = tabs.findIndex(item => item.value === tabValue);
    setActiveTabIndex(activeTabIndexValue);

    if (onTabChange) {
      onTabChange(activeTabIndexValue);
    }
  };

  /* const renderTabContent = React.useMemo(
    () => tabs[activeTabIndex]?.content,
    [activeTabIndex],
  );*/

  return (
    <>
      <MaterialUiTabs
        className={classes.tabsWrapper}
        TabIndicatorProps={{ style: tabIndicatorStyle }}
        value={tabs[activeTabIndex]?.value}
        onChange={handleChange}
      >
        {tabs.map(({ label, value }) => (
          <Tab
            label={label}
            id={value}
            value={value}
            key={value}
            disableRipple
            disableFocusRipple
          />
        ))}
      </MaterialUiTabs>
      {tabs[activeTabIndex]?.content}
    </>
  );
};
// Tabs page
