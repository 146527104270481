import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { definitions } from '../../api';
import { ApplicationRequestProps } from './types';
import { ControlTextField } from './FormHelpers';
import CasinoTwoToneIcon from '@material-ui/icons/CasinoTwoTone';
import {
  Config,
  uniqueNamesGenerator,
  adjectives,
  animals,
} from 'unique-names-generator';

// Schemas
type NamespaceAutocompletionResponseProjectType =
  definitions['handlers.NamespaceAutocompletionResponseProject'];

// props schema
export type ApplicationNameProps = {
  defaultAppName: string;
  stargateProject: NamespaceAutocompletionResponseProjectType;
  validationSetter: (isValid: boolean) => void;
  setIsAppNameDefault?: (b: boolean) => void;
  enableEdit: boolean;
};

export const generateRandomName = (
  stargateProject: NamespaceAutocompletionResponseProjectType,
) => {
  const config: Config = {
    dictionaries: [adjectives, animals],
    separator: '-',
    style: 'lowerCase',
  };

  return `${stargateProject.key}-${uniqueNamesGenerator(config)}`.toLowerCase();
};

export const ApplicationName = (props: ApplicationNameProps) => {
  // Form constants
  const maxAppNameLength = 57;

  const {
    defaultAppName,
    stargateProject,
    validationSetter,
    setIsAppNameDefault,
    enableEdit,
  } = props;

  const {
    setValue,
    getValues,
    control,
    formState: { isValid },
  } = useFormContext<ApplicationRequestProps>();

  useEffect(() => {
    validationSetter(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const [appName, setAppName] = useState<string>(defaultAppName);

  const setFormAppName = (newAppName: string) => {
    setAppName(newAppName);

    if (getValues('github_repository.name') === '') {
      setValue('github_repository.name', newAppName, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }

    setValue('app_name', newAppName, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const onAppNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormAppName((event.currentTarget.value as string).toLowerCase());
    if (!!setIsAppNameDefault) setIsAppNameDefault(false);
  };

  const randomizeName = () => {
    setFormAppName(generateRandomName(stargateProject));
    if (!!setIsAppNameDefault) setIsAppNameDefault(true);
  };

  return (
    <>
      <Grid container spacing={3} id="mtfuji_wizard_application_name">
        <Grid item xs={12}>
          <Typography variant="h5">Name</Typography>
          <p>
            Application names must be unique across all of Stargate Multicloud.
            Many teams opt to have a common prefix to group multiple
            applications. By default the Stargate project workspace key is
            prefixed for this purpose.
          </p>
        </Grid>

        <Grid item xs={11}>
          <ControlTextField
            label="Application name"
            placeholder="app-name"
            helperText={`Only lowercase alphanumeric characters and hyphens allowed. Must not start or end with a hyphen. Max length: ${maxAppNameLength} characters.`}
            name="app_name"
            // https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#rfc-1035-label-names
            rules={{
              required: true,
              pattern: new RegExp('^[a-z0-9]([-a-z0-9]*[a-z0-9])?$'),
              maxLength: maxAppNameLength,
            }}
            onChange={onAppNameChange}
            control={control}
            value={appName}
            disabled={!enableEdit}
          />
        </Grid>
        {enableEdit && (
          <Grid item xs={1}>
            <Tooltip title="Generate a random name">
              <IconButton style={{ marginTop: '28px' }} onClick={randomizeName}>
                <CasinoTwoToneIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </>
  );
};
