interface CloudAccount {
  accountId: string;
  // accountName?: string; // not implemented yet
  // accountOwner?: string; // not implemented yet
  costCenterId?: string;
  BUL2?: string;
  BUL3?: string;
  // WBSID?: string; // not implemented yet
}

export interface CloudAccountChargeback extends CloudAccount {
  period: {
    [key: string]: string;
  };
}

export interface ChargebacksPayload {
  accountsData: CloudAccountChargeback[];
  months: string[];
}

export enum OrgBusinessUnitLevels {
  L2 = 'L2',
  L3 = 'L3',
}

export type BusinessUnit = string;
