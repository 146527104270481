import { Config } from '@backstage/config';
import { IdentityApi, createApiRef } from '@backstage/core-plugin-api';
import { generateHeaders } from 'sg-utils-frontend';

export const roverApiRef = createApiRef<RoverApi>({
  id: 'plugin.rover',
});

type RoverApiOptions = {
  configApi: Config;
  identityApi: IdentityApi;
  gacApi: any;
};

export type UserSettingsParameters = {
  settings: {
    onboarding_flow_completed?: boolean;
    usage_agreement_accepted?: boolean;
    role?: string;
    response_style?: string;
    additional_instructions?: Array<string>;
  };
};

export interface RoverApiInterface {
  getUser: () => Promise<Response>;
  updateUser: (params: UserSettingsParameters) => Promise<Response>;
}

export class RoverApi implements RoverApiInterface {
  private configApi: Config;
  private identityApi: IdentityApi;
  private gacApi: any;
  private backendUrl: string;

  constructor(options: RoverApiOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
    this.gacApi = options.gacApi;
    this.backendUrl = this.configApi.getString('backend.baseUrl');
  }

  public async getUser() {
    const requestUrl = new URL(`${this.backendUrl}/api/rover/me`);

    const token = (await this.identityApi.getCredentials()).token;
    const gacToken = await this.gacApi.getIdToken();

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: gacToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data?.error?.message || response.statusText);
    }
    return data;
  }

  public async updateUser(params: UserSettingsParameters): Promise<Response> {
    const requestUrl = new URL(`${this.backendUrl}/api/rover/me`);

    const token = (await this.identityApi.getCredentials()).token;
    const gacToken = await this.gacApi.getIdToken();

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: gacToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'PATCH',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(params),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data?.error?.message || response.statusText);
    }
    return data;
  }
}
