import { USGThemeMixins } from '../types';
import { USGPalette } from './../types';

export const usgLinkMixins = (p: USGPalette) => ({
  color: p.usgColors.secondary.darkRed,
  '&:hover': {
    textDecoration: 'underline',
  },
  '&:visited': {
    color: p.usgColors.secondary.redberry,
  },
  '&:focus': {
    outline: `2px solid ${p.usgColors.secondary.blue}`,
  },
});

export const USGMixins: Omit<USGThemeMixins, 'gutters' | 'toolbar'> = {
  lineClamp: (maxLinesNumber: number) => ({
    display: '-webkit-box',
    WebkitLineClamp: maxLinesNumber,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  }),
};
