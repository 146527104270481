import { yellow } from '@material-ui/core/colors';
import { USG_COLOR_SCHEME } from '../colorScheme';
import { USGPaletteOptions } from '../types';

export const USGPalette: USGPaletteOptions = {
  background: {
    default: USG_COLOR_SCHEME.secondary.lightGray,
  },
  status: {
    ok: '#1DB954',
    warning: '#FF9800',
    error: USG_COLOR_SCHEME.primary.red,
    running: '#2E77D0',
    pending: '#FFED51',
    aborted: '#757575',
  },
  bursts: {
    gradient: {
      linear: 'linear-gradient(-137deg, #4BB8A5 0%, #187656 100%)',
    },
    fontColor: '#FEFEFE',
    slackChannelText: '#ddd',
    backgroundColor: {
      default: '#7C3699',
    },
  },
  primary: {
    main: '#2E77D0',
  },
  secondary: {
    main: '#0E4295',
  },
  banner: {
    info: '#2E77D0',
    error: USG_COLOR_SCHEME.primary.red,
    text: USG_COLOR_SCHEME.primary.white,
    link: USG_COLOR_SCHEME.primary.black,
  },
  border: USG_COLOR_SCHEME.secondary.silverGray,
  textContrast: USG_COLOR_SCHEME.primary.black,
  textVerySubtle: '#DDD',
  textSubtle: USG_COLOR_SCHEME.secondary.slateGray,
  highlight: '#FFFBCC',
  errorBackground: '#FFEBEE',
  warningBackground: '#F59B23',
  infoBackground: '#ebf5ff',
  errorText: USG_COLOR_SCHEME.primary.red,
  infoText: '#004e8a',
  warningText: USG_COLOR_SCHEME.primary.black,
  linkHover: USG_COLOR_SCHEME.secondary.darkRed,
  link: USG_COLOR_SCHEME.secondary.darkRed,
  gold: yellow.A700,
  navigation: {
    background: USG_COLOR_SCHEME.primary.black,
    indicator: '#9BF0E1',
    color: USG_COLOR_SCHEME.secondary.slateGray,
    selectedColor: USG_COLOR_SCHEME.primary.white,
  },
  pinSidebarButton: {
    icon: '#181818',
    background: '#BDBDBD',
  },
  tabbar: {
    indicator: '#9BF0E1',
  },
  usgColors: USG_COLOR_SCHEME,
};
