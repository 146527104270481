import {
  Card,
  Divider,
  Grid,
  Typography,
  CardContent,
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { getTechDocsLink } from 'sg-utils-frontend';
import { useStyles } from './styles';

const cards = [
  {
    title: 'Introduction',
    description:
      'Understand the structure and components of the Stargate platform.',
    image: 'image1.webp',
    documents: [
      {
        title: 'Welcome Page',
        link: getTechDocsLink('stargate-welcome-guides'),
      },
      {
        title: 'Getting Started with Stargate',
        link: getTechDocsLink('welcome-to-stargate'),
      },
      {
        title: 'Stargate Architecture',
        link: getTechDocsLink('architecture'),
      },
      {
        title: 'Who should use Stargate',
        link: getTechDocsLink('when-to-use-Stargate'),
      },
    ],
  },
  {
    title: 'Managing Stargate Projects',
    description:
      'Learn how to manage projects and repositories within Stargate project workspaces.',
    image: 'image2.webp',
    documents: [
      {
        title: 'Overview',
        link: getTechDocsLink('admin-overview'),
      },
      {
        title: 'User Groups',
        link: getTechDocsLink('user-groups'),
      },
      {
        title: 'Creating a Project',
        link: getTechDocsLink('creating-project'),
      },
      {
        title: 'Stargate API',
        link: getTechDocsLink('api-overview'),
      },
      {
        title: 'Dynamic User Groups',
        link: getTechDocsLink('automatic-dynamic-groups'),
      },
    ],
  },
  {
    title: 'Stargate Resources',
    description:
      'Utilize integrated development tools like GitHub, Artifactory, Jira, Confluence, Jama, etc.',
    image: 'image3.webp',
    documents: [
      {
        title: 'Tools',
        link: getTechDocsLink('list-of-tools'),
      },
      {
        title: 'Service Catalog',
        link: getTechDocsLink('service-catalog'),
      },
      {
        title: 'IP whitelisting',
        link: getTechDocsLink('ip-whitelisting'),
      },
    ],
  },
  {
    title: 'Stargate Policies',
    description:
      'Adhere to Stargate’s usage policies for repository usage, naming conventions, data retention and security.',
    image: 'image4.webp',
    documents: [
      {
        title: 'Security Policies',
        link: getTechDocsLink('security-banner'),
      },
      {
        title: 'Data Retention',
        link: getTechDocsLink('data-retention'),
      },
      {
        title: 'Confidentiality Agreement',
        link: getTechDocsLink('confidentiality-agreement'),
      },
    ],
  },
];

export const FeaturedCardSection = () => {
  const classes = useStyles();
  return (
    <Grid container item spacing={4}>
      {cards.map(card => (
        <Grid item key={card.title} xs={12} sm={6} lg={3}>
          <Card variant="outlined" className={classes.card}>
            <CardContent className={classes.content}>
              <div className={classes.imageContainer}>
                <img
                  src={`assets/techdocs/${card.image}`}
                  alt={card.title}
                  height={100}
                />
              </div>
              <Typography variant="h4">{card.title}</Typography>
              <Typography variant="caption" className={classes.caption}>
                {card.description}
              </Typography>
              <Divider />
              <div>
                {card.documents.map((doc, index) => (
                  <React.Fragment key={doc.title}>
                    <Link to={doc.link} className={classes.titleLink}>
                      {doc.title}
                    </Link>
                    {index !== card.documents.length - 1 && (
                      <span className={classes.bar}>|</span>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
