import { format, compareAsc, compareDesc, isDate, isEqual } from 'date-fns';
import { SortDirections } from 'usg-types';

export const formatDate = (date: string, isLongMonthName: boolean) =>
  isLongMonthName
    ? format(new Date(date), 'MMMM d, yyyy')
    : format(new Date(date), 'MMM d, yyyy');

export const dateComparer = (
  date1: Date,
  date2: Date,
  sortDirection: SortDirections = SortDirections.ASC,
): number => {
  const compareFunction =
    sortDirection === SortDirections.ASC ? compareAsc : compareDesc;

  if (!isDate(date1) || !isDate(date2) || isEqual(date1, date2)) {
    return 0;
  }

  return compareFunction(date1, date2);
};
