import React from 'react';
import { useStyles } from './styles';

import { Typography } from '@material-ui/core';
import { Link as BackstageLink } from '@backstage/core-components';

export type Props = {
  to: string;
  text: string;
};

export const HeaderLink = ({ to, text }: Props) => {
  const classes = useStyles();

  return (
    <BackstageLink
      to={to}
      color="inherit"
      underline="always"
      className={classes.link}
    >
      <Typography variant="caption" className={classes.text}>
        {text}
      </Typography>
    </BackstageLink>
  );
};
