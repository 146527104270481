import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import { useStyles } from './styles';

type Props = {
  theme?: 'light' | 'dark';
  className?: string;
};

export const Content = ({
  theme = 'dark',
  className,
  children,
}: PropsWithChildren<Props>) => {
  const classes = useStyles();
  return (
    <article
      className={cn(
        classes.container,
        { [classes.light]: theme === 'light' },
        className,
      )}
    >
      {children}
    </article>
  );
};
