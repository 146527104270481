import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
} from '@material-ui/core';
import React from 'react';
import { AppEnvs } from './types';
import Button from '@material-ui/core/Button';
import { Autocomplete } from '@material-ui/lab';
import {
  NON_PRODUCTION_ENVIRONMENTS,
  PRODUCTION_ENVIRONMENTS,
} from './Constants';
import Typography from '@mui/material/Typography';

function isProdEnvValid(prodEnv: string | null): boolean {
  return prodEnv !== null && prodEnv.length > 0;
}

function areNonProdEnvsValid(nonProdEnvs: string[]): boolean {
  return nonProdEnvs.length > 0 && nonProdEnvs.length <= 7;
}

export default function AvailableEnvironmentEdit({
  open,
  appEnvs,
  mainEnvName,
  onApply,
  onClose,
}: {
  open: boolean;
  appEnvs: AppEnvs;
  mainEnvName: string;
  onApply: (appEnvs: AppEnvs, mainEnvName: string) => void;
  onClose: () => void;
}) {
  const appEnvNames = Object.getOwnPropertyNames(appEnvs);
  const [prodEnvName, setProdEnvName] = React.useState<string | null>(
    appEnvNames.find(name => PRODUCTION_ENVIRONMENTS.includes(name)) || null,
  );
  const [nonProdEnvNames, setNonProdEnvNames] = React.useState(
    appEnvNames.filter(name => !PRODUCTION_ENVIRONMENTS.includes(name)),
  );

  const handleProdEnvChange = (envName: string | null) => {
    setProdEnvName(envName);
  };

  const handleNonProdEnvChange = (envNames: string[]) => {
    setNonProdEnvNames(envNames);
  };

  const handleApply = () => {
    if (prodEnvName === null) {
      return;
    }
    const newAppEnvs: AppEnvs = Object.fromEntries([
      [prodEnvName, appEnvs[mainEnvName] || {}],
      ...nonProdEnvNames.map(envName => [envName, appEnvs[envName] || {}]),
    ]);
    onApply(newAppEnvs, prodEnvName);
  };

  const prodEnvNameValid = isProdEnvValid(prodEnvName);
  const nonProdEnvNamesValid = areNonProdEnvsValid(nonProdEnvNames);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Edit environments</DialogTitle>
      <DialogContent id="ug-available-environments-edit-dialog" dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Production environment</Typography>
            <Autocomplete
              value={prodEnvName}
              options={PRODUCTION_ENVIRONMENTS}
              onChange={(_, value) => handleProdEnvChange(value)}
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    label=""
                    placeholder="Prod env"
                    error={!prodEnvNameValid}
                    helperText={
                      !prodEnvNameValid &&
                      'Production environment cannot be empty'
                    }
                    fullWidth
                  />
                );
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Non-production environments</Typography>
            <Autocomplete
              value={nonProdEnvNames}
              options={NON_PRODUCTION_ENVIRONMENTS}
              multiple
              onChange={(_, value) => handleNonProdEnvChange(value)}
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    label=""
                    placeholder="Non-prod envs"
                    error={!nonProdEnvNamesValid}
                    helperText={
                      !nonProdEnvNamesValid &&
                      'Non-production environments cannot be empty or contain more than 7 values.'
                    }
                    fullWidth
                  />
                );
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleApply()}
          disabled={!prodEnvNameValid || !nonProdEnvNamesValid}
        >
          Apply
        </Button>
        <Button onClick={() => onClose()}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
