import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  errorIcon: {
    margin: '10px auto',
    width: '2em',
    height: 'auto',
    color: '#EB0A1E',
  },
  errorModal: {
    backgroundColor: '#fdebed',
    textAlign: 'center',
  },
}));
