import React from 'react';
import { useStyles } from './styles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import ErrorIcon from '@material-ui/icons/Error';
import Box from '@mui/material/Box';

interface Props {
  alertMessage: string;
  isModalOpen: boolean;
  onClose: () => void;
}
export const AlertModal = ({ alertMessage, isModalOpen, onClose }: Props) => {
  const styles = useStyles();
  const handleClose = () => {
    onClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className={styles.errorModal}>
          <ErrorIcon className={styles.errorIcon} />
          <DialogTitle id="alert-dialog-title">
            Something went wrong
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {alertMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Go to Home page</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};
