export enum NotificationType {
  FCBN = 'FCBN',
  FORN = 'FORN',
  SPVN = 'SPVN',
  SNN = 'SNN',
  SAWSW = 'SAWSW',
  SAZW = 'SAZW',
}

export enum NotificationReadStatus {
  READ = 'read',
  UNREAD = 'unread',
}

export type Notification = {
  notificationId: string;
  type: NotificationType;
  read: boolean;
  message?: string;
  date?: string;
  accountId?: string;
  link?: string;
  month?: string;
  cloud?: string;
};
