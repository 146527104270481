import React from 'react';
import { Typography } from '@material-ui/core';
import CopyrightIcon from '@material-ui/icons/Copyright';
// import Logo from '../logo/logoicon.svg';
import { IdentityProviders } from './types';
import { getSignInProviders, useSignInProviders } from './providers';

import { useStyles } from './styles';

import { Progress } from '@backstage/core-components';
import {
  SignInPageProps,
  // , useApi, configApiRef
} from '@backstage/core-plugin-api';

type LoginPageProps = SignInPageProps & {
  providers: IdentityProviders;
};

const BackgroundStyle = {
  backgroundImage: `url(assets/login_page_background.webp)`,
  backgroundSize: 'cover',
  height: '100vh',
};

export const SignInPage = ({ providers, onSignInSuccess }: LoginPageProps) => {
  const classes = useStyles();
  const date = new Date().getFullYear();
  // const configApi = useApi(configApiRef);
  const signInProviders = getSignInProviders(providers);
  const [loading, providerElements] = useSignInProviders(
    signInProviders,
    onSignInSuccess,
  );

  if (loading) {
    return <Progress />;
  }
  return (
    <div style={BackgroundStyle} className={classes.root}>
      <div className={classes.container}>
        <div className={classes.infoContainer}>
          <div className={classes.logo}>
            {/* <img className={classes.logoIcon} src={Logo} alt="logo" /> */}
            <Typography variant="h3" className={classes.title}>
              Stargate
            </Typography>
          </div>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Toyota’s global development tools and software collaboration
            platform
          </Typography>
          <Typography variant="subtitle1" className={classes.textLine}>
            Single Sign in with Global Access Center
          </Typography>
          {providerElements}
        </div>
        <div className={classes.dateContainer}>
          <CopyrightIcon className={classes.copyrightIcon} />
          <Typography variant="body2" className={classes.date}>
            {date} Stargate Developer Portal
          </Typography>
        </div>
      </div>
    </div>
  );
};
