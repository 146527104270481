import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { homePageExternalRouteRef, rootRouteRef } from './routes';
import { FeatureFlagApi, featureFlagsApiRef } from './api';

export const featureFlagsPlugin = createPlugin({
  id: 'feature-flags',
  routes: {
    root: rootRouteRef,
  },
  externalRoutes: {
    home: homePageExternalRouteRef,
  },
  apis: [
    createApiFactory({
      api: featureFlagsApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ configApi, identityApi }) =>
        new FeatureFlagApi({ configApi, identityApi }),
    }),
  ],
});

export const FeatureFlagsPage = featureFlagsPlugin.provide(
  createRoutableExtension({
    name: 'FeatureFlagsPage',
    component: () =>
      import('./components/FeatureFlagList').then(m => m.FeatureFlagList),
    mountPoint: rootRouteRef,
  }),
);
