import React from 'react';

import { Progress } from '@backstage/core-components';
import { Dialog, DialogContent, Typography } from '@material-ui/core';

import { WaitingForUserExportProps } from './types';

import { useStyles } from '../styles';

const WaitingForUserExport = ({
  displayExportProgressBar,
}: WaitingForUserExportProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={displayExportProgressBar}
      maxWidth="xs"
      style={{ top: '5' }}
      className={classes.dialog}
    >
      <DialogContent style={{ fontWeight: 'bold' }}>
        <Typography variant="body1" gutterBottom>
          Exporting users to CSV...
        </Typography>
        <Progress />
      </DialogContent>
    </Dialog>
  );
};

export default WaitingForUserExport;
