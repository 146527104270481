import React from 'react';
import { makeStyles } from '@material-ui/core';

const useSidebarTagStyles = makeStyles(() => ({
  tagBase: {
    color: '#91B9FA',
    border: '1px solid #91B9FA',
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '3px',
    alignItems: 'center',
    width: 'fit-content',
    fontWeight: 600,
    padding: '0 3px',
  },
}));

export const SidebarItemTag = ({ label }: { label: string }) => {
  const classes = useSidebarTagStyles();

  return (
    <div className={classes.tagBase}>
      <span>{label}</span>
    </div>
  );
};
