export enum MarkdownCategory {
  BLOGS = 'blogs',
  FAQ = 'faq',
  NEWS = 'news',
  TUTORIALS = 'tutorials',
}

export interface MarkdownMetadataCommonPart {
  id: string;
  fileName: string;
}
