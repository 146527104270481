import React from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import { ArtifactoryGitHubWorkflowAccessHeader } from './ArtifactoryGitHubWorkflowAccessHeader';
import { BaseArtifactoryTable } from './BaseArtifactoryTable';

export const ArtifactoryGitHubWorkflowAccessViewTable = (props: {
  name: string;
  artifactoryProperties: any;
  isLoading: boolean;
}) => {
  const { name, artifactoryProperties, isLoading } = props;

  const renderContent = () => {
    if (isLoading) {
      return <CircularProgress />;
    }
    if (artifactoryProperties) {
      return (
        <BaseArtifactoryTable
          name={name}
          artifactoryProperties={artifactoryProperties}
        />
      );
    }
    return (
      <Typography style={{ margin: '20px' }}>
        Currently, this artifactory has no workflow access configured. To have
        the same incorporated, please edit if you have owner permissions on this
        artifactory.
      </Typography>
    );
  };

  return (
    <>
      <ArtifactoryGitHubWorkflowAccessHeader />
      {renderContent()}
    </>
  );
};
