import { MarkdownMetadataCommonPart } from './markdown';

export enum TutorialContentType {
  VIDEO = 'video',
  TEXT = 'text',
}

export enum TutorialTag {
  GET_STARTED = 'Get started',
  // ... and others
}

interface TutorialMetadataCommonPart {
  id: string;
  title: string;
  description: string;
  tags: TutorialTag[];
  date: string;
  relatedTutorialsIds?: string;
  contentType: TutorialContentType;
  previewImage?: string;
  isFeatured: boolean;
}

export interface VideoTutorialMetadata extends TutorialMetadataCommonPart {
  link: string;
}

export interface TextTutorialMetadata
  extends MarkdownMetadataCommonPart,
    TutorialMetadataCommonPart {}

export type TutorialMetadata = VideoTutorialMetadata | TextTutorialMetadata;
