import React, { Suspense, useEffect } from 'react';
import { BootErrorPageProps } from '@backstage/core-plugin-api';
import { CustomErrorPage } from '@internal/sg-ui-kit';
import { Progress } from '@backstage/core-components';

const pageHasBeenForceRefreshed = 'page-has-been-force-refreshed';

const retryPageLoading = (): boolean => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem(pageHasBeenForceRefreshed) || 'false',
  ) as boolean;

  if (!pageHasAlreadyBeenForceRefreshed) {
    window.localStorage.setItem(pageHasBeenForceRefreshed, 'true');
    window.location.reload();
    return true;
  }

  window.localStorage.setItem(pageHasBeenForceRefreshed, 'false');
  return false;
};

export const BootErrorPage = ({ step, error }: BootErrorPageProps) => {
  useEffect(() => {
    const isChunkLoadError = error && step === 'load-chunk';
    if (isChunkLoadError) {
      // Reload the page if it's a chunk load error
      retryPageLoading();
    }
  }, [error, step]);

  return (
    <Suspense fallback={<Progress />}>
      <CustomErrorPage status="404" statusMessage="" />
    </Suspense>
  );
};
