import { Config } from '@backstage/config';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { generateHeaders } from 'sg-utils-frontend';

export const featureFlagsApiRef = createApiRef<FeatureFlagApiInterface>({
  id: 'plugin.feature-flags',
});

type FeatureFlagsApiOptions = {
  configApi: Config;
  identityApi: IdentityApi;
};

export interface FeatureFlagApiInterface {
  getBinaryFeatureflags(): Promise<any>;
  getCanaryFeatureflags(): Promise<any>;
  getBinaryFlag(flag_key: string): Promise<any>;
  getCanaryFeatureFlag(flag_key: string): Promise<any>;
  updateBinaryFlag(
    flag_key: string,
    value: boolean,
    idToken: string,
  ): Promise<any>;
  switchFullyEnableCanaryFeatureFlag(
    flag_key: string,
    value: boolean,
    idToken: string,
  ): Promise<any>;
  addValueToCanaryFeatureFlag(
    flag_key: string,
    value: any,
    idToken: string,
  ): Promise<any>;
  removeValueFromCanaryFeatureFlag(
    flag_key: string,
    value: any,
    idToken: string,
  ): Promise<any>;
}

export class FeatureFlagApi implements FeatureFlagApiInterface {
  private configApi: Config;
  private identityApi: IdentityApi;

  constructor(options: FeatureFlagsApiOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
  }

  async getBinaryFeatureflags() {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const url = `${backendUrl}/api/feature-flags/binary`;
    const token = (await this.identityApi.getCredentials()).token;

    const headers = generateHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });

    const response = await fetch(url, {
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(`${data?.error?.message || response.statusText}`);
    }

    return data;
  }

  async getCanaryFeatureflags() {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const url = `${backendUrl}/api/feature-flags/canary`;
    const token = (await this.identityApi.getCredentials()).token;

    const headers = generateHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });

    const response = await fetch(url, {
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(`${data?.error?.message || response.statusText}`);
    }

    return data;
  }

  async getBinaryFlag(flag_key: string): Promise<any> {
    try {
      const backendUrl = this.configApi.getString('backend.baseUrl');
      const url = `${backendUrl}/api/feature-flags/binary/${flag_key}`;
      const token = (await this.identityApi.getCredentials()).token;

      const headers = generateHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      });

      const response = await fetch(url, {
        credentials: 'include',
        headers: Object.fromEntries(headers),
      });
      const data = await response.json();

      if (!response.ok) {
        return false;
      }

      return data;
    } catch (err) {
      return false;
    }
  }

  async getCanaryFeatureFlag(flag_key: string): Promise<any> {
    try {
      const backendUrl = this.configApi.getString('backend.baseUrl');
      const url = `${backendUrl}/api/feature-flags/canary/${flag_key}`;
      const token = (await this.identityApi.getCredentials()).token;

      const headers = generateHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      });

      const response = await fetch(url, {
        credentials: 'include',
        headers: Object.fromEntries(headers),
      });
      const data = await response.json();

      if (!response.ok) {
        return [];
      }

      return data;
    } catch (err) {
      return [];
    }
  }

  async updateBinaryFlag(
    flag_key: string,
    value: boolean,
    idToken: string,
  ): Promise<any> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const url = `${backendUrl}/api/feature-flags/binary/${flag_key}`;
    const token = (await this.identityApi.getCredentials()).token;

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const body = {
      value: value,
    };
    const response = await fetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      const data = await response.json();
      throw new Error(`${data?.error?.message || response.statusText}`);
    }
  }

  async switchFullyEnableCanaryFeatureFlag(
    flag_key: string,
    value: boolean,
    idToken: string,
  ): Promise<any> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const url = `${backendUrl}/api/feature-flags/canary/fullyEnable/${flag_key}`;
    const token = (await this.identityApi.getCredentials()).token;

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const body = {
      value: value,
    };
    const response = await fetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(`${data?.error?.message || response.statusText}`);
    }
  }

  async addValueToCanaryFeatureFlag(
    flag_key: string,
    value: any,
    idToken: string,
  ): Promise<any> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const url = `${backendUrl}/api/feature-flags/canary/${flag_key}`;
    const token = (await this.identityApi.getCredentials()).token;

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const body = {
      value: value,
    };
    const response = await fetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(`${data?.error?.message || response.statusText}`);
    }
  }

  async removeValueFromCanaryFeatureFlag(
    flag_key: string,
    value: any,
    idToken: string,
  ): Promise<any> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const url = `${backendUrl}/api/feature-flags/canary/${flag_key}`;
    const token = (await this.identityApi.getCredentials()).token;

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const body = {
      value: value,
    };
    const response = await fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(`${data?.error?.message || response.statusText}`);
    }
  }
}
