import { Typography } from '@material-ui/core/styles/createTypography';

export const USGTypography: Partial<Typography> = {
  h1: {
    fontSize: 32,
    letterSpacing: 0,
    fontWeight: 700,
    lineHeight: 'normal',
  },
  h2: { fontSize: 28, letterSpacing: 0, fontWeight: 700, lineHeight: 'normal' },
  h3: { fontSize: 28, letterSpacing: 0, fontWeight: 600, lineHeight: 'normal' },
  h4: { fontSize: 18, letterSpacing: 0, fontWeight: 700, lineHeight: 'normal' },
  h5: { fontSize: 16, letterSpacing: 0, fontWeight: 700, lineHeight: 'normal' },
  h6: { fontSize: 14, letterSpacing: 0, fontWeight: 700, lineHeight: 'normal' },
  subtitle1: {
    fontSize: 16,
    letterSpacing: 0,
    fontWeight: 600,
    lineHeight: 'normal',
  },
  subtitle2: {
    fontSize: 14,
    letterSpacing: 0,
    fontWeight: 600,
    lineHeight: 'normal',
  },

  body1: {
    fontSize: 16,
    letterSpacing: 0,
    fontWeight: 400,
    lineHeight: '140%',
  },
  body2: {
    fontSize: 14,
    letterSpacing: 0,
    fontWeight: 400,
    lineHeight: '140%',
  },
  caption: {
    fontSize: 12,
    letterSpacing: 0,
    fontWeight: 600,
    lineHeight: '140%',
  },
  button: {
    fontSize: 14,
    letterSpacing: '1px',
    fontWeight: 700,
    lineHeight: 1.33,
  },
  overline: {
    fontSize: 12,
    lineHeight: 1.33,
    textTransform: 'initial',
  },
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
};
