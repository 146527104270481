import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    padding: theme.spacing(5),
    minHeight: 'calc(100vh - 310px)',
  },
  roleGrid: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px !important',
  },
  resourceBox: {
    // paddingBottom: '20px',
    // // width: '185px',
    width: 163,
    wordBreak: 'break-all',
  },
  resourceOpacity: {
    opacity: 0.5,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px !important',
  },
  resourceGrid: {
    display: 'contents',
  },
  // resourceName: {
  //   padding: '0px 9px 18px 0px',
  //   fontSize: 12,
  //   fontWeight: 700,
  // },
  resourceList: {
    display: 'flex',
  },
  roleType: {
    width: '120px',
    '& div>div': {
      textTransform: 'capitalize',
      fontSize: '14px',
    },
  },
  resourcePaper: {
    padding: '20px',
    marginTop: '15px',
  },
  createUserGroupSection: {
    paddingTop: '1%',
  },
}));
