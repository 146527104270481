import React from 'react';
import { USGTheme } from 'usg-theme';
import { makeStyles, Typography } from '@material-ui/core';
// import Logo from '../../logo/logoicon.svg';

export const useStyles = makeStyles<USGTheme>(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  svg: {
    width: 40,
    height: 40,
    display: 'block',
    marginRight: theme.spacing(2),
    justifyContent: 'left',
  },
  content: {
    width: '120px',
    marginLeft: '58px',
  },
  title: {
    color: theme.palette.usgColors.secondary.lightGray,
  },
  body: {
    color: theme.palette.usgColors.secondary.slateGray,
  },
}));

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {/* <img className={classes.svg} src="" alt='logo' /> */}
      <div className={classes.content}>
        <Typography className={classes.title} variant="h4">
          Stargate
        </Typography>
        <Typography className={classes.body} variant="body2">
          Developer Portal
        </Typography>
      </div>
    </div>
  );
};

export default LogoIcon;
