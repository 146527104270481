import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '40px',
    '& label': {
      marginTop: '10px',
    },
    '& .v5-MuiTextField-root, .MuiOutlinedInput-root, .MuiFormHelperText-root':
      {
        width: '50%',
      },
  },
  datePicker: {
    width: '100%',
    backgroundColor: 'white',
    '& .v5-MuiInputBase-root': {
      height: '2.5em',
    },
  },
  buttons: {
    marginTop: '30px',
    '& button': {
      marginRight: '10px',
    },
  },
  invalidField: {
    color: '#CC0000 !important',
  },
}));
