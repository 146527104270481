import { Config } from '@backstage/config';
import { NotFoundError } from '@backstage/errors';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { generateHeaders } from 'sg-utils-frontend';
import { Component } from './types';

export const statuspageApiRef = createApiRef<StatuspageInterface>({
  id: 'plugin.statuspage',
});

interface StatuspageInterface {
  getComponentStatus(componentId: string): Promise<Component>;
}

interface StatuspageApiOptions {
  configApi: Config;
  identityApi: IdentityApi;
}

export class StatuspageApi implements StatuspageInterface {
  private configApi: Config;
  private identityApi: IdentityApi;
  private backendUrl: string;

  constructor(options: StatuspageApiOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
    this.backendUrl = this.configApi.getString('backend.baseUrl');
  }

  public async getComponentStatus(componentId: string): Promise<Component> {
    const tokenData = await this.identityApi.getCredentials();
    const token = tokenData?.token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/statuspage/components/${componentId}`,
    );

    const headers = generateHeaders({
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
    });

    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }
}
