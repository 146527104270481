import React from 'react';
import { TextField } from '@material-ui/core';
import { ADMIN, RESOURCE_DESCRIPTION_MAX_LENGTH } from 'usg-types';
import { transformResourceName } from 'sg-utils-frontend';
import { GenricResourcePropsType } from './Types';
import { ArtifactoryGitHubWorkflowAccessEditTable } from '../artifactory-github-workflow-access/ArtifactoryGitHubWorkflowAccessEditTable';

type specificPropsType = {
  dataData: any;
  descriptionDirty: boolean;
  onDescriptionClick: () => void;
  showArtifactoryGithubWorkflowAccess: boolean;
  artifactoryProperties: any;
  setArtifactoryProperties: React.Dispatch<any>;
};

const ArtifactorySaasViewEdit = (
  props: React.PropsWithChildren<GenricResourcePropsType<specificPropsType>>,
) => {
  const { eventType, resourceData, formik, specificProps, classes, roles } =
    props;
  const {
    descriptionDirty,
    onDescriptionClick,
    showArtifactoryGithubWorkflowAccess,
    artifactoryProperties,
    setArtifactoryProperties,
  } = specificProps;

  const RClassFieldView = () => {
    return (
      <>
        <h4 className={classes.labelHeader}>R Class</h4>
        {resourceData.config.rclass ? (
          <p className={classes.viewTag}>{resourceData.config.rclass}</p>
        ) : (
          'NA'
        )}
      </>
    );
  };

  const displaySitesFieldView = (r_class: any) => {
    return (
      r_class === 'local' ||
      (roles?.includes(ADMIN) &&
        (r_class === 'remote' || r_class === 'virtual'))
    );
  };

  const viewCase = (
    <>
      <h4 className={classes.labelHeader}>
        {transformResourceName('artifactorysaas')} Repo Key
      </h4>
      <p id="resource-key-value" className={classes.viewTag}>
        {resourceData?.key}
      </p>
      <h4 className={classes.labelHeader}>
        {transformResourceName('artifactorysaas')} Repo Name
      </h4>
      <p id="resource-name-value" className={classes.viewTag}>
        {resourceData?.name}
      </p>
      {resourceData?.description ? (
        <>
          <h4 className={classes.labelHeader}>Description</h4>
          <p id="resource-name-description" className={classes.viewTag}>
            {resourceData?.description ? resourceData.description : ''}
          </p>
        </>
      ) : null}
      {resourceData.config && Object.keys(resourceData.config).length > 0 ? (
        <>
          <h4 className={classes.labelHeader}>Config</h4>
          <div style={{ paddingLeft: '24px' }}>
            <h4 className={classes.labelHeader}>Package Type</h4>
            {resourceData.config.packageType ? (
              <p className={classes.viewTag}>
                {resourceData.config.packageType}
              </p>
            ) : (
              'NA'
            )}
            <RClassFieldView />
            {displaySitesFieldView(resourceData?.config?.rclass) && (
              <>
                <h4 className={classes.labelHeader}>Sites</h4>
                {resourceData.config.sites ? (
                  <p className={classes.viewTag}>
                    {resourceData.config.sites.join(', ')}
                  </p>
                ) : (
                  'NA'
                )}
              </>
            )}
          </div>
        </>
      ) : null}
    </>
  );

  const editCase = (
    <>
      <h4 className={classes.labelHeader}>
        {transformResourceName('artifactorysaas')} Repo Key
      </h4>
      <p className={classes.viewTag}>{resourceData?.key}</p>
      <h4 className={classes.labelHeader}>
        {transformResourceName('artifactorysaas')} Repo Name
      </h4>
      <p className={classes.viewTag}>{resourceData?.name}</p>
      <h4 className={classes.labelHeader}>Description</h4>
      <TextField
        id="resource-description"
        name="resourceDescription"
        type="text"
        onChange={formik.handleChange}
        value={formik.values.resourceDescription}
        minRows={3}
        multiline
        fullWidth
        error={
          formik.values.resourceDescription.length >
            RESOURCE_DESCRIPTION_MAX_LENGTH && descriptionDirty
        }
        helperText={`Provide a brief description for your ${transformResourceName(
          'artifactorysaas',
        )} repo. Number of characters allowed Max = ${RESOURCE_DESCRIPTION_MAX_LENGTH}`}
        placeholder="Example : Repository for storing the artifacts for development within the One-click delivery automation solutions project."
        onClick={onDescriptionClick}
      />
      {resourceData.config && Object.keys(resourceData.config).length > 0 ? (
        <>
          <h4 className={classes.labelHeader}>Config</h4>
          <div style={{ paddingLeft: '24px' }}>
            <h4 className={classes.labelHeader}>Package Type</h4>
            {resourceData.config.packageType ? (
              <p className={classes.viewTag}>
                {resourceData.config.packageType}
              </p>
            ) : (
              'NA'
            )}
            <RClassFieldView />
            {displaySitesFieldView(resourceData?.config?.rclass) && (
              <>
                <h4 className={classes.labelHeader}>Sites</h4>
                {resourceData.config.sites ? (
                  <p className={classes.viewTag}>
                    {resourceData.config.sites.join(', ')}
                  </p>
                ) : (
                  'NA'
                )}
              </>
            )}
          </div>
        </>
      ) : null}
      {showArtifactoryGithubWorkflowAccess && (
        <ArtifactoryGitHubWorkflowAccessEditTable
          name={resourceData?.name}
          artifactoryProperties={artifactoryProperties}
          setArtifactoryProperties={setArtifactoryProperties}
        />
      )}
    </>
  );

  switch (eventType) {
    case 'edit':
      return editCase;
    default:
      return viewCase;
  }
};

export default ArtifactorySaasViewEdit;
