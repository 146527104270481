import React from 'react';
import { StepIconProps } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import cn from 'classnames';
import { useStyles } from './styles';

export const StepIcon = (props: StepIconProps) => {
  const classes = useStyles();
  const { active, completed, error, icon: stepNumber } = props;

  const getIcon = () => {
    if (completed) {
      return <CheckIcon fontSize="small" />;
    }
    if (error) {
      return <ClearIcon fontSize="small" />;
    }
    return stepNumber;
  };

  return (
    <div
      className={cn(classes.root, {
        [classes.coloured]: active || completed,
        [classes.error]: error,
      })}
    >
      {getIcon()}
    </div>
  );
};
