import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogActions: {
    justifyContent: 'right',
    paddingBottom: '20px',
    marginRight: '16px',
  },
  iconContainer: {
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '1px',
    paddingRight: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '26px',
  },
  deleteIcon: {
    padding: '3px',
    margin: '30px 0px 10px 5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconColor: {
    color: theme.palette.usgColors.secondary.blue,
  },
  disabledIconColor: {
    color: '#9FC3FF',
  },
  hyperButton: {
    borderRadius: '10px',
    border: 'dashed',
    color: theme.palette.usgColors.secondary.blue,
  },
  errorMsg: {
    marginBottom: '16px',
    color: 'red',
  },
  resourceName: {
    display: 'block',
    width: '450px',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
  },
  tooltip: {
    color: '#000000',
    backgroundColor: '#E6E6E6',
  },
  tooltipArrow: {
    color: '#E6E6E6',
  },
}));
