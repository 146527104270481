import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Grid,
  Link,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { definitions } from '../../api';
import { ApplicationRequestProps } from './types';
import {
  StyledTextField,
  ControlAutocomplete,
  isMatchArtifactorySaaSHost,
  isEmptyOrNil,
} from './FormHelpers';
import { CreateArtifactoryDialog } from '../CreateArtifactoryDialog';
import {
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
  ARTIFACTORY_DEV_TOOL_ID,
} from 'usg-types';
import {
  ARTIFACTORY_PROD_HOST,
  ARTIFACTORY_SAAS_NAME,
  ARTIFACTORY_SAAS_PROD_HOST,
  ARTIFACTORY_SELF_HOSTED_NAME,
} from './Constants';
import { getTechDocsLink } from 'sg-utils-frontend';

// Schemas
export type NamespaceRequestArtifactoryRepository =
  definitions['handlers.NamespaceRequestArtifactoryRepository'];
type NamespaceAutocompletionResponseProjectType =
  definitions['handlers.NamespaceAutocompletionResponseProject'];
type ArtifactoryRegistry = definitions['handlers.ArtifactoryRegistry'];

// props schema
export type ArtifactoryRepositoryProps = {
  stargateProject: NamespaceAutocompletionResponseProjectType;
  defaultArtifactoryRepo: ArtifactoryRegistry[];
  artifactoryRepositoryOptions: ArtifactoryRegistry[];
  autocompletionsLoading: boolean;
  validationSetter: (isValid: boolean) => void;
  isFormCreation: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    inlineCreate: {
      display: 'flex',
      alignItems: 'center',
    },
    inlineCreateLink: {
      padding: '0 5px 0 0',
      fontWeight: 'bolder',

      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);

export const ArtifactoryRepository = (props: ArtifactoryRepositoryProps) => {
  const {
    stargateProject,
    defaultArtifactoryRepo,
    artifactoryRepositoryOptions,
    autocompletionsLoading,
    validationSetter,
    isFormCreation,
  } = props;
  const classes = useStyles();

  const {
    setValue,
    control,
    getFieldState,
    formState: { isValid },
  } = useFormContext<ApplicationRequestProps>();

  useEffect(() => {
    validationSetter(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const [artifactorySaasRepoValue, setArtifactorySaasRepoValue] =
    useState<ArtifactoryRegistry>(defaultArtifactoryRepo[0]);

  const [artifactorySelfHostedRepoValue, setArtifactorySelfHostedRepoValue] =
    useState<ArtifactoryRegistry>(defaultArtifactoryRepo[1]);

  const [artifactorySaasHostValue, setArtifactorySaasHostValue] =
    useState<string>(defaultArtifactoryRepo[0].host);

  const [artifactorySelfHostedHostValue, setArtifactorySelfHostedHostValue] =
    useState<string>(defaultArtifactoryRepo[1].host);

  const [registries, setRegistries] = useState<ArtifactoryRegistry[]>(
    artifactoryRepositoryOptions,
  );

  const [artifactoryDialogOpen, setArtifactoryDialogOpen] =
    useState<boolean>(false);

  const resetArtifactorySaasRepo = () => {
    const empty = {
      key: '',
      name: '',
      host: defaultArtifactoryRepo[0].host,
      dev_tool_id: defaultArtifactoryRepo[0].dev_tool_id,
    };

    setArtifactorySaasRepoValue(empty);
    setValue('artifactory_registries.0', empty, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const resetArtifactorySelfHostedRepo = () => {
    const empty = {
      key: '',
      name: '',
      host: defaultArtifactoryRepo[1].host,
      dev_tool_id: defaultArtifactoryRepo[1].dev_tool_id,
    };

    setArtifactorySelfHostedRepoValue(empty);
    setValue('artifactory_registries.1', empty, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleDialogClose = (data: {
    key: string;
    name: string;
    dev_tool_id: number;
  }) => {
    if (!isEmptyOrNil(data) && data.key && data.name && data.dev_tool_id) {
      const { key, name, dev_tool_id } = data;

      const newResourceData = {
        key,
        name,
        dev_tool_id,
        host: artifactorySaasHostValue,
      } as ArtifactoryRegistry;

      setValue('artifactory_registries.0', newResourceData, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setRegistries(registries.concat(newResourceData));
      setArtifactorySaasRepoValue(newResourceData);
    }

    setArtifactoryDialogOpen(false);
  };

  const onRepoHostChange = (flavor: string) => {
    return (_: React.ChangeEvent<{}>, v: any) => {
      const isSaaSFlavor = flavor === ARTIFACTORY_SAAS_NAME;

      if (isSaaSFlavor) {
        setArtifactorySaasHostValue(v as string);
        resetArtifactorySaasRepo();
      } else {
        setArtifactorySelfHostedHostValue(v as string);
        resetArtifactorySelfHostedRepo();
      }
    };
  };

  const onArtRepoChange = (flavor: string) => {
    return (_: React.ChangeEvent<{}>, v: ArtifactoryRegistry) => {
      const isSaaSFlavor = flavor === ARTIFACTORY_SAAS_NAME;

      if (!v) {
        if (isSaaSFlavor) {
          resetArtifactorySaasRepo();
        } else {
          resetArtifactorySelfHostedRepo();
        }
      } else {
        if (isSaaSFlavor) {
          setArtifactorySaasRepoValue(v);
          setValue('artifactory_registries.0', v, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          setArtifactorySelfHostedRepoValue(v);
          setValue('artifactory_registries.1', v, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }
    };
  };

  const filterRegistry = (isArtSaas: boolean) =>
    registries
      .filter(o => isMatchArtifactorySaaSHost(o.host) === isArtSaas)
      .sort((a, b) => a.name.localeCompare(b.name));

  const getArtifactoryDevToolId = (host: string) => {
    return isMatchArtifactorySaaSHost(host)
      ? ARTIFACTORY_SAAS_DEV_TOOL_ID
      : ARTIFACTORY_DEV_TOOL_ID;
  };

  return (
    <>
      <Grid container spacing={3} id="mtfuji_wizard_artifactory_registries">
        <Grid item xs={12}>
          <Typography variant="h5">Container Registry</Typography>
          <p>
            Container images built by the CI/CD pipeline need to be stored on a
            container registry so that Kubernetes can pull the image when
            starting containers. Stargate Multicloud uses{' '}
            {
              <Link
                target="_new"
                href={`https://${ARTIFACTORY_SAAS_PROD_HOST}/`}
              >
                Artifactory (SaaS)
              </Link>
            }{' '}
            as the integrated container registry.{' '}
            {isFormCreation ? (
              <p>
                <small>
                  <i>
                    Deprecation Notice: New SMC applications can only use
                    Artifactory (SaaS) as a container registry. If you have a
                    registry on{' '}
                    <Link
                      target="_new"
                      href={`https://${ARTIFACTORY_PROD_HOST}/`}
                    >
                      Artifactory (Self-hosted)
                    </Link>
                    , please consider{' '}
                    <Link
                      target="_new"
                      href={getTechDocsLink('artifactory-cloud-migration')}
                    >
                      migrating
                    </Link>{' '}
                    before creating your SMC application.
                  </i>
                </small>
              </p>
            ) : (
              <p>
                <small>
                  <i>
                    Deprecation Notice:{' '}
                    <Link
                      target="_new"
                      href={`https://${ARTIFACTORY_PROD_HOST}/`}
                    >
                      Artifactory (Self-hosted)
                    </Link>{' '}
                    will soon be deprecated and it is highly encouraged to{' '}
                    <Link
                      target="_new"
                      href={getTechDocsLink('artifactory-cloud-migration')}
                    >
                      migrate
                    </Link>{' '}
                    your resources over to Artifactory (SaaS).
                  </i>
                </small>
              </p>
            )}
          </p>
        </Grid>
        <Grid item xs={4}>
          <ControlAutocomplete
            name="artifactory_registries.0.host"
            rules={{ required: true }}
            control={control}
            loading={autocompletionsLoading}
            options={[defaultArtifactoryRepo[0].host]}
            getOptionLabel={o => o}
            getOptionSelected={(o, v) => o === v}
            onChange={onRepoHostChange(ARTIFACTORY_SAAS_NAME)}
            value={artifactorySaasHostValue}
            renderInput={params => (
              <StyledTextField {...params} label="Artifactory (SaaS) Host" />
            )}
          />
        </Grid>
        <Grid item xs={7}>
          <ControlAutocomplete
            name="artifactory_registries.0"
            rules={{ required: true }}
            control={control}
            loading={autocompletionsLoading}
            options={registries}
            filterOptions={() => filterRegistry(true)}
            getOptionLabel={o => o.name}
            getOptionSelected={(o, v) => o.key === v.key}
            onChange={onArtRepoChange(ARTIFACTORY_SAAS_NAME)}
            value={artifactorySaasRepoValue}
            renderInput={params => (
              <StyledTextField
                {...params}
                label="Container Registry"
                placeholder="Select a container registry from the list"
                error={getFieldState('artifactory_registries.0').invalid}
                helperText={
                  <>
                    <div className={classes.inlineCreate}>
                      <Link
                        className={classes.inlineCreateLink}
                        onClick={() =>
                          setArtifactoryDialogOpen(
                            !isEmptyOrNil(artifactorySaasHostValue),
                          )
                        }
                      >
                        Create a new one
                      </Link>{' '}
                      or select the private container registry to use from the
                      list.
                    </div>
                    {getFieldState('artifactory_registries.0').invalid && (
                      <Typography
                        variant="inherit"
                        color="error"
                        style={{ color: '#CC0000' }}
                      >
                        {
                          getFieldState('artifactory_registries.0').error
                            ?.message
                        }
                      </Typography>
                    )}
                  </>
                }
              />
            )}
          />
        </Grid>
        {!isFormCreation && (
          <>
            <Grid item xs={4}>
              <ControlAutocomplete
                name="artifactory_registries.1.host"
                rules={{ required: true }}
                control={control}
                loading={autocompletionsLoading}
                options={[defaultArtifactoryRepo[1].host]}
                getOptionLabel={o => o}
                getOptionSelected={(o, v) => o === v}
                onChange={onRepoHostChange(ARTIFACTORY_SELF_HOSTED_NAME)}
                value={artifactorySelfHostedHostValue}
                renderInput={params => (
                  <StyledTextField
                    {...params}
                    label="Artifactory (Self-hosted) Host"
                  />
                )}
              />
            </Grid>
            <Grid item xs={7}>
              <ControlAutocomplete
                name="artifactory_registries.1"
                rules={{ required: true }}
                control={control}
                loading={autocompletionsLoading}
                options={registries}
                filterOptions={() => filterRegistry(false)}
                getOptionLabel={o => o.name}
                getOptionSelected={(o, v) => o.key === v.key}
                onChange={onArtRepoChange(ARTIFACTORY_SELF_HOSTED_NAME)}
                value={artifactorySelfHostedRepoValue}
                renderInput={params => (
                  <StyledTextField
                    {...params}
                    label="Container Registry"
                    placeholder="Select a container registry from the list"
                    error={getFieldState('artifactory_registries.1').invalid}
                    helperText={
                      getFieldState('artifactory_registries.1').invalid
                        ? getFieldState('artifactory_registries.1').error
                            ?.message
                        : ''
                    }
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
      <CreateArtifactoryDialog
        open={artifactoryDialogOpen}
        handleDialogClose={handleDialogClose}
        stargateProject={stargateProject}
        devTool={getArtifactoryDevToolId(artifactorySaasHostValue)}
      />
    </>
  );
};
