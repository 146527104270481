import { useState } from 'react';
import {
  useApi,
  microsoftAuthApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { projectApiRef } from '@internal/plugin-projects';
import { createGlobalState, useEffectOnce } from 'react-use';
import { DevToolData } from 'usg-types';
import { transformResourceName } from 'sg-utils-frontend';

const useGlobalDevtools = createGlobalState<[]>([]);
const useGlobalDevtoolsIdToNameMapping = createGlobalState<{}>({});
const useGlobalDevtoolsNameToIdMapping = createGlobalState<{}>({});

export interface Devtoolhook {
  allDevtools: Array<any>;
  allDevtoolsIdToNameMapping: { [index: number]: string };
  allDevtoolsNametoIdMapping: { [index: string]: number };
  isSuccess: boolean;
  message: string;
  isLoading: boolean;
}

export function useDevtoolsApi(): Devtoolhook {
  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);

  const [allDevtools, setAllDevtools] = useGlobalDevtools();
  const [allDevtoolsIdToNameMapping, setAllDevtoolsIdToNameMapping] =
    useGlobalDevtoolsIdToNameMapping();
  const [allDevtoolsNametoIdMapping, setAllDevtoolsNameToIdMapping] =
    useGlobalDevtoolsNameToIdMapping();
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchDevtools = async (token: any) => {
    try {
      const devtoolsRes: any = await projectApi.getDevelopmentToolsData(token);
      return devtoolsRes?.development_tools;
    } catch (errorMsg) {
      errorApi.post(new Error(`${errorMsg?.message}`));
      return [];
    }
  };

  //   const appendDevtools = async () => {
  //     const idToken = await authref.getIdToken();

  //     const devtoolsRes = await fetchDevtools(idToken);
  //     let devtools = devtoolsRes?.development_tools;
  //     return devtools;
  //   };

  useEffectOnce(() => {
    if (allDevtools?.length === 0) {
      setIsLoading(true);
      (async () => {
        try {
          const idToken = await authref.getIdToken();
          const devtools = await fetchDevtools(idToken);
          const nameToID: any = {};
          const idToName: any = {};
          devtools.forEach((devtool: DevToolData) => {
            nameToID[transformResourceName(devtool.name)] = devtool.id;
            idToName[devtool.id] = transformResourceName(devtool.name);
          });
          setAllDevtools(devtools);
          setAllDevtoolsIdToNameMapping(idToName);
          setAllDevtoolsNameToIdMapping(nameToID);
          setIsSuccess(true);
        } catch (err) {
          setMessage(err?.message);
        } finally {
          setIsLoading(false);
        }
      })();
    } else {
      setIsSuccess(true);
    }
  });

  return {
    allDevtools,
    allDevtoolsIdToNameMapping,
    allDevtoolsNametoIdMapping,
    isSuccess,
    message,
    isLoading,
  };
}
