import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import {
  commonDisableRoleSelection,
  commonHideOwnerResources,
  createDevtoolRoleNameMapping,
  getTechDocsLink,
  transformResourceName,
} from 'sg-utils-frontend';
import { OWNER, VAULT_DEV_TOOL_ID } from 'usg-types';

import { SGRowItem } from '@internal/sg-ui-kit';

import { AttachResource } from '../../components/AttachResource';
import { DetachResource } from '../../components/DetachResource';
import { ProtectedComponent } from '../../components/ProtectedComponent';
import { UpdateResourceRole } from '../../components/UpdateResourceRole';
import { ResourceResultState } from '../../components/AttachResource/AttachResource';
import { useStyles } from './styles';

interface ProjectResource {
  id: number;
  dev_tool_id: number;
  dev_tool_name: string;
  key: string;
  name: string;
  url: string;
  environment: string;
  role: string;
  config: Object;
  generated_resource_key: string;
}

export interface AttachedProjectResource {
  dev_tool_id: number;
  dev_tool_name: string;
  attachedResources: Array<ProjectResource>;
}

export interface AttachedResourcesByDevTool {
  [devTooID: string]: AttachedProjectResource;
}

export interface ResourcesTabIProps {
  attachedResourcesByDevTool: AttachedResourcesByDevTool;
  projectId: string;
  groupId: string;
  attachableProjectResources: AttachedResourcesByDevTool;
  devTools: any;
  groupData: any;
  fetchUserGroupResources: any;
  loadingFetchUserGroupResources: any;
  isAdmin: boolean;
  isOwner: boolean;
  resourceData: any;
  isGroupArchived: boolean;
  totalUsersCount: number;
}

const ResourcesTab = (props: ResourcesTabIProps) => {
  const {
    projectId,
    groupId,
    attachedResourcesByDevTool,
    attachableProjectResources,
    devTools,
    groupData,
    fetchUserGroupResources,
    loadingFetchUserGroupResources,
    isAdmin,
    isOwner,
    isGroupArchived,
    totalUsersCount,
  } = props;
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [showDetachResource, setShowDetachResource] = useState(false);
  const [showUpdateResource, setShowUpdateResource] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

  const [resourcesResult, setResourcesResult] = useState<ResourceResultState>({
    success: [],
    fail: [],
  });
  const [selectedResource, setSelectedResource] = useState({
    name: '',
    id: 0,
    role: '',
    devtool_id: 0,
  });

  const openSuccessMessage = (resourcesResult.success.length > 0 ||
    isUpdateSuccess) && (
    <>
      <Typography variant="body2">
        <Box fontWeight={700}>
          Resource to usergroup assignment updated successfully. It can take up
          to 2 hours to synchronize fully.{' '}
          <Link
            className={classes.hyperLink}
            target="_blank"
            to={getTechDocsLink('dev-tool-sync')}
          >
            Learn more here.
          </Link>
        </Box>
      </Typography>
      {resourcesResult.fail.length > 0 && (
        <Divider style={{ margin: '10px' }} />
      )}
    </>
  );

  const openFailMessage =
    resourcesResult.fail.length > 0 ? (
      <div style={{ color: '#CC0000' }}>
        <Box mt={1} display="flex" alignItems="center">
          <span style={{ alignSelf: 'center', paddingRight: '5px' }}>
            <ErrorIcon htmlColor="#CC0000" />
          </span>
          <span>An error occurred assigning the following resources:</span>
        </Box>
        <ul>
          {resourcesResult?.fail?.map((res: any) => {
            return (
              <li key={`error-resource-${res.id}`}>
                {res.name} : {res.message} (code {res.status})
              </li>
            );
          })}
        </ul>
      </div>
    ) : (
      ''
    );

  const allowedToChangeRole = (key: string) => {
    // only admin or owner can change role
    if (!isAdmin && !isOwner) {
      return false;
    }

    const resHasRoles =
      devTools.find(
        (dt: any) => dt.id === attachedResourcesByDevTool[key].dev_tool_id,
      )?.roles.length > 0;
    // user with no role to this resource can not change role of this resource
    if (!resHasRoles) {
      return false;
    }

    // in here we put that vault is excluded in this checking because vault is different than other reources
    // and the following check only applies for other resources, not vault
    // we did not modify commonDisableRoleSelection(key) function because it is used by others and can break other parts
    if (
      !isAdmin &&
      commonDisableRoleSelection(key) &&
      key !== String(VAULT_DEV_TOOL_ID)
    ) {
      return false;
    }

    return true;
  };

  const allowedToDetach = (key: string) => {
    // only admin or owner can detach resource
    if (!isAdmin && !isOwner) {
      return false;
    }

    // in here we put that vault is excluded in this checking because vault is different than other reources
    // and the following check only applies for other resources, not vault
    // we did not modify commonDisableRoleSelection(key) function because it is used by others and can break other parts
    if (
      !isAdmin &&
      commonHideOwnerResources(key) &&
      key !== String(VAULT_DEV_TOOL_ID)
    ) {
      return false;
    }
    return true;
  };

  const resourcesSection = Object.keys(attachedResourcesByDevTool)?.map(
    (key: string) => {
      const resourceByDevtool = attachedResourcesByDevTool[key];
      if (!resourceByDevtool) {
        return <></>;
      }
      const devtoolsRolesMapping = createDevtoolRoleNameMapping(devTools);
      const changeRoleActionEnabled = allowedToChangeRole(key);
      const detachAssignActionEnabled = allowedToDetach(key);

      return (
        <div
          id={`resource-devtool-${resourceByDevtool.dev_tool_id}`}
          key={`resource_dev_tool_${resourceByDevtool.dev_tool_name}`}
        >
          <Typography style={{ fontWeight: '700', fontSize: '14px' }}>
            {transformResourceName(resourceByDevtool.dev_tool_name)}
          </Typography>

          {resourceByDevtool?.attachedResources?.map((apr: ProjectResource) => (
            <SGRowItem
              title={{ text: apr.name, id: `resourcename-${apr.id}` }}
              secondaryText={{
                text: devtoolsRolesMapping[apr.dev_tool_id][apr.role],
                id: `currentrole-resourceid-${apr.id}`,
              }}
              linkTo={`/projects/${projectId}/manageresource/${apr.id}`}
              key={`resource_row_${apr.name}-${apr.environment}`}
              tags={
                apr.dev_tool_id === VAULT_DEV_TOOL_ID
                  ? [{ label: apr.environment, color: 'primary' }]
                  : []
              }
              actions={[
                {
                  label: 'Change Role',
                  onClick: () => {
                    setSelectedResource({
                      name: apr.name,
                      id: apr.id,
                      role: apr.role,
                      devtool_id: parseInt(key, 10),
                    });
                    setShowUpdateResource(true);
                  },
                  props: {
                    className: classes.actionBtn,
                    id: `btn-change-role-resourceid-${apr.id}`,
                  },
                  visible: changeRoleActionEnabled,
                },
                {
                  label: 'Detach Resource',
                  onClick: () => {
                    setSelectedResource({
                      name: apr.name,
                      id: apr.id,
                      role: apr.role,
                      devtool_id: parseInt(key, 10),
                    });
                    setShowDetachResource(true);
                  },
                  props: {
                    className: classes.actionBtn,
                    id: `btn-detach-resourceid-${apr.id}`,
                  },
                  visible: detachAssignActionEnabled,
                },
              ]}
            />
          ))}
        </div>
      );
    },
  );

  if (loadingFetchUserGroupResources) {
    return (
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        style={{ flexDirection: 'column' }}
      >
        <CircularProgress /> Fetching resources
      </Grid>
    );
  }
  const handleSuccessClose = async () => {
    setOpenSuccess(false);
    setIsUpdateSuccess(false);
    setResourcesResult({
      success: [],
      fail: [],
    });
    await fetchUserGroupResources();
  };
  return (
    <div className={classes.deleteUserIcon}>
      <Grid container justifyContent="space-between">
        <Grid item xs={9}>
          {resourcesSection}
        </Grid>
        <Grid item>
          {!isGroupArchived && (
            <ProtectedComponent projectId={projectId} roles={[OWNER]}>
              <Button
                variant="contained"
                color="default"
                onClick={() => setShow(true)}
              >
                Assign Resources
              </Button>
            </ProtectedComponent>
          )}
        </Grid>
      </Grid>
      <AttachResource
        isVisible={show}
        handleClose={() => {
          setShow(false);
        }}
        handleSuccess={() => {
          setOpenSuccess(true);
          setIsUpdateSuccess(true);
        }}
        projectId={projectId}
        groupId={groupId}
        resources={attachableProjectResources}
        setResourcesResult={setResourcesResult}
        devTools={devTools}
        groupData={groupData}
        isAdmin={isAdmin}
        isOwner={isOwner}
        totalUsersCount={totalUsersCount}
      />
      <DetachResource
        isVisible={showDetachResource}
        handleClose={() => {
          setShowDetachResource(false);
        }}
        handleSuccess={() => {
          setOpenSuccess(true);
          setIsUpdateSuccess(true);
        }}
        projectId={projectId}
        groupId={groupId}
        resourceId={selectedResource?.id}
        resourceName={selectedResource?.name}
        groupData={groupData}
        tab="resources"
      />

      <UpdateResourceRole
        isVisible={showUpdateResource}
        handleSuccess={() => {
          setOpenSuccess(true);
          setIsUpdateSuccess(true);
        }}
        handleClose={() => {
          setShowUpdateResource(false);
          setSelectedResource({
            name: '',
            id: 0,
            role: '',
            devtool_id: 0,
          });
        }}
        projectId={projectId}
        resourceId={selectedResource?.id}
        resourceRole={selectedResource?.role}
        groupData={groupData}
        resourceName={selectedResource?.name}
        resourceRoleOptions={
          devTools.find((dt: any) => dt.id === selectedResource?.devtool_id)
            ?.roles
        }
      />
      <Dialog
        open={openSuccess}
        onClose={handleSuccessClose}
        maxWidth="xs"
        style={{ top: '5' }}
        className={classes.dialog}
      >
        <DialogContent style={{ fontWeight: 'bold' }}>
          {openSuccessMessage}
          {openFailMessage}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            id="btn-assign-resource-modal-close"
            size="small"
            onClick={handleSuccessClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ResourcesTab;
