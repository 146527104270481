/**
 * Transforms the core devtool name into a business name to be display to portal.
 *
 * @param name devtool core name
 * @returns devtool display name
 */
export const transformResourceName = (name: string) => {
  switch (name?.toLowerCase()) {
    case 'github':
      return 'GitHub Enterprise';
    case 'githubemu':
      return 'GitHub (EMU)';
    case 'mtfuji':
    case 'mt. fuji':
      return 'Stargate Multicloud';
    case 'artifactory':
      return 'Artifactory (Self-hosted)';
    case 'artifactorysaas':
      return 'Artifactory (SaaS)';
    default:
      return name;
  }
};

/**
 * Transforms the RClass value for Artifactory SaaS based on the user role.
 *
 * @param rClass  RClass value
 * @param userRole  User role
 * @returns transformed RClass value
 */
export const transformArtifactorySaaSRClassValue = (
  rClass: string,
  userRole: string,
): string => {
  if (userRole !== 'admin') {
    return 'federated';
  }
  return rClass === 'local' ? 'federated' : rClass;
};

/**
 * Returns the first two letters of the string.
 * If the string is an email
 *   - If the email name has dots, return the first characters of first two words.
 *     Example: john.smith@email.com  -> JS
 *   - If the email has no dots, return the first two characters.
 *     Example: johnsmith@email.com   -> JO
 *   - If the value is a name separate by space
 *     Example: John Smith -> JS
 *
 * Limitation: Only supports alphabet characters.
 *
 * @param value
 * @returns Two characters
 */
export const getFirstTwoLetters = (value: string, capitalized: boolean) => {
  if (value.length <= 0) {
    return [];
  }

  let result: string[] = [];

  const email = value.split('@');
  if (email.length >= 2) {
    const words = email[0].split('.');
    if (words.length >= 2) {
      result = [words[0].charAt(0), words[1].charAt(0)];
    } else {
      result = [words[0].charAt(0), words[0].charAt(1)];
    }
  } else {
    const names = value.split(' ');
    if (names.length >= 2) {
      result = [names[0].charAt(0), names[1].charAt(0)];
    } else if (value.length >= 2) {
      result = [names[0].charAt(0), names[0].charAt(1)];
    }
  }

  if (capitalized) {
    result = [result[0].toLocaleUpperCase(), result[1].toLocaleUpperCase()];
  } else {
    result = [result[0].toLocaleLowerCase(), result[1].toLocaleLowerCase()];
  }

  return result;
};

/**
 * Returns the first and last name from a given email string.
 *
 *   - If the email name has a dot, split by dot and assume first substring is first name,
 *     and last substring is last name.
 *     Example: john.smith@email.com  -> John Smith
 *   - If the email has no dots, return the email in it's entirety (cannot parse)
 *
 *
 * @param value
 * @returns String of `${firstname} ${lastname}`
 */
export const getNameFromEmail = (
  value: string,
  capitalized: boolean,
): string => {
  const nameSubstring = value.split('@')[0].split('.');

  if (nameSubstring.length !== 2) {
    return value;
  }

  return nameSubstring
    .reduce(
      (nameString, substring) =>
        `${nameString} ${
          capitalized
            ? substring[0].toLocaleUpperCase() + substring.slice(1)
            : substring.toLocaleLowerCase()
        }`,
      '',
    )
    .trim();
};
