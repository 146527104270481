import ConfluenceLogo from './confluence.svg';
import JiraLogo from './jira.svg';
import GithubLogo from './github.svg';
import ArchLogo from './archlinux.svg';
import CppLogo from './c-plusplus.svg';
import GoLogo from './go.svg';
import JavaLogo from './java.svg';
import JavascriptLogo from './javascript.svg';
import PythonLogo from './python.svg';
import RubyLogo from './ruby.svg';
import ReactLogo from './react.svg';

import StargateLogo from './stargate.svg';

import ConfluenceGrayscaleLogo from './confluence-bw.svg';
import JiraGrayscaleLogo from './jira-bw.svg';
import GithubGrayscaleLogo from './github-bw.svg';
import ArchGrayscaleLogo from './archlinux-bw.svg';
import CppGrayscaleLogo from './c-plusplus-bw.svg';
import GoGrayscaleLogo from './go-bw.svg';
import JavaGrayscaleLogo from './java-bw.svg';
import JavascriptGrayscaleLogo from './javascript-bw.svg';
import PythonGrayscaleLogo from './python-bw.svg';
import RubyGrayscaleLogo from './ruby-bw.svg';
import ReactGrayscaleLogo from './react-bw.svg';

export const logos = {
  confluence_guru: ConfluenceLogo,
  jira_guru: JiraLogo,
  github: GithubLogo,
  stargate_guru: StargateLogo,
  archlinux_btw: ArchLogo,
  cpp: CppLogo,
  go: GoLogo,
  java: JavaLogo,
  javascript: JavascriptLogo,
  python: PythonLogo,
  ruby: RubyLogo,
  react: ReactLogo,
} as {
  [key in string]: string;
};

export const grayscaleLogos = {
  confluence_guru: ConfluenceGrayscaleLogo,
  jira_guru: JiraGrayscaleLogo,
  github: GithubGrayscaleLogo,
  stargate_guru: StargateLogo,
  archlinux_btw: ArchGrayscaleLogo,
  cpp: CppGrayscaleLogo,
  go: GoGrayscaleLogo,
  java: JavaGrayscaleLogo,
  javascript: JavascriptGrayscaleLogo,
  python: PythonGrayscaleLogo,
  ruby: RubyGrayscaleLogo,
  react: ReactGrayscaleLogo,
} as {
  [key in string]: string;
};
