/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  ProviderComponent,
  ProviderLoader,
  SignInProvider,
  SignInProviderConfig,
} from './types';
import { useApi, errorApiRef } from '@backstage/core-plugin-api';
import { ForwardedError } from '@backstage/errors';
import { UserIdentity } from '@backstage/core-components';
import { useStyles } from './styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const Component: ProviderComponent = ({ config, onSignInSuccess }) => {
  const { apiRef, title, message } = config as SignInProviderConfig;
  const authApi = useApi(apiRef);
  const errorApi = useApi(errorApiRef);
  const classes = useStyles();
  const handleLogin = async () => {
    try {
      const identityResponse = await authApi.getBackstageIdentity({
        instantPopup: true,
      });
      if (!identityResponse) {
        throw new Error(
          `The ${title} provider is not configured to support sign-in`,
        );
      }

      const profile = await authApi.getProfile();
      onSignInSuccess({
        userId: identityResponse!.token,
        getProfileInfo: () => profile!,
        getCredentials: () => {
          return authApi.getBackstageIdentity();
        },
        signOut: async () => {
          await authApi.signOut();
        },
      } as any);
    } catch (error) {
      errorApi.post(new ForwardedError('Login failed', error));
    }
  };

  return (
    <Button
      id="signin-button"
      color="inherit"
      onClick={handleLogin}
      className={classes.button}
    >
      {/* <img src={icon} className={classes.authIcon} alt='icon' /> */}
      <div className={classes.panel}>
        <Typography variant="subtitle1">{message}</Typography>
        <ArrowForwardIcon className={classes.arrowIcon} />
      </div>
    </Button>
  );
};

const loader: ProviderLoader = async (apis, apiRef) => {
  const authApi = apis.get(apiRef)!;

  const identityResponse = await authApi.getBackstageIdentity({
    optional: true,
  });

  if (!identityResponse) {
    return undefined;
  }

  const profile = await authApi.getProfile();

  return UserIdentity.create({
    identity: identityResponse.identity,
    profile,
    authApi,
  });
};

export const commonProvider: SignInProvider = { Component, loader };
