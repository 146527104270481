import { createStyles, makeStyles } from '@material-ui/core/styles';
import { USGTheme } from '../../types';

export const useStyles = makeStyles((theme: USGTheme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      backgroundColor: theme.palette.usgColors.secondary.slateGray,
      color: theme.palette.usgColors.primary.white,

      fontSize: '12px',
      lineHeight: 1,
      fontWeight: 700,
    },
    error: {
      backgroundColor: theme.palette.usgColors.primary.black,
    },
    coloured: {
      borderRadius: '50%',
      backgroundImage: 'linear-gradient(to bottom, #f71840, #ff9265)',
    },
  }),
);
