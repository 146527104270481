export const initialValues = {
  project_description: '',
  management_type: '',
  project_owners: [] as any,
  primary_owners: [] as any,
  access_instructions: '',
  link: '',
  IP_Owner_Companies: [
    {
      company_id: 0,
      started_on: '' as any,
      ended_on: '' as any,
      percentage: 0,
    },
  ],
  Collaborating_Companies: [
    {
      company_id: 0,
      started_on: '' as any,
      ended_on: '' as any,
    },
  ],
};

export const createInitialValues = {
  project_name: '',
  project_key: '',
  project_description: '',
  management_type: '',
  project_owners: [] as any,
  primary_owners: [] as any,
  IP_Owner_Companies: [
    {
      company_id: '' as any,
      started_on: new Date(),
      ended_on: '' as any,
      percentage: '',
    },
  ],
  Collaborating_Companies: [
    {
      company_id: '' as any,
      started_on: new Date(),
      ended_on: '' as any,
    },
  ],
};
