/** Redact emails and query parameters from href and text label before sending to GA4 */
export const redactGAEventAttributes = (
  href: string,
  textLabel: string,
): [string, string] => {
  if (href.startsWith('mailto')) {
    const split = href.split(':');
    return [
      `mailto:${split[1]?.replaceAll(/./g, '*')}`,
      textLabel.replaceAll(/./g, '*'),
    ];
  } else if (href.includes('?')) {
    const hrefSplit = href.split('?');
    const textLabelSplit = textLabel.split('?');
    return [
      `${hrefSplit[0]}?${hrefSplit[1]?.replaceAll(/./g, '*')}`,
      `${textLabelSplit[0]}${
        textLabelSplit[1] ? `?${textLabelSplit[1]?.replaceAll(/./g, '*')}` : ''
      }`,
    ];
  } else if (href.includes('@') && href.includes('/')) {
    const tempHref =
      href.charAt(-1) === '/' ? href.substring(0, href.length - 1) : href;
    const hrefSplitIndex = tempHref.lastIndexOf('/');
    const redactedHref = `${tempHref.substring(0, hrefSplitIndex + 1)}${tempHref
      .substring(hrefSplitIndex)
      .replaceAll(/./g, '*')}`;
    let redactedTextLabel = href === textLabel ? redactedHref : null;
    if (!redactedTextLabel && textLabel.includes('@')) {
      const textLabelSplit = textLabel.split('@');
      redactedTextLabel = `${textLabelSplit[0].replaceAll(
        /./g,
        '*',
      )}${textLabelSplit[1].replaceAll(/./g, '*')}`;
    }
    return [redactedHref, redactedTextLabel ?? textLabel];
  }
  return [href, textLabel];
};
