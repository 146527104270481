import React from 'react';

import { useViewEditResourceContext } from '../../ViewEditResourcePage/context';
import { GenricResourcePropsType } from '../Types';
import NewVaultViewEdit from './NewVault';
import OldVaultViewEdit from './OldVault';
import { specificPropsType } from './types';

const VaultViewEdit = (
  props: React.PropsWithChildren<GenricResourcePropsType<specificPropsType>>,
) => {
  const resourceContext = useViewEditResourceContext();

  if (resourceContext.isNewVaultUiEnabled) {
    return <NewVaultViewEdit {...props} />;
  }
  return <OldVaultViewEdit {...props} />;
};

export default VaultViewEdit;
