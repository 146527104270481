import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { createGlobalState } from 'react-use';

interface withFlagInterface {
  flag: string;
  withValues: any[];
  withoutValues?: never;
}

interface withoutFlagInterface {
  flag: string;
  withValues?: never;
  withoutValues: any[];
}

// should except either withValues or withoutValues
type Props = withFlagInterface | withoutFlagInterface;

const useCachedCanaryFeatureFlags = createGlobalState<any>({});

const promises: any = {};

export const CanaryFeatureFlagged = ({
  children,
  flag,
  withValues,
  withoutValues,
}: PropsWithChildren<Props>) => {
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const errorApi = useApi(errorApiRef);
  const [cachedFeatureFlags, setCachedFeatureFlags] =
    useCachedCanaryFeatureFlags();
  const [canaryValues, setCanaryValues] = useState<any[]>([]);
  const [isCanaryFullenabled, setIsCanaryFullenabled] = useState(false);

  const fetchFlag = useCallback(
    async (flagName: string) => {
      if (promises[flagName]) {
        return promises[flagName];
      }
      const promise = new Promise(async (resolve, reject) => {
        if (flagName in cachedFeatureFlags) {
          resolve(cachedFeatureFlags[flagName]);
        } else {
          try {
            const res = await featureFlagsApi.getCanaryFeatureFlag(flagName);
            const value = res.data;
            setCachedFeatureFlags((prevState: any) => {
              return {
                ...prevState,
                [flagName]: value,
              };
            });
            resolve(value);
          } catch (error) {
            reject(error);
          } finally {
            delete promises[flagName];
          }
        }
      });
      promises[flagName] = promise;
      return promise;
    },
    [cachedFeatureFlags, featureFlagsApi, setCachedFeatureFlags],
  );

  useEffect(() => {
    (async () => {
      try {
        const values = await fetchFlag(flag);
        if (values === true) {
          setIsCanaryFullenabled(true);
        } else {
          setCanaryValues(values);
        }
      } catch (e) {
        errorApi.post(new Error(`${e?.message}`));
      }
    })();
  }, [featureFlagsApi, errorApi, flag, fetchFlag]);

  const isWithValues =
    withValues?.length &&
    withValues.some((item: any) => {
      return canaryValues.includes(item);
    });

  const isWithoutValues =
    withoutValues?.length &&
    withoutValues.every((item: any) => {
      return !canaryValues.includes(item);
    });

  return (
    <>
      {isCanaryFullenabled ? (
        children
      ) : (
        <>
          {isWithValues ? children : null}
          {isWithoutValues ? children : null}
        </>
      )}
    </>
  );
};
