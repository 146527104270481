import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    padding: theme.spacing(5),
    minHeight: 'calc(100vh - 310px)',
    position: 'relative',
    paddingTop: '8px',
  },
}));
