import React, { useEffect, useState } from 'react';

import { Table, TableColumn } from '@backstage/core-components';
import { EmptyDataMessage } from '@internal/sg-ui-kit';
import { Link } from '@material-ui/core';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { DEFAULT_TABLE_PAGE_SIZE_OPTIONS } from 'usg-types';
import { DialogName, VaultTableProps } from './types';

import { usePermissions } from '../../../../../../hooks/usePermissions';
import { AttachUserGroupToResource } from '../../../../../AttachUserGroupToResource';
import { DetachResource } from '../../../../../DetachResource';
import { UpdateResourceRole } from '../../../../../UpdateResourceRole';
import { useUserGroupsHooks } from '../../../hooks/useUserGroupsHook';
import { DialogSuccessAssignUserGroups } from '../DialogSuccessAssignUserGroups';

import { useStyles } from '../../../../styles';

export const VaultTable = ({
  attachableUserGroups,
  projectId,
  resourceData,
  selectedTabIndex,
  disableGroupListChanges,
}: VaultTableProps) => {
  const classes = useStyles();
  const { isAnyAdmin } = usePermissions();
  const {
    setTabIndex,
    environments,
    userGroups,
    refreshAssignedGroups,
    availableUserGroups,
  } = useUserGroupsHooks(projectId, resourceData, attachableUserGroups);

  const [tableState, setTableState] = useState({
    page: 1,
    pageSize: DEFAULT_TABLE_PAGE_SIZE_OPTIONS[0],
  });
  useEffect(() => {
    setTabIndex(selectedTabIndex);
  }, [selectedTabIndex, setTabIndex]);

  useEffect(() => {
    if (!userGroups) {
      return;
    }

    if (userGroups.length > DEFAULT_TABLE_PAGE_SIZE_OPTIONS[0]) {
      // We increase page size in the table because the data from backend for
      // the assigned user groups are actually NOT paginated. therefore, table is not
      // really correct to be chosen for displaying the UserGroups.
      // so by increasing page size, we kinda make the table seems more suitable for it.
      setTableState({
        page: 1,
        pageSize: tableState.pageSize + DEFAULT_TABLE_PAGE_SIZE_OPTIONS[0],
      });
    }
  }, [userGroups, environments, selectedTabIndex, tableState.pageSize]);

  const [selectedUserGroup, setSelectedUserGroup] = useState({
    name: '',
    id: 0,
    role: '',
  });

  const [openSuccess, setOpenSuccess] = useState(false);
  const [isNeedToDisplaySuccess, setIsNeedToDisplaySuccess] = useState(false);

  const [displayedDialog, setDisplayedDialog] = useState<DialogName>(
    DialogName.NONE,
  );

  useEffect(() => {
    if (displayedDialog === DialogName.NONE && isNeedToDisplaySuccess) {
      // We use timeout here because if we open the success dialog immediately, it will make the vertical scrollbar to disappear then the page cannot be scrolled again
      // ideally we use just one dialog instead of 2 dialogs
      // then this 1 dialog will have its content changed during different phases (display first message, processing, success message and failed message)
      setTimeout(() => setOpenSuccess(true), 500);
    } else {
      setIsNeedToDisplaySuccess(false);
    }
  }, [displayedDialog, isNeedToDisplaySuccess]);

  const feedColumns: Array<TableColumn<any>> = [
    {
      field: 'name',
      title: 'Group Name',
      width: '25%',
      render: ({ id, name }) => (
        <Link
          href={`/projects/${projectId}/usergroup/${id}`}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.hyperLink}
          title={name}
        >
          {name}
        </Link>
      ),
    },
    {
      field: 'role',
      title: 'Role',
      width: '25%',
      render: ({ role }) => <Typography>{role}</Typography>,
    },
    {
      field: 'actions',
      title: 'Actions',
      width: '25%',
      render: ({ id, name, role }) => (
        <Stack direction="row" spacing={0}>
          <Button
            disabled={disableGroupListChanges ? true : false}
            disableRipple
            onClick={() => {
              setSelectedUserGroup({
                name: name,
                id: id,
                role: role,
              });
              setDisplayedDialog(DialogName.UPDATE_USER_GROUP_ROLE);
            }}
            sx={{
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '&:active': {
                backgroundColor: 'transparent',
              },
              paddingLeft: 0,
            }}
          >
            <Typography
              className={
                !disableGroupListChanges
                  ? classes.enabledBtn
                  : classes.disabledBtn
              }
              textTransform="initial"
              variant="body2"
              fontWeight="bold"
            >
              Change Role
            </Typography>
          </Button>
          <Button
            disabled={disableGroupListChanges ? true : false}
            disableRipple
            sx={{
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '&:active': {
                backgroundColor: 'transparent',
              },
            }}
            onClick={() => {
              setSelectedUserGroup({
                name: name,
                id: id,
                role: role,
              });
              setDisplayedDialog(DialogName.DETACH_USER_GROUP);
            }}
          >
            <Typography
              className={
                !disableGroupListChanges
                  ? classes.enabledBtn
                  : classes.disabledBtn
              }
              textTransform="initial"
              variant="body2"
              fontWeight="bold"
            >
              Detach Group
            </Typography>
          </Button>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={feedColumns}
        options={{
          paging: true,
          search: false,
          sorting: false,
          actionsColumnIndex: -1,
          padding: 'dense',
          pageSize: tableState.pageSize,
          pageSizeOptions: DEFAULT_TABLE_PAGE_SIZE_OPTIONS,
          emptyRowsWhenPaging: false,
          actionsCellStyle: { padding: '5px 10px' },
        }}
        data={userGroups ?? []}
        title="ASSIGNED GROUP LIST"
        localization={{
          body: {
            emptyDataSourceMessage: (
              <EmptyDataMessage message="No assigned user groups." />
            ),
          },
        }}
        actions={[
          {
            icon: () => (
              <Typography
                fontWeight="bold"
                className={
                  !disableGroupListChanges
                    ? classes.enabledBtn
                    : classes.disabledBtn
                }
              >
                ASSIGN USER GROUP
              </Typography>
            ),
            tooltip: 'assign user group',
            isFreeAction: true,
            onClick: () => setDisplayedDialog(DialogName.ASSIGN_USER_GROUPS),
            disabled: disableGroupListChanges,
            hidden: false,
          },
        ]}
      />
      <AttachUserGroupToResource
        isVisible={displayedDialog === DialogName.ASSIGN_USER_GROUPS}
        handleClose={() => {
          setDisplayedDialog(DialogName.NONE);
        }}
        projectId={projectId}
        userGroups={availableUserGroups}
        isAdmin={isAnyAdmin}
        resourceData={{
          id: environments[selectedTabIndex]?.id,
          roles: resourceData.roles,
          dev_tool_id: resourceData.dev_tool_id,
        }}
        handleSuccess={() => {
          setIsNeedToDisplaySuccess(true);
        }}
      />

      <UpdateResourceRole
        isVisible={displayedDialog === DialogName.UPDATE_USER_GROUP_ROLE}
        handleClose={() => {
          setSelectedUserGroup({
            name: '',
            id: 0,
            role: '',
          });
          setDisplayedDialog(DialogName.NONE);
        }}
        handleSuccess={() => {
          setIsNeedToDisplaySuccess(true);
        }}
        projectId={projectId}
        resourceId={environments[selectedTabIndex]?.id}
        resourceName={resourceData?.name}
        resourceRole={selectedUserGroup?.role}
        groupData={selectedUserGroup} // groupdata should be removed on feautre flag removal
        resourceRoleOptions={resourceData?.roles}
      />

      <DetachResource
        isVisible={displayedDialog === DialogName.DETACH_USER_GROUP}
        handleClose={() => {
          setDisplayedDialog(DialogName.NONE);
        }}
        handleSuccess={() => {
          setIsNeedToDisplaySuccess(true);
        }}
        projectId={projectId}
        groupId={selectedUserGroup?.id.toString()}
        resourceId={environments[selectedTabIndex]?.id}
        resourceName={resourceData?.name}
        groupData={selectedUserGroup} // groupdata should be removed on feautre flag removal
        tab="usergroups"
      />
      <DialogSuccessAssignUserGroups
        isOpen={openSuccess}
        handleSuccessClose={() => {
          setOpenSuccess(false);
          refreshAssignedGroups();
        }}
      />
    </>
  );
};
