import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { LicenseInfo } from '@mui/x-license-pro';
import { useEffectOnce } from 'react-use';

const MUIXLicense = () => {
  const configApi = useApi(configApiRef);

  useEffectOnce(() => {
    LicenseInfo.setLicenseKey(configApi.getString('muix.licensekey'));
  });

  return null;
};

export default MUIXLicense;
