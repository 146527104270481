import { USGColorScheme } from './types';

export const USG_COLOR_SCHEME: USGColorScheme = {
  primary: {
    red: '#EB0A1E',
    blue: '#1059CD',
    white: '#FFFFFF',
    black: '#000000',
    gray: '#697077',
    blue50: '#2E78EF',
  },
  secondary: {
    darkGray: '#121619',
    slateGray: '#8B919A',
    mediumGray: '#C1C7CD',
    silverGray: '#E7EAEE',
    lightGray: '#F8F9FB',
    blue: '#2E78EF',
    lightBlue: '#679DF4',
    darkRed: '#CC0000',
    redberry: '#CC0000',
    green: '#009C0D',
    orange: '#f48b00',
    grey50: '#A2A9B0',
  },
  others: {
    blue: {
      50: '#EBF7FF',
      100: '#ECF3FE',
      200: '#C6DAFB',
      300: '#679DF4',
      400: '#2E78EF',
      500: '#1059CD',
      600: '#0B4198',
      700: '#0D3A80',
      800: '#0D3069',
      900: '#0C2957',
      1000: '#081D3F',
    },
    grey: {
      100: '#F8F9FB',
      200: '#F4F5F7',
      300: '#E7EAEE',
      400: '#C1C7CD',
      500: '#A2A9B0',
      600: '#8B919A',
      700: '#697077',
      800: '#4D5358',
      900: '#343A3F',
      1000: '#121619',
    },
    generic: {
      orange: '#F87720',
      yellow: '#E2D91B',
      green: '#11CE20',
      darkGreen: '#187656',
      teal: '#4BB8A5',
      cyan: '#61DBEA',
      blue: '#27A9F3',
      purple: '#AB82DF',
      darkPurple: '#7C3699',
      magenta: '#FB6DD7',
      grey: '#737373',
    },
    green: {
      100: '#E0FAEA',
      200: '#009C0D',
    },
    red: {
      100: '#FEECED',
    },
    orange: {
      100: '#FFF6EB',
      200: '#F48B00',
    },
  },
};
