import {
  ARTIFACTORY_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
  CONFLUENCE_DEV_TOOL_ID,
  GITHUBEMU_DEV_TOOL_ID,
  GITHUB_DEV_TOOL_ID,
  JAMA_DEV_TOOL_ID,
  JIRA_DEV_TOOL_ID,
} from 'usg-types';

export const ADMIN_ONLY_DEV_TOOL_IDS: number[] = [];
export const COMMON_EDITABLE_DEV_TOOL_IDS = [
  JIRA_DEV_TOOL_ID,
  CONFLUENCE_DEV_TOOL_ID,
  ARTIFACTORY_DEV_TOOL_ID,
  GITHUB_DEV_TOOL_ID,
  JAMA_DEV_TOOL_ID,
  GITHUBEMU_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
];

export type UserGroupObject = {
  id: number;
  name: string;
  rule_based: boolean;
  role: string;
};

export type SortedUserGroupsArrayType = UserGroupObject[];

export interface ViewEditResourcePageComponentProps {
  projectId: string;
  resourceId: string;
}

export type UnarchiveButtonProps = {
  setUnArchiveOpen: (value: boolean) => void;
};

export type EditArchiveButtonProps = {
  devToolId: number;
  projectId: string;
  isAdmin: boolean;
  setEditMode: (value: boolean) => void;
  isProjectOwner: (projectId: number) => boolean;
  readOnlyResources: number[];
  resourceManagerCheck: boolean;
  setOpenArchive: (value: boolean) => void;
};

export type ResourceManagersProps = {
  projectId: string;
  editMode: boolean;
  resourceManagerData: any[];
  setIsAddUserLoading: any;
  setIsAddUserEnable: any;
  isAddUserEnable: boolean;
  addBottomMargin?: boolean;
};

export type ResourceUrlProps = {
  resourceUrl: string;
  projectId: string;
  resourceId: string;
  copyButtonLabel: string;
  setCopyButtonLabel: (value: string) => void;
};

type EmailErrorData = {
  message: string;
};

export type EditButtonsProps = {
  formik: any;
  idTokenData: string;
  devToolId: number;
  projectId: string;
  resourceId: string;
  apiLoader: boolean;
  showArtifactoryGithubWorkflowAccess: boolean;
  artifactoryProperties: any;
  setResourceData: any;
  resourceData: any;
  setOpenEditSucessMsg: (value: boolean) => void;
  setEditMode: (value: boolean) => void;
  setApiLoader: (value: boolean) => void;
  setArtifactoryProperties: any;
  existingArtifactoryProperties: any;
  setUserEmailErrorData: (err: EmailErrorData) => void;
  userEmailErrorData: EmailErrorData;
  isAddUserLoading: boolean;
  isAddUserEnable: boolean;
};

export type DialogUnarchiveProps = {
  unArchiveOpen: boolean;
  setUnArchiveOpen: (value: boolean) => void;
  projectId: string;
  resourceId: string;
  idTokenData: string;
  setUnArchiveSuccessDialog: (value: boolean) => void;
};

export type DialogSuccessfulUnarchivedProps = {
  unArchiveSuccessDialog: boolean;
  setUnArchiveSuccessDialog: (value: boolean) => void;
  navigate: any;
  projectId: string;
};

export type DialogResourceErrorMessageProps = {
  devToolId: number;
  openEditSucessMsg: boolean;
  fetchContent: () => Promise<void>;
  setEditMode: (value: boolean) => void;
  setOpenEditSucessMsg: (value: boolean) => void;
  fetchResourceManagers: (
    email: string,
    specificResourceId: any,
  ) => Promise<void>;
  userEmail: string;
};

export type DialogArchiveResourcesProps = {
  openArcive: boolean;
  projectId: string;
  navigate: any;
  resourceId: string;
  idTokenData: string;
  setOpenArchive: (value: boolean) => void;
};

export type SuccessBarProps = {
  isOpen: boolean;
  children: React.ReactElement;
  setIsSuccessBoxOpen: (value: boolean) => void;
};

export type ViewEditResourceData = {
  isNewVaultUiEnabled: boolean | undefined;
};
