import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { useStyles } from './styles';

const DEFAULT_MESSAGE = 'No data to display';

type Props = {
  message?: string;
  messageComponent?: React.ReactNode;
  iconComponent?: React.ReactNode;
};

export const EmptyDataMessage = ({
  message = DEFAULT_MESSAGE,
  messageComponent,
  iconComponent,
}: Props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.container}
    >
      <Grid item>
        <div className={classes.icon}>
          {iconComponent ?? <DescriptionIcon />}
        </div>
      </Grid>
      <Grid item>
        {messageComponent ? (
          messageComponent
        ) : (
          <Typography variant="h4">{message}</Typography>
        )}
      </Grid>
    </Grid>
  );
};
