import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from '../styles';

export const ArtifactoryGitHubWorkflowAccessHeader = () => {
  const classes = useStyles();
  return (
    <Typography variant="h4" className={classes.labelHeader}>
      GitHub Workflow Access
    </Typography>
  );
};
