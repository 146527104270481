/* eslint-disable dot-notation */
import { useState } from 'react';
import { useApi, microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { projectApiRef } from '@internal/plugin-projects';
import { createGlobalState, useAsyncFn, useEffectOnce } from 'react-use';
import {
  ADMIN,
  GROUP_CREATOR,
  GROUP_ROLE_MANAGER,
  GROUP_ROLE_MEMBER,
  RESOURCE_CREATOR,
  RESOURCE_ROLE_MANAGER,
  RO_ADMIN,
} from 'usg-types';
import { getRoles } from '@internal/sg-utils-common';

interface ResourceCreator {
  devtool_id: number;
  project_id: number;
}
export interface Permissions {
  role: string;
  member: { groups: any[]; projects: any[] };
  group_manager: any[]; // array of group ids
  resource_manager: any[]; // array of resource ids
  group_creator: any[]; // array of project ids
  resource_creator: ResourceCreator[]; // array of devtool ids and project ids
  owner: { projects: any[]; groups: any[]; resources: any[] };
  is_guest: boolean;
  email: string;
  email_domain: string;
}
const defaultProjectRoles = () => {
  return {
    role: '',
    member: { groups: [], projects: [] },
    group_manager: [],
    resource_creator: [],
    group_creator: [],
    resource_manager: [],
    owner: { projects: [], groups: [], resources: [] },
    is_guest: false,
    email: '',
    email_domain: '',
  };
};
let project_roles: Permissions = defaultProjectRoles();
const useGlobalPermissions = createGlobalState<Permissions>(project_roles);
const useGlobalRoles = createGlobalState<any[]>([]);
const useGlobalIsSetPermissions = createGlobalState<boolean>(false);

// TODO: Get all roles from permission hook(Resource Manager, Group Manager, Resource Creator, Group Creator)...

export function usePermissions() {
  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);

  const [permissions, setPermissions] = useGlobalPermissions();
  const [roles, setRoles] = useGlobalRoles();
  const [isSetPermissions, setIsSetPermissions] = useGlobalIsSetPermissions();
  const [fetchDataErrorMsg, setFetchDataErrorMsg] = useState<Error>();

  // use this to create the permissions object from the userdetails api response data
  const getProjectRoles = (userdetails: any) => {
    project_roles = defaultProjectRoles();

    project_roles.role = userdetails.role;
    project_roles.is_guest = userdetails.is_guest || false;
    project_roles.email = userdetails.email || '';
    project_roles.email_domain = userdetails.email.split('@')[1] || '';
    // projects is not required in the response
    userdetails.projects?.map((project: any) => {
      // get projects you own
      if (project.is_owner) project_roles.owner.projects.push(project.id);
      // get projects you can create groups for
      if (project.project_roles?.includes(GROUP_CREATOR))
        project_roles[GROUP_CREATOR].push(project.id);
      // get projects and devtools you can create resources for
      if (project.development_tools?.length > 0) {
        project.development_tools?.map((devtool: any) => {
          if (devtool.dev_tool_roles?.includes(RESOURCE_CREATOR))
            project_roles[RESOURCE_CREATOR].push({
              devtool_id: devtool.id,
              project_id: project.id,
            });
        });
      }
      // get groups you manage and member of
      if (project.user_groups?.length > 0) {
        project.user_groups.map((group: any) => {
          if (group.group_roles?.includes(GROUP_ROLE_MANAGER))
            project_roles[GROUP_ROLE_MANAGER].push(group.id);

          if (group.group_roles?.includes(GROUP_ROLE_MEMBER))
            project_roles.member.groups.push(group.id);
        });

        project_roles.member.projects.push(project.id);
      }
      // get resources you manage
      if (project.resources?.length > 0) {
        project.resources.map((resource: any) => {
          if (resource.resource_roles?.includes(RESOURCE_ROLE_MANAGER))
            project_roles[RESOURCE_ROLE_MANAGER].push(resource.id);
        });

        project_roles.member.projects.push(project.id);
      }
    });

    return project_roles;
  };

  const [{ loading: fetchRolesLoading, error: fetchRolesError }, fetchRoles] =
    useAsyncFn(async () => {
      let res: any;
      try {
        const idToken = await authref.getIdToken();
        res = await projectApi.getUserRoles(idToken);
      } catch (e) {
        throw new Error(`Error getting user roles. ${e?.message}`);
      }
      return getRoles(res?.data?.roles);
    }, []);

  const [
    { loading: fetchPermissionsLoading, error: fetchPermissionsError },
    fetchPermissions,
  ] = useAsyncFn(async () => {
    const idToken = await authref.getIdToken();
    const queryParams = {
      include: 'all',
    };
    const params = { idToken: idToken };
    let res: any;
    try {
      res = await projectApi.getUserDetails(params, queryParams);
    } catch (e) {
      throw new Error(`Error getting user permissions. ${e?.message}`);
    }
    const project_roles_res = getProjectRoles(res?.response?.data);
    return project_roles_res;
  }, []);

  /* helpers */
  const isAdmin = roles?.includes(ADMIN);
  const isROAdmin = roles?.includes(RO_ADMIN);

  const isGroupCreator = (project_id: number) =>
    permissions?.hasOwnProperty(GROUP_CREATOR) &&
    permissions[GROUP_CREATOR].includes(project_id);

  const isProjectOwner = (project_id: number) => {
    return permissions?.owner?.projects.includes(project_id);
  };

  const isResourceCreator = (project_id: number, dev_tool_id: number) =>
    permissions[RESOURCE_CREATOR].filter(
      (resource: any) =>
        resource.project_id === project_id &&
        resource.devtool_id === dev_tool_id,
    ).length > 0;

  const isResourceManager = (resource_id: number) =>
    permissions?.hasOwnProperty(RESOURCE_ROLE_MANAGER) &&
    permissions[RESOURCE_ROLE_MANAGER].includes(resource_id);

  const isGroupManager = (group_id: number) =>
    permissions?.hasOwnProperty(GROUP_ROLE_MANAGER) &&
    permissions[GROUP_ROLE_MANAGER].includes(group_id);

  const isGroupMember = (group_id: number) =>
    permissions.member.groups.includes(group_id);

  const isProjectMember = (project_id: number) =>
    permissions.member.projects.includes(project_id);

  // check if the user has a role
  const hasRole = (role: string, id: number, devtool_id?: number) => {
    switch (role) {
      case GROUP_ROLE_MANAGER:
        return isGroupManager(id);
      case RESOURCE_CREATOR:
        return devtool_id !== undefined && isResourceCreator(id, devtool_id);
      case GROUP_ROLE_MEMBER:
        return isGroupMember(id);
      case RESOURCE_ROLE_MANAGER:
        return isResourceManager(id);
      default:
        return isProjectOwner(id);
    }
  };

  const fetchData = async () => {
    try {
      const getRolesRes = await fetchRoles();
      const getPermissionsRes = await fetchPermissions();
      if (getRolesRes instanceof Error || getPermissionsRes instanceof Error) {
        throw new Error(
          'Error getting user permissions. Please try again and refresh the page.',
        );
      }
      setRoles(getRolesRes);
      setPermissions(getPermissionsRes);
    } catch (e) {
      setFetchDataErrorMsg(e);
    } finally {
      setIsSetPermissions(true);
    }
  };

  useEffectOnce(() => {
    if (!isSetPermissions) {
      (async () => {
        await fetchData();
      })();
    }
  });

  const refresh = async () => {
    await fetchData();
  };

  return {
    roles,
    permissions,
    isLoading:
      fetchRolesLoading || fetchPermissionsLoading || !isSetPermissions, // #1691 - added !isSetPermissions as the initial loading state is false. why?
    isAdmin,
    isROAdmin,
    isAnyAdmin: isAdmin || isROAdmin,
    isGuest: permissions?.is_guest,
    isGroupCreator,
    isResourceCreator,
    isGroupManager,
    isProjectOwner,
    isGroupMember,
    isResourceManager,
    isProjectMember,
    hasRole,
    error: fetchDataErrorMsg || fetchRolesError || fetchPermissionsError,
    refresh,
  };
}
