import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'projects',
});
export const projectPageRouteRef = createRouteRef({
  id: 'projects-pages',
  params: ['projectId'],
});
export const projectGroupRouteRef = createRouteRef({
  id: 'projects-group',
  params: ['projectId', 'usergroup', 'groupId'],
});
export const projectGroupSharingRouteRef = createRouteRef({
  id: 'projects-group-sharing',
  params: ['projectId', 'usergroup', 'groupId', 'sharingsettings'],
});
export const projectResourceRouteRef = createRouteRef({
  id: 'projects-resource',
  params: ['projectId', 'resourceId'],
});
export const pendingRequestPageRouteRef = createRouteRef({
  id: 'pending-user-membership-requests',
});
export const profilePageRouteRef = createRouteRef({
  id: 'profile',
});
export const projectContractPageRouteRef = createRouteRef({
  id: 'project-contracts',
  params: ['projectId'],
});
export const assignUpdateContractPageRouteRef = createRouteRef({
  id: 'assign-update-project-contracts',
  params: ['projectId', 'contractId'],
});
