import { useEffect, useState } from 'react';

import { useApi } from '@backstage/core-plugin-api';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import { useAsyncFn } from 'react-use';
import { VAULT_NEW_UI } from 'usg-types';

const useFeatureFlag = () => {
  const [isNewVaultUiEnabled, setIsNewVaultUiEnabled] = useState<
    boolean | undefined
  >(undefined);

  const featureFlagsApi = useApi(featureFlagsApiRef);

  const [{ loading: loadingFeatureFlags = true as boolean }, getFeatureFlag] =
    useAsyncFn(async () => {
      const isEnabled = await featureFlagsApi.getBinaryFlag(VAULT_NEW_UI);
      setIsNewVaultUiEnabled(isEnabled.data);
    }, [featureFlagsApi]);

  useEffect(() => {
    getFeatureFlag();
  }, [getFeatureFlag]);

  return { isNewVaultUiEnabled, loadingFeatureFlags };
};

export default useFeatureFlag;
