import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import React from 'react';
import { useStyles } from '../../styles';
import { DialogSuccessfulUnarchivedProps } from '../types';

export const DialogSuccessfulUnarchived = ({
  unArchiveSuccessDialog,
  setUnArchiveSuccessDialog,
  navigate,
  projectId,
}: DialogSuccessfulUnarchivedProps) => {
  const classes = useStyles();

  const handleUnArchiveSuccessClose = () => {
    setUnArchiveSuccessDialog(false);
    navigate(`/projects/${projectId}`);
  };

  return (
    <Dialog
      open={unArchiveSuccessDialog}
      onClose={handleUnArchiveSuccessClose}
      maxWidth="xs"
      style={{ top: '5' }}
      className={classes.dialog}
    >
      <DialogContent style={{ fontWeight: 'bold' }}>
        Resource has been successfully unarchived. It might take a few minutes
        to reflect in the project details page.
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          variant="contained"
          size="small"
          onClick={handleUnArchiveSuccessClose}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
