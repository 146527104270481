import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import React from 'react';
import { projectApiRef } from '../../../../api';
import { useStyles } from '../../styles';
import { DialogUnarchiveProps } from '../types';

export const DialogUnarchive = ({
  projectId,
  resourceId,
  idTokenData,
  unArchiveOpen,
  setUnArchiveSuccessDialog,
  setUnArchiveOpen,
}: DialogUnarchiveProps) => {
  const classes = useStyles();
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);

  const handleUnArchiveDialog = async () => {
    const data: any = await projectApi.unArchiveResources(
      projectId,
      Number(resourceId),
      {
        idToken: idTokenData,
      },
    );
    const code = data?.response?.status;
    if (code === 200) {
      setUnArchiveSuccessDialog(true);
    } else if (code === 401 || code === 404) {
      const errorMsg = data?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
    }
  };

  return (
    <Dialog
      open={unArchiveOpen}
      onClose={() => setUnArchiveOpen(false)}
      maxWidth="xs"
      style={{ top: '5' }}
      className={classes.dialog}
    >
      <DialogContent style={{ fontWeight: 'bold' }}>
        Are you sure you want to unarchive this resource?
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setUnArchiveOpen(false)}
        >
          cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={handleUnArchiveDialog}
        >
          UnArchive
        </Button>
      </DialogActions>
    </Dialog>
  );
};
