import React from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';

export const LastViewedDocuments = () => {
  const classes = useStyles();

  const documents = JSON.parse(
    localStorage.getItem('techdocRecentlyViewed') || '[]',
  );

  if (documents.length === 0) {
    return null;
  }

  return (
    <Grid container item spacing={4}>
      <Grid item sm={12}>
        <Typography variant="h4">Recently Viewed</Typography>
      </Grid>
      {documents.map((doc: any) => (
        <Grid item key={doc.header} sm={6} md={3}>
          <Card variant="outlined" className={classes.card}>
            <CardContent className={classes.content}>
              <Link to={`${window.location.origin}${doc.pathname}`}>
                <div className={classes.titleSection}>
                  <Typography
                    variant="h4"
                    className={classes.titleLink}
                    color="primary"
                  >
                    {doc.header?.slice(-1) === '¶'
                      ? doc.header?.slice(0, -1)
                      : doc.header}
                  </Typography>

                  <KeyboardArrowRightIcon />
                </div>
              </Link>

              {doc.content?.length > 80
                ? `${doc.content?.slice(0, 80)}...`
                : doc.content}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
