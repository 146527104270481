import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  identityApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';

import { Entity } from '@backstage/catalog-model';

import { rootRouteRef } from './routes';
import { PvmApi, pvmApiRef } from './api';
import { PROJECT_DEFECTS_ANNOTATION, PVM_ANNOTATION } from './annotations';

export const pvmPlugin = createPlugin({
  id: 'pvm',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: pvmApiRef,

      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
        gacApi: microsoftAuthApiRef,
      },
      factory: ({ configApi, identityApi, gacApi }) =>
        new PvmApi({ configApi, identityApi, gacApi }),
    }),
  ],
});

export const isProjectDefectsAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[PROJECT_DEFECTS_ANNOTATION]);

export const isSecurityTabAvailable = (entity: Entity): boolean =>
  Boolean(entity.metadata.annotations?.[PVM_ANNOTATION]);

export const EntitySecurityContent = pvmPlugin.provide(
  createRoutableExtension({
    name: 'EntitySecurityContent',
    component: () =>
      import('./pages/EntitySecurityContent').then(
        m => m.EntitySecurityContent,
      ),
    mountPoint: rootRouteRef,
  }),
);
