import { generateHeaders } from './common-utils';

export async function submitUserData(url: string, token: any, userData: any) {
  const headers = generateHeaders({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: Object.fromEntries(headers),
    body: JSON.stringify(userData),
  });
  const data = await response.json();

  if (data && data.error) {
    throw new Error(data.error.message);
  }

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return data;
}

export async function submitNPSData(url: string, token: any, npsData: any) {
  const headers = generateHeaders({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: Object.fromEntries(headers),
    body: JSON.stringify(npsData),
  });
  const data = await response.json();

  if (data && data.error) {
    throw new Error(data.error.message);
  }

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return data;
}

export async function getNPSData(url: string, token: any) {
  const headers = generateHeaders({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: Object.fromEntries(headers),
  });

  const data = await response.json();

  if (!response.ok) {
    return { data: [] };
  }

  return data;
}
