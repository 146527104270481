import React from 'react';

import { Button, FormHelperText } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { useStyles } from '../../styles';
import { environmentTypes } from './types';

type env = {
  id: string;
  environment: string;
  url: string;
};

type specificData = {
  environmentsData: env[];
  resourceID: string;
  setResourceID: (value: string) => void;
  copyButtonLabel: string;
  setCopyButtonLabel: (value: string) => void;
  projectId: string;
};

type resData = {
  key: string;
  name: string;
  linkedResources: any[];
};

type OldVaultViewEditProps = {
  eventType: string;
  resourceData: resData;
  specificProps: specificData;
};

const OldVaultViewEdit = (props: OldVaultViewEditProps) => {
  const classes = useStyles();

  const { eventType, resourceData, specificProps } = props;
  const {
    environmentsData,
    resourceID,
    setResourceID,
    copyButtonLabel,
    setCopyButtonLabel,
    projectId,
  } = specificProps;

  const viewCase = (
    <>
      <h4 className={classes.labelHeader}>Vault Namespace Key</h4>
      <p id="resource-key-value" className={classes.viewTag}>
        {resourceData?.key}
      </p>
      <h4 className={classes.labelHeader}>Vault Namespace Name</h4>
      <p id="resource-name-value" className={classes.viewTag}>
        {resourceData?.name}
      </p>
      <h4 className={classes.labelHeader}>Environments</h4>
      {environmentTypes.map((env: any) => (
        <div key={env.id}>
          <div
            id={`resource-environment-header-${env?.value}`}
            className={classes.enviText}
          >
            {env?.name}
          </div>
          {environmentsData?.map((tag: any) =>
            tag.environment === env?.value ? (
              <div
                key={tag.id}
                style={{
                  padding: '0px 0px 40px',
                  display: 'flex',
                  alignItems: 'end',
                }}
              >
                <a
                  id={`resource-environment-link-${env?.value}`}
                  href={`${tag?.url}`}
                  target="_blank"
                  style={{
                    fontWeight: 'bold',
                    textDecorationLine: 'underline',
                    color: '#307FFD',
                    marginBottom: '28px',
                  }}
                >
                  <span style={{ display: 'flex' }}>
                    {tag?.url}
                    <OpenInNewIcon
                      fontSize="small"
                      style={{ marginLeft: '8px' }}
                    />
                  </span>
                </a>
                <div>
                  <Button
                    variant="outlined"
                    style={{ marginLeft: '24px' }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${window.location.protocol}//${window.location.hostname}/projects/${projectId}?resource=${tag.id}&path=`,
                      );
                      setCopyButtonLabel('COPIED');
                      setResourceID(tag.id);
                      setTimeout(() => {
                        setCopyButtonLabel('Shareable URL');
                        setResourceID('');
                      }, 2000);
                    }}
                    disabled={resourceID === tag.id}
                  >
                    {resourceID === tag.id ? copyButtonLabel : 'Shareable URL'}{' '}
                    <FileCopyIcon
                      style={{ marginLeft: '8px' }}
                      fontSize="small"
                    />
                  </Button>
                  <FormHelperText
                    style={{
                      marginLeft: '24px',
                      padding: '0 24px',
                    }}
                  >
                    Click to copy resource URL.
                  </FormHelperText>
                </div>
              </div>
            ) : (
              ''
            ),
          )}
          {resourceData.linkedResources
            .filter((x: any) => x.environment === env?.value)
            .map((s: any) => s.environment)[0] === env?.value ? (
            ''
          ) : (
            <p className={classes.viewTag}>Not Available</p>
          )}
        </div>
      ))}
    </>
  );

  const editCase = <></>;

  switch (eventType) {
    case 'edit':
      return editCase;
    default:
      return viewCase;
  }
};

export default OldVaultViewEdit;
