import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LaunchIcon from '@material-ui/icons/Launch';
import {
  CodeSnippet,
  Progress,
  ItemCardGrid,
  ItemCardHeader,
  LinkButton,
} from '@backstage/core-components';
import {
  Card,
  CardMedia,
  CardActions,
  Typography,
  Link,
} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { mtfujiApiRef } from '../../fetcher';
import { ApiError } from 'openapi-typescript-fetch';
import { definitions } from '../../api';
import { GITHUBEMU_DEV_TOOL_ID } from 'usg-types';

type ApplicationResponse = definitions['handlers.ApplicationResponse'];

const refreshInterval = 15000; // milliseconds

type Template = {
  language: string;
  name: string;
};

const templates: Template[] = [
  {
    language: 'Go',
    name: 'mtfuji-template-webapp-golang',
  },
  {
    language: 'Python',
    name: 'mtfuji-template-webapp-python',
  },
  {
    language: 'NodeJS',
    name: 'mtfuji-template-webapp-nodejs',
  },
  {
    language: 'Mkdocs',
    name: 'mtfuji-template-webapp-mkdocs',
  },
  {
    language: 'No template',
    name: '',
  },
];

export type PendingProps = {
  projectId: number;
  applicationName: string;
  applicationResponse: ApplicationResponse;
  applicationResponseError: Error | undefined;
  maybeNewGithub?: boolean | false;
  onFinishedPending?: (appResp: ApplicationResponse) => void;
};

export const Pending = (props: PendingProps) => {
  const {
    projectId,
    applicationName,
    applicationResponse,
    applicationResponseError,
    maybeNewGithub,
    onFinishedPending = () => {},
  } = props;
  const mtfujiApi = useApi(mtfujiApiRef);

  const [checking, setChecking] = useState<boolean>(true);
  const [long, setLong] = useState<boolean>(false);
  const [found, setFound] = useState<boolean>(false);
  const [pollingError, setPollingError] = useState<Error>();

  const templatesAvailable = () => {
    return (
      applicationResponse.github_repositories &&
      applicationResponse.github_repositories.some(
        e => e.dev_tool_id === GITHUBEMU_DEV_TOOL_ID,
      )
    );
  };

  const getNewUrl = (
    owner: string,
    name: string,
    template_name: string,
    template_owner: string = 'sg-innersource',
  ) => {
    const baseURL = applicationResponse.github_repositories
      ? `https://${applicationResponse.github_repositories[0].host}`
      : 'https://github.com';

    const url = new URL('/enterprises/stargate/sso', baseURL);

    const returnURL = new URL('/new', baseURL);
    returnURL.searchParams.set('owner', owner);
    returnURL.searchParams.set('name', name);

    if (templatesAvailable()) {
      returnURL.searchParams.set('template_owner', template_owner);
      returnURL.searchParams.set('template_name', template_name);
      returnURL.searchParams.set(
        'description',
        `Created using ${template_owner}/${template_name}`,
      );
    }
    if (baseURL === 'https://github.com') {
      url.searchParams.set('return_to', returnURL.toString());
      return url.toString();
    }
    return returnURL.toString();
  };

  let progressEl = <></>;
  if (checking) {
    progressEl = <Progress />;
  }

  const pendingEl = (
    <Alert severity="info" key="pendingEl">
      Your application namespaces are being created. This usually takes between
      5 and 7 minutes.
    </Alert>
  );

  const slackChannelUrl =
    'https://toyotaglobal.enterprise.slack.com/archives/C02JB3YLR1U';
  const longPendingEl = (
    <Alert severity="warning" key="longPendingEl" style={{ marginTop: 10 }}>
      Creation of your application namespaces seems still pending.
      Unfortunately, this takes unusually long.
      <br />
      <br />
      Please reach out to the Stargate Multicloud team in Slack and we're happy
      to help!
      <br />
      <br />
      <LinkButton color="secondary" variant="contained" to={slackChannelUrl}>
        Get help on Slack
      </LinkButton>
    </Alert>
  );

  const errorEl = (
    <Alert severity="error" key="errorEl" style={{ marginTop: 10 }}>
      Unfortunately, creating your application namespaces has failed.
      <CodeSnippet
        text={JSON.stringify(applicationResponseError, null, 2)}
        language="json"
      />
      Please provide the above error to the Stargate Multicloud team in Slack
      and we will help!
      <br />
      <br />
      <LinkButton color="secondary" variant="contained" to={slackChannelUrl}>
        Get help on Slack
      </LinkButton>
    </Alert>
  );

  const pollingErrorEl = (
    <Alert severity="error" key="pollingErrorEl" style={{ marginTop: 10 }}>
      Unfortunately, something went wrong while waiting for your namespaces.
      <CodeSnippet
        text={JSON.stringify(pollingError, null, 2)}
        language="json"
      />
      Please provide the above error to the Stargate Multicloud team in Slack
      and we will help!
      <br />
      <br />
      <LinkButton color="secondary" variant="contained" to={slackChannelUrl}>
        Get help on Slack
      </LinkButton>
    </Alert>
  );

  const successEl = (
    <Alert severity="success" key="successEl" style={{ marginTop: 10 }}>
      <AlertTitle>
        Your application namespaces were created successfully.
      </AlertTitle>
      <p>
        To access the created namespaces you will need the Pinniped CLI to be
        able to authenticate. Stargate uses Toyota Global Access Center (GAC) as
        the identity provider. This requires the{' '}
        <Link href="https://pinniped.dev/docs/howto/install-cli/">
          Pinniped CLI
        </Link>{' '}
        to be installed on your machine. The KUBECONFIGs provided by Stargate
        Multicloud are pre-configured to authenticate via GAC using the Pinniped
        CLI. You can install the prerequisite binary using:
      </p>
      <p>
        <b>Homebrew</b>:
        <code className="snippet">
          brew install vmware-tanzu/pinniped/pinniped-cli
        </code>
      </p>
      <p>
        <b>Manual</b>:
        <code className="snippet">
          curl -Lso pinniped
          https://get.pinniped.dev/latest/pinniped-cli-linux-amd64 \ <br />
          && chmod +x pinniped \ <br />
          && sudo mv pinniped /usr/local/bin/pinniped
        </code>
      </p>
      <p>
        Once you have the Pinniped CLI installed, visit the overview page and{' '}
        <u>download the updated Kubeconfig</u>.
        <br />
      </p>
    </Alert>
  );

  let templatesEl = <></>;
  if (templatesAvailable()) {
    templatesEl = (
      <Alert
        severity="warning"
        icon={
          maybeNewGithub ? (
            <AssignmentLateIcon fontSize="inherit" />
          ) : (
            <HelpOutlineIcon fontSize="inherit" />
          )
        }
      >
        <AlertTitle>
          {maybeNewGithub
            ? 'Create your GitHub Repository'
            : "Don't have your GitHub Repository yet?"}
        </AlertTitle>
        <Typography variant="body2">
          If you haven't created your repository yet, now is a good time to do
          that.
          <br />
          You can use one of the Stargate Multicloud application templates or
          choose the no template option below to get you started.
        </Typography>
        <br />
        <ItemCardGrid>
          {templates.map(tpl => (
            <Card key={tpl.name}>
              <CardMedia>
                <ItemCardHeader title={tpl.name} subtitle={tpl.language} />
              </CardMedia>
              <CardActions>
                <LinkButton
                  color="primary"
                  to={getNewUrl(
                    applicationResponse.github_repositories
                      ? applicationResponse.github_repositories[0].owner
                      : '',
                    applicationResponse.github_repositories
                      ? applicationResponse.github_repositories[0].name
                      : '',
                    tpl.name,
                  )}
                  fullWidth
                  target="_blank"
                >
                  {tpl.name !== '' ? (
                    <>
                      <span>Use this template &nbsp;</span>
                      <LaunchIcon fontSize="small" />
                    </>
                  ) : (
                    <>
                      <span>Create an empty repository &nbsp;</span>
                      <LaunchIcon fontSize="small" />
                    </>
                  )}
                </LinkButton>
              </CardActions>
            </Card>
          ))}
        </ItemCardGrid>
      </Alert>
    );
  }

  useEffect(
    () => {
      if (typeof applicationResponseError !== 'undefined') {
        // don't start querying if create request failed
        setChecking(false);
        return;
      }

      let count = 0;
      const pollID: any = setInterval(() => {
        async function check() {
          count++;

          try {
            const entry: ApplicationResponse = await mtfujiApi.getApplication(
              projectId,
              applicationName,
            );
            if (entry.status === 'applied') {
              clearInterval(pollID);
              setChecking(false);
              setFound(true);
              onFinishedPending(entry);

              return;
            }
          } catch (error) {
            if (
              error instanceof ApiError &&
              (error.status === 404 || error.status === 500)
            ) {
              // if the application or pr hasn't been created yet it should return 404
              // which throws an error so don't do anything here
              // TODO: remove the check for 500 once the SMC api has been fixed to not return 500 while the pr is not merged
            } else {
              setPollingError(error);
              clearInterval(pollID);
              setChecking(false);
              return;
            }
          }

          // display warning if we've been checking for more than 10 minutes
          if (count > 600000 / refreshInterval) {
            setLong(true);
          }

          // give up checking after 15 minutes
          if (count > 900000 / refreshInterval) {
            clearInterval(pollID);
            setChecking(false);
          }
        }

        check();
        return () => clearInterval(pollID);
      }, refreshInterval);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  let notificationEls = [pendingEl];
  if (typeof applicationResponseError !== 'undefined') {
    notificationEls = [errorEl];
  }

  if (long) {
    notificationEls = [longPendingEl, pendingEl];
  }

  if (found) {
    notificationEls = [successEl];
  }

  if (pollingError) {
    notificationEls = [pollingErrorEl];
  }

  return (
    <div id="mtfuji_wizard_pending">
      <div style={{ marginTop: 25, marginBottom: 15 }}>
        {progressEl}
        {notificationEls}
      </div>
      {templatesEl}
    </div>
  );
};
