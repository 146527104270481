import React, { useState } from 'react';
import { EmptyDataMessage } from '@internal/sg-ui-kit';
import { Table, TableColumn } from '@backstage/core-components';
import { formatDate } from 'sg-utils-frontend';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TablePagination,
  Tooltip,
} from '@material-ui/core';
import { InputAdornment, TextField, MenuItem } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { debounce } from 'lodash';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';

const PAGE_SIZE_OPTIONS = [20, 50, 100, 500];

interface Props {
  data?: [];
  setSelectedListItems?: any;
  tableState: any;
  setTableState: any;
  nextPage: any;
  setUserEmail: any;
  setSourceApplication: any;
  setAction: any;
  setApproveStatus: any;
  userEmail: any;
  sourceApplication: any;
  action: any;
  approveStatus: any;
  setSentStatusFilter: any;
  sentStatusFilter: any;
  setNorenStatus: any;
  norenStatus: any;
  setSentStatus: any;
  errorIds: any;
}
export const PendingRequestTable: React.FC<Props> = ({
  data = [],
  setSelectedListItems = [],
  tableState,
  errorIds,
  setSentStatus,
  setTableState,
  nextPage,
  setUserEmail,
  setSourceApplication,
  setNorenStatus,
  norenStatus,
  setAction,
  setSentStatusFilter,
  sentStatusFilter,
  setApproveStatus,
  approveStatus,
  userEmail,
  sourceApplication,
  action,
}) => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const [requestDetails, setRequestDetails] = useState({} as any);

  // debounce function for search by email variable wait for 800 ms
  const handleSearch = debounce((value: string) => {
    setUserEmail(value);
  }, 800);

  // debounce function for search by source app variable wait for 800 ms
  const handleSourceApplicationSearch = debounce((value: string) => {
    setSourceApplication(value);
  }, 800);

  // Action options
  const actionData = [
    {
      value: 'ADD',
      label: 'Add',
    },
    {
      value: 'REMOVE',
      label: 'Remove',
    },
  ];

  // Approve status options
  const approveStatusData = [
    {
      value: 'APPROVED',
      label: 'Approved',
    },
    {
      value: 'PENDING',
      label: 'Pending',
    },
    {
      value: 'REJECTED',
      label: 'Rejected',
    },
  ];

  // Sent status options
  const sentStatusData = [
    {
      value: 'CANCELLED',
      label: 'Cancelled',
    },
    {
      value: 'FAILED',
      label: 'Failed',
    },
    {
      value: 'HOLD',
      label: 'Hold',
    },
    {
      value: 'NONE',
      label: 'None',
    },
    {
      value: 'RETRY_EXCEED',
      label: 'Retry Exceed',
    },
    {
      value: 'SUCCESS',
      label: 'Success',
    },
  ];

  // Noren Status options
  const norenStatusData = [
    {
      value: 'completed',
      label: 'Completed',
    },
    {
      value: 'error',
      label: 'Error',
    },
    {
      value: 'failed to start',
      label: 'Failed to start',
    },
    {
      value: 'in_progress',
      label: 'In Progress',
    },
    {
      value: 'requested',
      label: 'Requested',
    },
    {
      value: 'started',
      label: 'Started',
    },
    {
      value: 'expired',
      label: 'Expired',
    },
  ];

  // list of all fields columns
  const feedColumns: Array<TableColumn<any>> = [
    {
      field: 'id',
      title: 'Id',
      width: '28%',
      cellStyle: { fontWeight: 700 },
      filtering: false,
    },
    {
      field: 'user_group_name',
      title: 'Group Name',
      width: '38%',
      filtering: false,
    },
    {
      field: 'project_id',
      title: 'Project ID',
      width: '38%',
      filtering: false,
    },
    {
      field: 'user_email',
      title: 'User Email',
      width: '54%',
      filterComponent: () => (
        <TextField
          variant="standard"
          defaultValue={userEmail}
          className={classes.textField}
          onChange={e => handleSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FilterListIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow,
                  }}
                  arrow
                  placement="top"
                  title="Clear Filter"
                >
                  <IconButton
                    onClick={() => {
                      setUserEmail('');
                    }}
                    edge="end"
                    title="Close"
                    className={classes.closeBtn}
                  >
                    {userEmail ? <CloseIcon /> : ''}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      ),
      render: item => (
        <Link
          to={`/projects/${item.project_id}/users/${item.user_email}`}
          className={classes.hyperlink}
          target="_new"
        >
          {item.user_email}
        </Link>
      ),
    },
    {
      field: 'source_application',
      title: 'Source Application',
      width: '54%',
      filterComponent: () => (
        <TextField
          variant="standard"
          defaultValue={sourceApplication}
          className={classes.textField}
          onChange={e => handleSourceApplicationSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FilterListIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow,
                  }}
                  arrow
                  placement="top"
                  title="Clear Filter"
                >
                  <IconButton
                    onClick={() => {
                      setSourceApplication('');
                    }}
                    edge="end"
                    title="Close"
                    className={classes.closeBtn}
                  >
                    {userEmail ? <CloseIcon /> : ''}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      ),
    },
    {
      field: 'action',
      title: 'Type',
      width: '47%',
      filterComponent: () => (
        <TextField
          variant="standard"
          id="standard-select-currency"
          className={classes.textField}
          select
          defaultValue={action}
          onChange={e => {
            setAction(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FilterListIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow,
                  }}
                  arrow
                  placement="top"
                  title="Clear Filter"
                >
                  <IconButton
                    onClick={() => {
                      setAction('');
                    }}
                    edge="end"
                    className={classes.closeBtn}
                  >
                    {action ? <CloseIcon /> : ''}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        >
          {actionData.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
              className={classes.menuList}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      ),
    },
    {
      field: 'status',
      title: 'Approve Status',
      width: '47%',
      filterComponent: () => (
        <TextField
          variant="standard"
          id="standard-select-currency"
          select
          defaultValue={approveStatus}
          className={classes.textField}
          onChange={e => {
            setApproveStatus(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FilterListIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow,
                  }}
                  arrow
                  placement="top"
                  title="Clear Filter"
                >
                  <IconButton
                    onClick={() => {
                      setApproveStatus('');
                    }}
                    edge="end"
                    className={classes.closeBtn}
                  >
                    {approveStatus ? <CloseIcon /> : ''}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        >
          {approveStatusData.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
              className={classes.menuList}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      ),
    },
    {
      field: 'sent',
      title: 'Sent Status',
      width: '47%',
      filterComponent: () => (
        <TextField
          variant="standard"
          id="standard-select-currency"
          select
          defaultValue={sentStatusFilter}
          className={classes.textField}
          onChange={e => {
            setSentStatusFilter(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FilterListIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow,
                  }}
                  arrow
                  placement="top"
                  title="Clear Filter"
                >
                  <IconButton
                    onClick={() => {
                      setSentStatusFilter('');
                    }}
                    edge="end"
                    className={classes.closeBtn}
                  >
                    {sentStatusFilter ? <CloseIcon /> : ''}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        >
          {sentStatusData.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
              className={classes.menuList}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      ),
    },
    {
      field: 'noren_progress_status',
      title: 'Progress Status',
      filterComponent: () => (
        <TextField
          variant="standard"
          id="standard-select-currency"
          select
          defaultValue={norenStatus}
          className={classes.textField}
          onChange={e => {
            setNorenStatus(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FilterListIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow,
                  }}
                  arrow
                  placement="top"
                  title="Clear Filter"
                >
                  <IconButton
                    onClick={() => {
                      setNorenStatus('');
                    }}
                    edge="end"
                    className={classes.closeBtn}
                  >
                    {norenStatus ? <CloseIcon /> : ''}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        >
          {norenStatusData.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
              className={classes.menuList}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      ),
    },
    {
      title: 'Requested On',
      filtering: false,
      render: item => formatDate(item.requested_on, false),
    },
    {
      title: 'Additional Details',
      filtering: false,
      render: item => (
        <Tooltip
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.tooltipArrow,
          }}
          arrow
          placement="top"
          title="Show Additional Details"
        >
          <IconButton
            onClick={() => {
              setRequestDetails(item);
              setIsVisible(true);
            }}
            edge="end"
            color="primary"
          >
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      ),
    },
  ];
  const handleSelectListItems = (item: any) => {
    setSelectedListItems(item);
  };
  const handleRowChange = (e: any) => {
    const pageSize = e.target.value;
    const page = 1;
    setTableState({ ...tableState, pageSize, page });
  };
  const handlePageChange = (_: any, newPage: number) => {
    const page = newPage + 1;
    setTableState({ ...tableState, page });
  };
  const handleRefresh = () => {
    setSentStatus('SUCCESS');
  };
  const count = nextPage ? nextPage * tableState.pageSize : 0;

  return (
    <div className={classes.heading}>
      <Table
        columns={feedColumns}
        options={{
          paging: true,
          search: false,
          sorting: false,
          toolbar: true,
          draggable: false,
          selection: true,
          pageSize: tableState.pageSize,
          showFirstLastPageButtons: false,
          emptyRowsWhenPaging: false,
          tableLayout: 'fixed',
          rowStyle: rowData => ({
            backgroundColor: errorIds.find(
              (id: any) => id === rowData.tableData.id,
            )
              ? 'rgb(226, 232, 242)'
              : '',
          }),
          filtering: data && data.length >= 0,
        }}
        onSelectionChange={rows => handleSelectListItems(rows)}
        data={data}
        title="Group Membership Requests"
        localization={{
          body: {
            emptyDataSourceMessage: <EmptyDataMessage />,
          },
          pagination: {
            labelDisplayedRows: '',
          },
        }}
        actions={[
          {
            icon: () => <AutorenewIcon />,
            tooltip: 'Refresh',
            onClick: () => handleRefresh(),
            isFreeAction: true,
          },
        ]}
        components={{
          Pagination: props => (
            <TablePagination
              {...props}
              className={classes.pagination}
              rowsPerPageOptions={PAGE_SIZE_OPTIONS}
              count={count}
              page={tableState.page - 1}
              onPageChange={handlePageChange}
              rowsPerPage={tableState.pageSize}
              onRowsPerPageChange={handleRowChange}
              backIconButtonProps={{ disabled: tableState.page === 1 }}
              nextIconButtonProps={{
                disabled: !nextPage,
              }}
            />
          ),
        }}
      />

      <Dialog open={isVisible}>
        <DialogTitle>
          <div className={classes.dialogHeader}>
            Additional Details
            <Tooltip
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow,
              }}
              arrow
              placement="top"
              title="Close Button"
            >
              <IconButton
                aria-label="close"
                className={classes.iconContainer}
                onClick={() => setIsVisible(false)}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </DialogTitle>
        <DialogContent>
          <b>Requested By:</b> {requestDetails?.requested_by}
          <br />
          <b>Request Reason :</b> {requestDetails?.request_reason}
          <br />
          <b>Sent Fail Reason:</b> {requestDetails?.sent_failure_reason}
          <br />
          <b>Noren Progress ID:</b> {requestDetails?.noren_progress_id}
          <br />
          <b>Review Comment:</b> {requestDetails?.review_comment}
          <br />
          <b>Reviewed By:</b> {requestDetails?.reviewed_by}
          <br />
          <b>Sent Attempts:</b> {requestDetails?.sent_attempts}
          <br />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => setIsVisible(false)}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
