import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles<USGTheme>(theme => ({
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  bar: {
    margin: '0 8px',
  },
  titleLink: {
    fontWeight: 'bold',
    color: theme.palette.usgColors.secondary.blue,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  imageContainer: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  caption: {
    fontWeight: 'normal',
  },
  card: {
    height: '100%',
  },
  activeList: {
    marginLeft: 4,
    marginRight: 4,
    color: '#000000',
    fontWeight: 700,
    pointerEvents: 'none',
  },
  inActiveList: {
    marginLeft: 4,
    marginRight: 4,
  },
  list: {
    listStyleType: 'none',
    display: 'flex',
    paddingLeft: '4px',
    fontSize: '16px',
    '& li': {
      cursor: 'pointer',
    },
    alignItems: 'center',
  },
  selector: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '0px',
  },
  backToLink: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  backToLinkText: {
    textDecoration: 'underline',
    color: theme.palette.usgColors.primary.blue,
  },
  toolBarContainer: {
    padding: '0 24px',
  },
  icon: {
    paddingTop: '3px',
  },
}));
