import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'actions',
});

export const catalogItemRouteRef = createRouteRef({
  id: 'catalog-item',
  params: ['catalogKey'],
});

export const marketplacePageRouteRef = createRouteRef({
  id: 'marketplace',
});

export const marketplaceGitHubItemRouteRef = createRouteRef({
  id: 'marketplace-github-item',
  params: ['category', 'catalogKey'],
});

export const marketplaceImageItemRouteRef = createRouteRef({
  id: 'marketplace-iamge-item',
  params: ['category', 'namespace', 'name'],
});
