import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  warningMessageCollapse: {
    color: theme.palette.usgColors.primary.black,
    width: '100%',
  },
  readMore: {
    textTransform: 'none',
    padding: 0,
    color: theme.palette.usgColors.primary.blue50,
    height: 'auto',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
}));
