import { createContext, useContext } from 'react';
import { ViewEditResourceData } from './types';

export const ViewEditResourceContext = createContext<
  ViewEditResourceData | undefined
>(undefined);

export function useViewEditResourceContext() {
  const viewEditResourceContext = useContext(ViewEditResourceContext);

  if (viewEditResourceContext === undefined) {
    throw new Error(
      'useViewEditResourceContext must be used with ViewEditResourceContext',
    );
  }

  return viewEditResourceContext;
}
