import React from 'react';
import { Tabs } from '@internal/sg-ui-kit';

import { CloudUsage, CloudLicenseUsage } from '../../components';
import { useStyles } from './styles';

interface CatalogUsagePageProps {
  projectId?: string;
}

export const CatalogUsagePage = ({ projectId }: CatalogUsagePageProps) => {
  const classes = useStyles();

  const type = new URLSearchParams(window.location.search).get('usageType');

  const tabs = [
    {
      label: 'Cloud',
      value: 'cloud-usage',
      content: <CloudUsage projId={projectId} />,
    },
    {
      label: 'License',
      value: 'cloud-license-usage',
      content: <CloudLicenseUsage projId={projectId} />,
    },
  ];

  return (
    <div className={classes.container}>
      <div>
        <div>
          <Tabs
            tabs={tabs}
            initialActiveTabIndex={type === 'license' ? 1 : 0}
          />
        </div>
      </div>
    </div>
  );
};
