import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  identityApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';

import { onBoardingRouteRef, rootRouteRef } from './routes';
import { RoverApi, roverApiRef } from './api';

export const roverPlugin = createPlugin({
  id: 'rover',
  routes: {
    root: rootRouteRef,
    onBoarding: onBoardingRouteRef,
  },
  apis: [
    createApiFactory({
      api: roverApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
        gacApi: microsoftAuthApiRef,
      },
      factory: ({ configApi, identityApi, gacApi }) =>
        new RoverApi({ configApi, identityApi, gacApi }),
    }),
  ],
});

export const RoverPage = roverPlugin.provide(
  createRoutableExtension({
    name: 'RoverPage',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
