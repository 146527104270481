import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    padding: '40px',
  },
  filters: {
    margin: '24px 0',
  },
  filtername: {
    marginBottom: '5px',
    textTransform: 'none',
  },
  assignButton: {
    marginLeft: 'auto',
  },
  button: {
    padding: '0',
    color: theme.palette.usgColors.secondary.lightBlue,
    fontSize: '12px',
    fontWeight: 600,
    '&:hover, &:focus': {
      backgroundColor: 'rgba(230, 230, 230, 0)',
    },
    textTransform: 'none',
  },
  columnText: {
    display: 'flex',
    fontSize: '16px',
    justifyContent: 'space-between',
  },
  info: {
    width: '80%',
    color: 'black !important',
    '&:nth-of-type(1)': {
      marginBottom: '15px',
    },
  },
  warning: {
    color: '#ee832a',
  },
  link: {
    color: '#0E4295',
    fontSize: '1.3em',
  },
}));
