export type LanguageType = 'en' | 'ja';
export type Language = {
  name: string;
  lang: LanguageType;
  by: string;
};

export const detectLanguage = (
  languages: Language[],
  currentPath: string,
): { lang: string; by: string } => {
  const languageDetected: { lang: string; by: string }[] = [];
  const splitCurrentPath = currentPath.split('/');
  languages.forEach(lang => {
    if (splitCurrentPath.includes(lang.lang)) {
      languageDetected.push(lang);
    }
  });
  return languageDetected?.length !== 1 ? languages[0] : languageDetected[0];
};

export const getNewPath = (
  selectedLanguage: LanguageType,
  supportedLanguages: Language[],
  currentPath: string,
): string => {
  const detectedLanguage = detectLanguage(supportedLanguages, currentPath);
  const selectedLanguageObj = supportedLanguages.filter(
    element => element.lang === selectedLanguage,
  )[0];

  if (selectedLanguage === 'en') {
    const splitBy = detectedLanguage.by;
    const regex = new RegExp(`${splitBy}(.*)`);
    const url = currentPath.split(regex);
    return url.join('');
  }

  let url;
  if (currentPath.includes(detectedLanguage.by)) {
    url = currentPath.split(detectedLanguage.by);
  } else {
    // because the rest of the url path is case sensitive, we cannot compare the lowercased version of both detectedLanguage.by and pathname
    url = currentPath.split(detectedLanguage.by.toLowerCase());
  }
  const path =
    detectedLanguage.lang === 'en'
      ? url[0] + detectedLanguage.by + selectedLanguageObj.by + (url[1] || '')
      : url.join(selectedLanguageObj.by);
  return path;
};
