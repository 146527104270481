import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Link,
  Tooltip,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import SyncTwoToneIcon from '@material-ui/icons/SyncTwoTone';
import { definitions } from '../../api';
import { ApplicationRequestProps } from './types';
import {
  ControlTextField,
  StyledTextField,
  ControlAutocomplete,
  isEmptyOrNil,
} from './FormHelpers';
import { GITHUBEMU_DEV_TOOL_ID, GITHUB_DEV_TOOL_ID } from 'usg-types';
import { CreateGithubOrgDialog } from '../CreateGithubOrgDialog';

// Schemas
export type NamespaceRequestRepository =
  definitions['handlers.NamespaceRequestRepository'];
export type NamespaceAutocompletionResponseProject =
  definitions['handlers.NamespaceAutocompletionResponseProject'];
type ApplicationGitHubRepository = definitions['handlers.GitHubRepository'];

// props schema
export type GithubRepositoryProps = {
  stargateProject: NamespaceAutocompletionResponseProject;
  defaultRepo: ApplicationGitHubRepository;
  autocompletionsLoading: boolean;
  repositoryOptions: ApplicationGitHubRepository[];
  setRepositoryOptions: (repos: ApplicationGitHubRepository[]) => void;
  validationSetter: (isValid: boolean) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    inlineCreateLink: {
      padding: '0',
      fontWeight: 'bolder',

      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);

export const GithubRepository = (props: GithubRepositoryProps) => {
  const {
    stargateProject,
    defaultRepo,
    autocompletionsLoading,
    repositoryOptions,
    setRepositoryOptions,
    validationSetter,
  } = props;
  const classes = useStyles();

  const {
    setValue,
    getValues,
    control,
    getFieldState,
    formState: { isValid },
  } = useFormContext<ApplicationRequestProps>();

  useEffect(() => {
    validationSetter(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const hostOptions = Array.from(
    repositoryOptions.reduce((acc, o) => acc.add(o.host), new Set<string>()),
  );

  const ownerOptions = Array.from(
    repositoryOptions.reduce((acc, o) => acc.add(o.owner), new Set<string>()),
  );

  const [repoHostValue, setRepoHostValue] = useState<string>(defaultRepo.host);
  const [repoOwnerValue, setRepoOwnerValue] = useState<string>(
    ownerOptions.includes(defaultRepo.owner) ? defaultRepo.owner : '',
  );
  const [repoNameValue, setRepoNameValue] = useState<string>(defaultRepo.name);

  const [userGithubOrgDialogOpen, setGithubOrgDialogOpen] =
    useState<boolean>(false);

  const filterOwners = () => {
    return repositoryOptions
      .filter(o => o.host === repoHostValue)
      .map(o => o.owner)
      .sort();
  };

  const getGithubDevToolId = (host: string) =>
    host === 'github.com' ? GITHUBEMU_DEV_TOOL_ID : GITHUB_DEV_TOOL_ID;

  const createNewRepositoryOption = (
    host: string,
    owner: string,
    name: string,
  ) => {
    return {
      dev_tool_id: getGithubDevToolId(host),
      owner: owner,
      key: owner,
      name: name,
      host: host,
    };
  };

  const setFormGithubRepository = (
    host: string,
    owner: string,
    name: string,
  ) => {
    setValue(
      'github_repository',
      createNewRepositoryOption(host, owner, name),
      { shouldValidate: true, shouldDirty: true },
    );
  };

  const handleDialogClose = (data: { key: string; name: string }) => {
    if (!isEmptyOrNil(data) && data.key && data.name) {
      setRepositoryOptions([
        ...repositoryOptions,
        createNewRepositoryOption(repoHostValue, data.key, data.name),
      ]);
      setRepoOwnerValue(data.name);

      setFormGithubRepository(repoHostValue, data.name, repoNameValue);
    }

    setGithubOrgDialogOpen(false);
  };

  const onRepoHostChange = (_: React.ChangeEvent<{}>, v: string) => {
    const isSameAsPreviousHost = v === repoHostValue;

    setRepoHostValue(v);

    if (v !== repoHostValue) {
      setRepoOwnerValue('');
    }

    setFormGithubRepository(
      v,
      isSameAsPreviousHost ? repoOwnerValue : '',
      repoNameValue,
    );
  };

  const onRepoOwnerChange = (_: React.ChangeEvent<{}>, v: string) => {
    setRepoOwnerValue(v);

    setFormGithubRepository(repoHostValue, v, repoNameValue);
  };

  const onRepoNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepoNameValue(event.currentTarget.value);

    setFormGithubRepository(
      repoHostValue,
      repoOwnerValue,
      event.currentTarget.value,
    );
  };

  const syncRepoNameWithAppName = () => {
    const appName = getValues('app_name');

    setRepoNameValue(appName);
    setFormGithubRepository(repoHostValue, repoOwnerValue, appName);
  };

  return (
    <>
      <Grid container spacing={3} id="mtfuji_wizard_github_repository">
        <Grid item xs={12}>
          <Typography variant="h5">GitHub Repository</Typography>
          <p>
            Stargate Multicloud supports repositories on{' '}
            <Link target="_new" href="https://github.tmc-stargate.com/">
              Stargate GitHub Enterprise (GHE)
            </Link>{' '}
            and{' '}
            <Link target="_new" href="https://github.com/enterprises/stargate">
              Stargate GitHub EMU
            </Link>
            . For the best possible integration, we recommend using Stargate
            GitHub EMU (github.com).
          </p>
        </Grid>

        <Grid item xs={3}>
          <ControlAutocomplete
            name="github_repository.host"
            rules={{ required: true }}
            control={control}
            loading={autocompletionsLoading}
            options={hostOptions}
            getOptionLabel={o => o}
            getOptionSelected={(o, v) => o === v}
            renderInput={params => (
              <StyledTextField
                {...params}
                label="GitHub Host"
                placeholder="Select a GitHub host from the list"
                helperText="Select the GitHub host you want to use."
              />
            )}
            onChange={onRepoHostChange}
            value={repoHostValue}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlAutocomplete
            name="github_repository.owner"
            rules={{ required: true }}
            control={control}
            loading={autocompletionsLoading}
            options={ownerOptions}
            value={repoOwnerValue}
            getOptionLabel={o => o}
            onChange={onRepoOwnerChange}
            getOptionSelected={(o, v) => o === v}
            filterOptions={filterOwners}
            renderInput={params => (
              <StyledTextField
                {...params}
                label="GitHub Organization"
                placeholder="Select an organization from the list"
                error={getFieldState('github_repository.owner').invalid}
                helperText={
                  <>
                    {getFieldState('github_repository.owner').invalid && (
                      <Typography
                        variant="inherit"
                        component="p"
                        color="error"
                        style={{ color: '#CC0000', marginBottom: 10 }}
                      >
                        {
                          getFieldState('github_repository.owner').error
                            ?.message
                        }
                      </Typography>
                    )}
                    <div>
                      {repoHostValue !== 'github.stg.tmc-stargate.com' && (
                        <>
                          <Link
                            className={classes.inlineCreateLink}
                            onClick={() =>
                              setGithubOrgDialogOpen(
                                !isEmptyOrNil(repoHostValue),
                              )
                            }
                          >
                            Create a new one
                          </Link>{' '}
                          or{' '}
                        </>
                      )}
                      Select the organization to create your repository in.
                    </div>
                  </>
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlTextField
            name="github_repository.name"
            rules={{
              required: true,
              pattern: new RegExp('^[A-Za-z0-9_.-]+$'),
            }}
            control={control}
            value={repoNameValue}
            onChange={onRepoNameChange}
            label="Repository Name"
            placeholder="Specify the repository"
            helperText="Specify the repository. The Github Action Runners will be authorized to make changes to your namespaces on MtFuji when run from this repository."
          />
        </Grid>

        <Grid item xs={1}>
          <Tooltip title="Use Application name as Repository name">
            <IconButton
              style={{ marginTop: '28px' }}
              onClick={syncRepoNameWithAppName}
            >
              <SyncTwoToneIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <CreateGithubOrgDialog
        open={repoHostValue !== '' && userGithubOrgDialogOpen}
        handleDialogClose={handleDialogClose}
        stargateProject={stargateProject}
        devTool={getGithubDevToolId(repoHostValue)}
      />
    </>
  );
};
