import React from 'react';
import { Typography } from '@material-ui/core';

import {
  InfoCard,
  Progress,
  ResponseErrorPanel,
} from '@backstage/core-components';
import { LinkAction } from './LinkAction';
import { ComponentsTable } from './ComponentsTable';
import { Component } from '../types';

interface ComponentCardProps {
  isLoading: boolean;
  component: Component | null | undefined;
  error: Error | null | undefined;
}

export const ComponentCard = ({
  isLoading,
  error,
  component,
}: ComponentCardProps) => {
  if (isLoading) {
    return <Progress title="Loading Statuspage Component" />;
  }

  if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  if (!component) {
    return (
      <InfoCard title="Service Status">
        <Typography variant="body2">
          The specified statuspage.io components could not be found. Check your
          annotation.
        </Typography>
      </InfoCard>
    );
  }

  return (
    <InfoCard title="Service Status" action={<LinkAction />}>
      <ComponentsTable components={[component]} />
    </InfoCard>
  );
};
