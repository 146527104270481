import { useCallback, useEffect, useState } from 'react';

import { microsoftAuthApiRef, useApi } from '@backstage/core-plugin-api';

import { VAULT_DEV_TOOL_ID } from 'usg-types';
import { projectApiRef } from '../../../../api';
import {
  Environment,
  ResourceData,
  UserGroup,
} from '../AssignableTabs/VaultViewEditTab/VaultTable/types';

export const useUserGroupsHooks = (
  projectId: string,
  resourceData: ResourceData,
  attachableUserGroups: Array<any>,
) => {
  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);

  const [projectData, setProjectData] = useState<any>(undefined);
  const [tabIndex, setTabIndex] = useState(0);
  const [environments, setEnvironments] = useState<Environment[]>([]);
  const [userGroups, setUserGroups] = useState<UserGroup[] | null>(null);

  const [availableUserGroups, setAvailableUserGroups] =
    useState(attachableUserGroups);

  useEffect(() => {
    if (
      projectId === '' ||
      resourceData?.id === '' ||
      resourceData.linkedResources.length === 0
    ) {
      return;
    }

    setEnvironments(
      resourceData.linkedResources.map(e => {
        return {
          id: e.id,
          environment: e.environment,
          devToolId: VAULT_DEV_TOOL_ID,
        };
      }),
    );

    (async () => {
      const idToken = await authref.getIdToken();
      const params = {
        manipulators: [
          'resources',
          `resource_${resourceData.id}`,
          'is_combined',
          'user_groups',
        ],
      };

      const data = await projectApi.getProjectByID(projectId, idToken, params);
      setProjectData(data);
    })();
  }, [
    projectId,
    resourceData.id,
    authref,
    projectApi,
    resourceData.linkedResources,
  ]);

  const adjustAvailableUserGroups = useCallback(
    (userGroupsData: UserGroup[] | null) => {
      if (!userGroupsData) {
        return;
      }
      const availGroups = attachableUserGroups.filter(
        a => userGroupsData.filter(u => u.id === a.id).length === 0,
      );
      setAvailableUserGroups(availGroups);
    },
    [attachableUserGroups],
  );

  const refreshAssignedGroups = useCallback(async () => {
    const token = await authref.getIdToken();
    const res: any = await projectApi.getUserGroupsOfResource(
      projectId,
      String(environments[tabIndex].id),
      token,
      {},
    );

    let userGroupsData = res?.data.user_groups_roles;
    const groupIdToObjectMapping: any = {};
    const userGroupsArray = projectData?.user_groups;
    userGroupsArray.forEach((group: any) => {
      if (!(group.id in groupIdToObjectMapping))
        groupIdToObjectMapping[group.id] = group;
    });

    userGroupsData = userGroupsData.map((group: any) => {
      const groupData = groupIdToObjectMapping[group.id];

      if (!groupData) {
        throw new Error(
          `Couldn't find group ${group.id} under project ${projectId}`,
        );
      }

      return {
        ...group,
        name: groupData.name,
        rule_based: groupData.rule_based,
        archived: groupData.deleted_on ? true : false,
      };
    });

    setUserGroups(userGroupsData);
  }, [
    tabIndex,
    projectData,
    projectId,
    authref,
    projectApi,
    setUserGroups,
    environments,
  ]);

  useEffect(() => {
    adjustAvailableUserGroups(userGroups);
  }, [adjustAvailableUserGroups, userGroups]);

  useEffect(() => {
    if (!projectData) {
      return;
    }

    refreshAssignedGroups();
  }, [projectData, tabIndex, refreshAssignedGroups]);

  return {
    userGroups,
    availableUserGroups,
    setTabIndex,
    projectData,
    tabIndex,
    environments,
    refreshAssignedGroups,
  };
};
