import React from 'react';
import { Link, makeStyles, Typography } from '@material-ui/core';
import { SEARCH_INPUT_MAX_LENGTH } from 'usg-types';

const useStyles = makeStyles(() => ({
  searchResultItemText: {
    color: 'black',
  },
}));

type Props = {
  result: any;
  section: string;
};

export const TechDocsSearchResultListItem = ({ result, section }: Props) => {
  const classes = useStyles();

  return (
    <>
      <Link href={result.document.location}>
        <Typography variant={section === 'sub_section' ? 'body1' : 'h4'}>
          {result.document.title}
        </Typography>
        <Typography variant="body2" className={classes.searchResultItemText}>
          {result.document.text.length > SEARCH_INPUT_MAX_LENGTH
            ? `${result.document.text.slice(0, SEARCH_INPUT_MAX_LENGTH)}...`
            : result.document.text}
        </Typography>
      </Link>
    </>
  );
};
