import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, Typography } from '@material-ui/core';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import { Alert, AlertTitle } from '@material-ui/lab';
import { definitions } from '../../api';
import { StyledTextField, ControlAutocomplete } from './FormHelpers';
import { ApplicationRequestProps } from './types';

// Schemas
export type NamespaceRequestSpec = definitions['handlers.NamespaceRequestSpec'];
export type ApiConfigCluster = definitions['config.MountFujiCluster'];

// props schema
export type ActiveClustersProps = {
  defaultClusters: {
    id: string;
    label: string;
    name_prefix: string;
    provider: string;
    region: string;
  }[];
  autocompletionsLoading: boolean;
  clusterOptions: ApiConfigCluster[];
  validationSetter: (isValid: boolean) => void;
};

export const ActiveClusters = (props: ActiveClustersProps) => {
  const {
    defaultClusters,
    autocompletionsLoading,
    clusterOptions,
    validationSetter,
  } = props;

  const {
    setValue,
    control,
    formState: { isValid },
  } = useFormContext<ApplicationRequestProps>();

  useEffect(() => {
    validationSetter(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const [activeClustersValue, setActiveClustersValue] =
    useState<ApiConfigCluster[]>(defaultClusters);

  const onClusterChange = (_: React.ChangeEvent<{}>, v: ApiConfigCluster[]) => {
    setActiveClustersValue(v);
    setValue('active_clusters', v, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <>
      <Grid container spacing={3} id="mtfuji_wizard_active_clusters">
        <Grid item xs={12}>
          <Typography variant="h5">Clusters</Typography>

          <p>
            Stargate Multicloud offers AWS or GCP clusters in multiple regions
            to select from. The three environment namespaces every application
            has, will be created in every region you select. Your prod namespace
            will be on a separate cluster in the same region as your non-prod
            namespaces.
          </p>

          <Alert severity="warning" icon={<WhatshotIcon fontSize="inherit" />}>
            <AlertTitle>Tip</AlertTitle>
            Select one cluster initially. You can add more later when your
            application is ready to go multi-region or multi-cloud.
          </Alert>
        </Grid>

        <Grid item xs={12}>
          <ControlAutocomplete
            name="active_clusters"
            rules={{ required: true }}
            control={control}
            multiple
            options={clusterOptions}
            loading={autocompletionsLoading}
            filterSelectedOptions
            onChange={onClusterChange}
            value={activeClustersValue}
            getOptionLabel={o => (o.label ? o.label : '')}
            getOptionSelected={(o, v) => o.id === v.id}
            renderInput={params => (
              <StyledTextField
                {...params}
                label="Active clusters"
                placeholder="Clusters"
                helperText="Select one or more clusters to run your application on."
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};
