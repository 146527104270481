import { USG_COLOR_SCHEME } from '../colorScheme';
import { USGPaletteOptions } from '../types';

export const USGPalette: USGPaletteOptions = {
  background: {
    default: USG_COLOR_SCHEME.secondary.lightGray,
  },
  status: {
    ok: USG_COLOR_SCHEME.secondary.green,
    warning: USG_COLOR_SCHEME.secondary.orange,
    error: USG_COLOR_SCHEME.secondary.darkRed,
    running: USG_COLOR_SCHEME.secondary.blue,
    pending: USG_COLOR_SCHEME.others.generic.orange,
    aborted: USG_COLOR_SCHEME.secondary.slateGray,
  },
  bursts: {
    gradient: {
      linear: `linear-gradient(-137deg, ${USG_COLOR_SCHEME.others.generic.teal} 0%, ${USG_COLOR_SCHEME.others.generic.darkGreen} 100%)`,
    },
    fontColor: USG_COLOR_SCHEME.primary.white,
    slackChannelText: USG_COLOR_SCHEME.others.grey[600],
    backgroundColor: {
      default: USG_COLOR_SCHEME.others.generic.darkPurple,
    },
  },
  primary: {
    main: USG_COLOR_SCHEME.primary.blue,
  },
  secondary: {
    main: USG_COLOR_SCHEME.secondary.blue,
  },
  banner: {
    info: USG_COLOR_SCHEME.secondary.blue,
    error: USG_COLOR_SCHEME.secondary.darkRed,
    text: USG_COLOR_SCHEME.primary.white,
    link: USG_COLOR_SCHEME.primary.black,
  },
  border: USG_COLOR_SCHEME.secondary.mediumGray,
  textContrast: USG_COLOR_SCHEME.others.grey[1000],
  textVerySubtle: USG_COLOR_SCHEME.others.grey[600],
  textSubtle: USG_COLOR_SCHEME.others.grey[800],
  highlight: USG_COLOR_SCHEME.others.generic.yellow,
  errorBackground: USG_COLOR_SCHEME.others.red[100],
  warningBackground: USG_COLOR_SCHEME.others.orange[100],
  infoBackground: USG_COLOR_SCHEME.others.blue[50],
  errorText: USG_COLOR_SCHEME.secondary.darkRed,
  infoText: USG_COLOR_SCHEME.secondary.blue,
  warningText: USG_COLOR_SCHEME.others.grey[1000],
  linkHover: USG_COLOR_SCHEME.others.blue[600],
  link: USG_COLOR_SCHEME.secondary.blue,
  gold: USG_COLOR_SCHEME.others.generic.yellow,
  navigation: {
    background: USG_COLOR_SCHEME.primary.black,
    indicator: USG_COLOR_SCHEME.primary.blue,
    color: USG_COLOR_SCHEME.others.grey[600],
    selectedColor: USG_COLOR_SCHEME.primary.white,
  },
  pinSidebarButton: {
    icon: USG_COLOR_SCHEME.others.grey[600],
    background: USG_COLOR_SCHEME.primary.black,
  },
  tabbar: {
    indicator: USG_COLOR_SCHEME.primary.blue,
  },
  usgColors: USG_COLOR_SCHEME,
};
