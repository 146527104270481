import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router';
import { usePermissions } from '../../hooks/usePermissions';
import { Progress } from '@backstage/core-components';

interface Props {
  children: React.ReactNode;
  redirectTo: string;
  anyAdmin?: boolean;
}
export const ProtectedRoute = ({ children, redirectTo, anyAdmin }: Props) => {
  const navigate = useNavigate();
  const errorApi = useApi(errorApiRef);
  const {
    isLoading: permissionsLoading,
    isAdmin,
    isAnyAdmin,
    error,
  } = usePermissions();
  const hasAccess = anyAdmin ? isAnyAdmin : isAdmin;

  const handleClose = () => {
    navigate(redirectTo);
  };
  if (permissionsLoading) {
    return <Progress />;
  }
  if (error) {
    errorApi.post(new Error(`${error}`));
  }

  return hasAccess ? (
    <>{children}</>
  ) : (
    <Dialog
      open={!hasAccess}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        You don't have admin permissions to view this page.
      </DialogTitle>
      <DialogContent />
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
