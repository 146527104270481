import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import DescriptionIcon from '@material-ui/icons/Description';
import BuildIcon from '@material-ui/icons/Build';
import HistoryIcon from '@material-ui/icons/History';
import GitHubIcon from '@material-ui/icons/GitHub';
import MemoryIcon from '@material-ui/icons/Memory';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperStyle: {
      display: 'block',
      marginBottom: theme.spacing(2),
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: 0,
    },
    blueText: {
      color: 'blue',
      textTransform: 'capitalize',
    },
    greenText: {
      color: 'green',
      textTransform: 'capitalize',
    },
  }),
);

/** @public */
export const BuildInfo = () => {
  const classes = useStyles();
  const configApi = useApi(configApiRef);
  const deployTarget =
    configApi.getOptionalString('buildInfo.deployTarget') ?? 'blue-green';
  /** cpu can have values like number 1 or string 1000m,
   *  so using getOptional since we don't know the type of the value
   * */
  const cpu = `${configApi.getOptional('buildInfo.cpuRequest') ?? '0'} / ${
    configApi.getOptional('buildInfo.cpuLimit') ?? '0'
  }`;
  const memory = `${
    configApi.getOptionalString('buildInfo.memoryRequest') ?? '0'
  } / ${configApi.getOptionalString('buildInfo.memoryLimit') ?? '0'}`;
  const deployedAt =
    configApi.getOptionalString('buildInfo.deployedAt') ??
    '2024-01-16T00:00:00Z';
  const textColorClass = deployTarget.includes('blue')
    ? classes.blueText
    : classes.greenText;
  const deployedDate = new Date(deployedAt);
  const weekdayFormatter = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
  });
  const weekday = weekdayFormatter.format(deployedDate);
  const deployedAtJst = `${deployedDate.toLocaleString('ja-JP', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })}(${weekday})`;

  return (
    <Box>
      <Paper className={classes.paperStyle}>
        <List className={classes.flexContainer}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <BuildIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Version"
              secondary={
                configApi.getOptionalString('buildInfo.version') ?? '1.0.0'
              }
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <GitHubIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Commit Id"
              secondary={
                configApi.getOptionalString('buildInfo.commitId') ?? '1a1asdd'
              }
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <HistoryIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Deployed At" secondary={deployedAtJst} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <DescriptionIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Deploy Target"
              secondary={<span className={textColorClass}>{deployTarget}</span>}
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <DeveloperBoardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="CPU Request/Limit" secondary={cpu} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <MemoryIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Memory Request/Limit" secondary={memory} />
          </ListItem>
        </List>
      </Paper>
    </Box>
  );
};
