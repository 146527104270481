import {
  configApiRef,
  createApiFactory,
  createPlugin,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { Entity } from '@backstage/catalog-model';
import { StatuspageApi, statuspageApiRef } from './api';

export const isStatuspageAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.['statuspage.io/component-id']);

export const statuspagePlugin = createPlugin({
  id: 'statuspage',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: statuspageApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ configApi, identityApi }) =>
        new StatuspageApi({ configApi, identityApi }),
    }),
  ],
});
