import React from 'react';
import { PageLayout } from '@internal/sg-ui-kit';
import { useStyles } from './styles';
import { rootRouteRef } from '../../routes';
import { useRouteRef } from '@backstage/core-plugin-api';
import { useAsyncFn } from 'react-use';
import { Progress } from '@backstage/core-components';
import { projectApiRef } from '../../api';
import {
  useApi,
  microsoftAuthApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { PendingRequestTable } from './PendingRequestTable';
import { FilterPage } from './FiltersPage';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROLES } from 'usg-types';
import { getRoles } from '@internal/sg-utils-common';

export const PendingRequestPage = () => {
  const classes = useStyles();
  const rootLink = useRouteRef(rootRouteRef);
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const [selectedListItems, setSelectedListItems] = React.useState([]);
  const [processState, setProcessState] = React.useState('');
  const [sentStatus, setSentStatus] = React.useState('');
  const [nextPage, setNextPage] = React.useState(3);
  const [userEmail, setUserEmail] = React.useState('');
  const [sourceApplication, setSourceApplication] = React.useState('');
  const [action, setAction] = React.useState('');
  const [approveStatus, setApproveStatus] = React.useState('');
  const [sentStatusFilter, setSentStatusFilter] = React.useState('');
  const [norenStatus, setNorenStatus] = React.useState('');
  const navigate = useNavigate();
  const errorApi = useApi(errorApiRef);
  // const [filterOpen, setFilterOpen] = React.useState(false);
  const [errorIds, setErrorIds] = React.useState([]);
  const [tableState, setTableState] = React.useState({
    page: 1,
    pageSize: 20,
  });
  const [userRoles, setUserRoles] = React.useState<string[]>([]);

  // Back to Projects list page
  const backToLink = React.useMemo(
    () => ({
      to: rootLink(),
      label: 'Back to Projects',
    }),
    [rootLink],
  );

  // To get all list of pending user membership requests
  const [{ value: data = [] as any, loading, error }, fetchContent] =
    useAsyncFn(async () => {
      const idToken = await authref.getIdToken();
      const params = {
        idToken: idToken,
      };
      const filterData = {
        process_state: processState,
        size: tableState.pageSize,
        page: tableState.page,
        user_email: userEmail,
        source_application: sourceApplication,
        action: action,
        status: approveStatus,
        sent: sentStatusFilter,
        noren_progress_status: norenStatus,
      };
      const requestsData = (await projectApi.getAllUserMembershipRequestsData(
        filterData as any,
        params,
      )) as any;

      try {
        const adminData: any = await projectApi.getUserDetails(params);
        setUserRoles([...getRoles(adminData?.response?.data?.roles)]);
        setSentStatus('');
        setNextPage(requestsData?.response?.data?.next_page);
      } catch (err) {
        errorApi.post(new Error(`${err?.message}`));
        setSentStatus('');
      }
      return requestsData;
    }, [
      processState,
      sentStatus,
      tableState.page,
      tableState.pageSize,
      userEmail,
      sourceApplication,
      action,
      approveStatus,
      sentStatusFilter,
      norenStatus,
    ]);

  React.useEffect(() => {
    fetchContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    processState,
    sentStatus,
    tableState.page,
    tableState.pageSize,
    userEmail,
    sourceApplication,
    action,
    approveStatus,
    sentStatusFilter,
    norenStatus,
  ]);

  if (loading) {
    return <Progress />;
  }

  if (error) {
    errorApi.post(new Error(`${error}`));
  }

  const handleClose = () => {
    navigate('/projects');
  };

  const handleOkButton = () => {
    navigate('/projects');
  };

  return (
    <PageLayout
      title="Group Membership Requests"
      headerBackgroundImg="/assets/welcome_bg.webp"
      backToLink={backToLink}
    >
      <div className={classes.container}>
        <FilterPage
          selectedListItems={selectedListItems}
          processState={processState}
          setProcessState={setProcessState}
          setSentStatus={setSentStatus}
          setUserEmail={setUserEmail}
          setSourceApplication={setSourceApplication}
          setAction={setAction}
          setApproveStatus={setApproveStatus}
          setSentStatusFilter={setSentStatusFilter}
          setNorenStatus={setNorenStatus}
          setErrorIds={setErrorIds}
        />
        <PendingRequestTable
          data={
            data?.response?.data?.requests &&
            userRoles.some(role => ADMIN_ROLES.includes(role))
              ? data?.response?.data?.requests
              : []
          }
          setSelectedListItems={setSelectedListItems}
          userEmail={userEmail}
          sourceApplication={sourceApplication}
          action={action}
          approveStatus={approveStatus}
          setNorenStatus={setNorenStatus}
          norenStatus={norenStatus}
          setSentStatus={setSentStatus}
          tableState={tableState}
          setTableState={setTableState}
          nextPage={nextPage}
          setUserEmail={setUserEmail}
          setSourceApplication={setSourceApplication}
          setAction={setAction}
          setApproveStatus={setApproveStatus}
          setSentStatusFilter={setSentStatusFilter}
          sentStatusFilter={sentStatusFilter}
          errorIds={errorIds}
        />
      </div>
      <Dialog
        open={!userRoles.some(role => ADMIN_ROLES.includes(role))}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          You don't have permissions to view the user membership requests page.
        </DialogTitle>
        <DialogContent />
        <DialogActions>
          <Button
            variant="contained"
            className={classes.requestAccessBtn}
            onClick={handleOkButton}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
};
