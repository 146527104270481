import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './styles';
import { Alert, AlertProps, AlertTitle } from '@material-ui/lab';

interface Props extends AlertProps {
  alertTitle?: string;
  alertMessage: string;
  delayTime?: number;
  condition?: boolean;
}
export const AlertNotification: React.FC<Props> = ({
  alertTitle,
  alertMessage,
  delayTime,
  condition,
  ...alertProps
}: any) => {
  const styles = useStyles();
  const [isAlertVisible, setIsAlertVisible] = useState(() => {
    if (delayTime) {
      return false;
    } else if (condition !== undefined) {
      return condition;
    }
    return true;
  }); // do not show if there is delay time// follow condition if the condition is defined but no delay time  // show if there was no condition defined

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (condition && delayTime) {
      timeoutRef.current = setTimeout(() => {
        setIsAlertVisible(() => true);
      }, delayTime);
    } else if (condition !== undefined) {
      setIsAlertVisible(() => condition);
      if (timeoutRef.current !== null && !condition)
        clearTimeout(timeoutRef.current);
    }
    return () => {
      if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);

  return isAlertVisible ? (
    <Alert className={styles.alertMsg} {...alertProps}>
      {alertTitle && (
        <AlertTitle style={{ marginBottom: 0 }}>{alertTitle}</AlertTitle>
      )}
      <div className={styles.alertSubHeading}>{alertMessage}</div>
    </Alert>
  ) : null;
};
