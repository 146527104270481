import { Typography } from '@material-ui/core/styles/createTypography';

export const USGTypography: Partial<Typography> = {
  h1: {
    fontSize: 32,
    letterSpacing: '-0.5px',
    fontWeight: 700,
    lineHeight: 1.25,
  },
  h2: {
    fontSize: 28,
    letterSpacing: '-0.25px',
    fontWeight: 600,
    lineHeight: 1.43,
  },
  h3: {
    fontSize: 24,
    letterSpacing: '-0.25px',
    fontWeight: 600,
    lineHeight: 1.33,
  },
  h4: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.44,
  },
  subtitle2: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 1.33,
  },
  body1: {
    fontSize: 16,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: 14,
    lineHeight: 1.43,
  },
  caption: {
    fontSize: 12,
    letterSpacing: '0.25px',
    fontWeight: 700,
    lineHeight: 1.33,
  },
  button: {
    fontSize: 14,
    letterSpacing: '1px',
    fontWeight: 700,
    lineHeight: 1.33,
  },
  overline: {
    fontSize: 12,
    lineHeight: 1.33,
    textTransform: 'initial',
  },
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
};
