import React from 'react';
import {
  Typography,
  Grid,
  Button,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useStyles } from './styles';
import { useAsyncFn } from 'react-use';
import { projectApiRef } from '../../api';
import {
  useApi,
  microsoftAuthApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { Progress } from '@backstage/core-components';

type Props = {
  selectedListItems?: any;
  setProcessState: any;
  processState: string;
  setSentStatus: any;
  setUserEmail: any;
  setSourceApplication: any;
  setAction: any;
  setApproveStatus: any;
  setNorenStatus: any;
  setSentStatusFilter: any;
  setErrorIds: any;
};
export const FilterPage = ({
  selectedListItems = [],
  setErrorIds,
  setNorenStatus,
  setSentStatusFilter,
  processState,
  setProcessState,
  setSentStatus,
  setUserEmail,
  setSourceApplication,
  setAction,
  setApproveStatus,
}: Props) => {
  const classes = useStyles();
  const [selectedSort, setSelectedSort] = React.useState('');
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const errorApi = useApi(errorApiRef);

  // Calling the following action api's based on user selected action like cancel,retry,hold and resume
  const [{ loading, error }, fetchContent] = useAsyncFn(async () => {
    const data = (await authref.getIdToken().then(async (token: any) => {
      const params = {
        idToken: token,
        request_ids: selectedListItems
          ? selectedListItems.map(({ id }: any) => id)
          : [],
      };
      if (selectedSort === 'Cancel') {
        return await projectApi.cancelUserMembership(params);
      } else if (selectedSort === 'Reset') {
        return await projectApi.retryUserMembership(params);
      } else if (selectedSort === 'Pause') {
        return await projectApi.holdUserMembership(params);
      } else if (selectedSort === 'Resume') {
        return await projectApi.resumeUserMembership(params);
      } else if (selectedSort === 'Approve') {
        return await projectApi.approveUserMembership(params);
      } else if (selectedSort === 'Reject') {
        return await projectApi.rejectUserMembership(params);
      }
      return {};
    })) as any;
    const message = data?.response?.data?.message;
    if (message) {
      errorApi.post(new Error(message));
    } else {
      setSentStatus('SUCCESS');
      setErrorIds([]);
    }
    const code = data?.response?.status;
    if (code === 401 || code === 404 || code === 403) {
      const errorMsg = data?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
    }
    return data;
  }, [selectedListItems, selectedSort]);

  if (loading) {
    return <Progress />;
  }

  if (error) {
    errorApi.post(new Error(error.message));
  }
  const handleSortChange = (event: any) => {
    setSelectedSort(event.target.value);
  };
  const handleFiltersChange = (event: any) => {
    setProcessState(event.target.value);
  };
  const selectedSortProcess = (sort: any) => {
    switch (sort) {
      case 'Cancel':
        return 'canceled';
      case 'Pause':
        return 'paused';
      case 'Resume':
        return 'resumed';
      case 'Reset':
        return 'reseted';
      case 'Approve':
        return 'approved';
      case 'Reject':
        return 'rejected';
      default:
        return '';
    }
  };
  const handleApplyAction = () => {
    const errorIds = [];
    if (selectedSort === 'Cancel') {
      errorIds.push(
        selectedListItems
          .filter((x: any) => x.sent === 'CANCELLED' || x.sent === 'SUCCESS')
          .map((x: any) => x.id),
      );
    } else if (selectedSort === 'Approve') {
      errorIds.push(
        selectedListItems
          .filter(
            (x: any) =>
              x.status !== 'PENDING' ||
              x.sent === 'CANCELLED' ||
              x.sent === 'SUCCESS' ||
              x.sent === 'HOLD' ||
              x.sent === 'RETRY_EXCEED',
          )
          .map((x: any) => x.id),
      );
    } else if (selectedSort === 'Reject') {
      errorIds.push(
        selectedListItems
          .filter(
            (x: any) =>
              x.status !== 'PENDING' ||
              x.sent === 'CANCELLED' ||
              x.sent === 'SUCCESS' ||
              x.sent === 'HOLD' ||
              x.sent === 'RETRY_EXCEED',
          )
          .map((x: any) => x.id),
      );
    } else if (selectedSort === 'Pause') {
      errorIds.push(
        selectedListItems
          .filter(
            (x: any) =>
              x.sent === 'CANCELLED' ||
              x.sent === 'SUCCESS' ||
              x.sent === 'HOLD' ||
              x.sent === 'RETRY_EXCEED',
          )
          .map((x: any) => x.id),
      );
    } else if (selectedSort === 'Resume') {
      errorIds.push(
        selectedListItems
          .filter(
            (x: any) =>
              x.sent === 'CANCELLED' ||
              x.sent === 'SUCCESS' ||
              x.sent === 'NONE' ||
              x.sent === 'FAILED' ||
              x.sent === 'RETRY_EXCEED',
          )
          .map((x: any) => x.id),
      );
    } else if (selectedSort === 'Reset') {
      errorIds.push([]);
    }
    if (selectedListItems.length > 0) {
      if (errorIds[0].length > 0) {
        setErrorIds(errorIds[0]);
        errorApi.post(
          new Error(
            `The highlighted requests cannot be ${selectedSortProcess(
              selectedSort,
            )}.`,
          ),
        );
      } else {
        fetchContent();
        setErrorIds([]);
      }
    } else {
      errorApi.post(new Error('Please select at least one item in the list.'));
    }
  };

  // To clear all variables
  const handleClear = () => {
    setSelectedSort('');
    setProcessState('');
    setApproveStatus('');
    setAction('');
    setUserEmail('');
    setSourceApplication('');
    setNorenStatus('');
    setSentStatusFilter('');
    setErrorIds([]);
  };

  return (
    <div className={classes.support}>
      <Grid container>
        <Grid item xs={4}>
          <Typography
            variant="button"
            className={classes.mainHeading}
            paragraph
          >
            Group Membership Requests
          </Typography>
          <Typography variant="overline" paragraph>
            Use this page to check the status of your requests to add users to
            groups or remove users from groups.
          </Typography>
        </Grid>
        <Grid item>
          <FormControl variant="outlined" className={classes.formControl}>
            <div className={classes.ClearDiv}>
              <Typography
                variant="button"
                className={classes.mainHeading}
                paragraph
              >
                Filter Results
              </Typography>
              <Typography
                className={classes.clearText}
                paragraph
                onClick={handleClear}
              >
                Clear All
              </Typography>
            </div>
            <Typography variant="body2" paragraph>
              Process state
            </Typography>
            <Select
              value={processState}
              displayEmpty
              onChange={handleFiltersChange}
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="FAIL">Failed Requests</MenuItem>
              <MenuItem value="ACTIVE">Active Requests</MenuItem>
              <MenuItem value="ACTIVE_AND_FAIL">
                Active + Failed Requests
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* <Divider orientation="vertical" className={classes.filterDivider} /> */}
        <Grid item>
          <FormControl variant="outlined" className={classes.formControl}>
            <Typography variant="body2" className={classes.actions} paragraph>
              Actions
            </Typography>
            <Select
              value={selectedSort}
              displayEmpty
              onChange={handleSortChange}
            >
              <MenuItem value="">Select Action</MenuItem>
              <MenuItem value="Reset">Reset</MenuItem>
              <MenuItem value="Pause">Pause</MenuItem>
              <MenuItem value="Resume">Resume</MenuItem>
              <MenuItem value="Cancel">Cancel</MenuItem>
              <MenuItem value="Approve">Approve</MenuItem>
              <MenuItem value="Reject">Reject</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={2}
          className={classes.applyBtn}
          style={{ maxWidth: 'fit-content' }}
        >
          <Button
            variant="contained"
            onClick={handleApplyAction}
            disabled={!selectedSort || selectedListItems.length <= 0}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
