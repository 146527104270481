import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { createGlobalState } from 'react-use';

interface withFlagInterface {
  withFlag: string;
  withoutFlag?: never;
}

interface withoutFlagInterface {
  withFlag?: never;
  withoutFlag: string;
}

// should except either withFlag or withoutFlag
type Props = withFlagInterface | withoutFlagInterface;

const useCachedBinaryFeatureFlags = createGlobalState<any>({});

const promises: any = {};

export const BinaryFeatureFlagged = ({
  children,
  withFlag,
  withoutFlag,
}: PropsWithChildren<Props>) => {
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const errorApi = useApi(errorApiRef);
  const [cachedFeatureFlags, setCachedFeatureFlags] =
    useCachedBinaryFeatureFlags();
  const [withFlagValue, setWithFlagValue] = useState(false);
  const [withoutFlagValue, setWithoutFlagValue] = useState(false);

  const fetchFlag = useCallback(
    async (flagName: string) => {
      if (promises[flagName]) {
        return promises[flagName];
      }
      const promise = new Promise(async (resolve, reject) => {
        if (flagName in cachedFeatureFlags) {
          resolve(cachedFeatureFlags[flagName]);
        } else {
          try {
            const res = await featureFlagsApi.getBinaryFlag(flagName);
            const value = res.data;
            setCachedFeatureFlags((prevState: any) => {
              return {
                ...prevState,
                [flagName]: value,
              };
            });
            resolve(value);
          } catch (error) {
            reject(error);
          } finally {
            delete promises[flagName];
          }
        }
      });
      promises[flagName] = promise;
      return promise;
    },
    [cachedFeatureFlags, featureFlagsApi, setCachedFeatureFlags],
  );

  useEffect(() => {
    (async () => {
      try {
        if (withFlag) {
          const value = await fetchFlag(withFlag);
          setWithFlagValue(value);
        }
        if (withoutFlag) {
          const value = await fetchFlag(withoutFlag);
          setWithoutFlagValue(!value);
        }
      } catch (e) {
        errorApi.post(new Error(`${e?.message}`));
      }
    })();
  }, [featureFlagsApi, errorApi, withFlag, withoutFlag, fetchFlag]);

  return (
    <>
      {withFlag && withFlagValue ? children : null}
      {withoutFlag && withoutFlagValue ? children : null}
    </>
  );
};
