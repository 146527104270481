import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles<USGTheme>(theme => ({
  title: {
    color: theme.palette.usgColors.primary.blue,
    '& h2': {
      fontSize: '24px',
    },
  },
  actions: {
    justifyContent: 'start',
    margin: 17,
  },
  emojiIcon: {
    '& svg': {
      fontSize: 40,
    },
    color: '#0E4295 !important',
  },
  dailogWidth: {
    maxWidth: 444,
  },
  ratingText: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  excellentText: {
    marginRight: '93px',
  },
  // poorText: {
  //     marginLeft: '9px',
  // },
  contactUs: {
    marginLeft: '26px',
    marginTop: '-16px',
  },
  textArea: {
    '& textarea': {
      fontFamily: 'ToyotaType',
      padding: 5,
      borderRadius: '4px',
    },
  },
  descriptionHelperText: {
    fontSize: 12,
  },
}));
