import React from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { useStyles } from '../../styles';

type resData = {
  key: string;
  name: string;
};

type NewVaultViewEditProps = {
  eventType: string;
  resourceData: resData;
};

const NewVaultViewEdit = ({
  eventType,
  resourceData,
}: NewVaultViewEditProps) => {
  const classes = useStyles();

  const viewCase = (
    <Paper>
      <Grid container sx={{ pb: 4 }}>
        <Grid item>
          <Container>
            <h4 className={classes.labelHeader}>Vault Namespace Key</h4>
            <p id="resource-key-value" className={classes.viewTag}>
              {resourceData?.key}
            </p>
            <h4 className={classes.labelHeader}>Vault Namespace Name</h4>
            <p id="resource-name-value" className={classes.viewTag}>
              {resourceData?.name}
            </p>
          </Container>
        </Grid>
      </Grid>
    </Paper>
  );

  const editCase = <></>;

  switch (eventType) {
    case 'edit':
      return editCase;
    default:
      return viewCase;
  }
};

export default NewVaultViewEdit;
