export const TECHDOCSLINKS = {
  base: '/docs/default/Component/STARGATE-WELCOME-GUIDES',
  'stargate-welcome-guides': '',
  'welcome-to-stargate':
    '/non-mandatory-docs/portal-docs/platform-documentation/welcome-to-stargate/',
  architecture: '/mandatory-docs/platform-governance/architecture/',
  'when-to-use-Stargate':
    '/non-mandatory-docs/portal-docs/platform-documentation/when-to-use-Stargate/',
  'admin-overview':
    '/non-mandatory-docs/portal-docs/project-management/admin-overview/',
  'user-groups':
    '/non-mandatory-docs/portal-docs/project-management/user-groups/user-groups/',
  'creating-project':
    '/non-mandatory-docs/portal-docs/project-management/creating-project/',
  'api-overview':
    '/non-mandatory-docs/portal-docs/project-management/stargate-api/api-overview/',
  'automatic-dynamic-groups':
    '/non-mandatory-docs/portal-docs/project-management/project-resources/automatic-dynamic-groups/',
  'list-of-tools':
    '/non-mandatory-docs/portal-docs/platform-documentation/list-of-tools/',
  'service-catalog':
    '/non-mandatory-docs/portal-docs/platform-documentation/service-catalog/',
  'ip-whitelisting':
    '/non-mandatory-docs/portal-docs/platform-documentation/networking/ip-whitelisting/',
  'security-banner': '/mandatory-docs/policies/security/security-banner/',
  'data-retention': '/mandatory-docs/policies/common/data-retention/',
  'confidentiality-agreement':
    '/mandatory-docs/policies/security/confidentiality-agreement/',
  'contact-us': '/contact-us/',
  'actions-catalog':
    '/non-mandatory-docs/tool-docs/github-actions/actions-catalog/',
  'artifactory-cloud-migration':
    '/non-mandatory-docs/tool-docs/artifactory/manuals/artifactory-cloud-migration/',
  'project-membership-ip':
    '/ja/non-mandatory-docs/portal-docs/project-management/user-management/project-membership-ip/',
  'advanced-search':
    '/non-mandatory-docs/portal-docs/project-management/project-resources/advanced-search/',
  'artifactory-saas-standard':
    '/mandatory-docs/standards/tools/artifactory/artifactory-saas-standard/',
  vpn: '/non-mandatory-docs/portal-docs/platform-documentation/networking/vpn/',
  'delegate-user-mgmt':
    '/non-mandatory-docs/portal-docs/project-management/user-management/delegate-user-mgmt/',
  'project-access':
    '/non-mandatory-docs/portal-docs/project-management/project-access/',
  'dev-tool-sync':
    '/non-mandatory-docs/portal-docs/project-management/project-resources/dev-tool-sync/',
  'jira-github-emu-integration':
    '/non-mandatory-docs/tool-docs/jira/jira-github-emu-integration/',
  'shareable-usergroups':
    '/non-mandatory-docs/portal-docs/project-management/user-groups/shareable-usergroups/',
  'auditing-members':
    '/non-mandatory-docs/portal-docs/project-management/user-management/auditing-members/',
  'add-remove-users-project':
    '/non-mandatory-docs/portal-docs/project-management/user-groups/add-remove-users-project/',
  'membership-failure':
    '/non-mandatory-docs/portal-docs/project-management/user-management/membership-failure/',
  'add-collaborating-companies':
    '/non-mandatory-docs/portal-docs/project-management/user-management/add-collaborating-companies/',
  'gac-accounts':
    '/non-mandatory-docs/portal-docs/project-management/user-management/gac/gac-accounts/',
};

export const getTechDocsLink = (key: string, lang?: string): string => {
  const appendLang = lang && lang !== 'en' ? `/${lang}` : '';
  const path = TECHDOCSLINKS[key as keyof typeof TECHDOCSLINKS] || '';
  return `${TECHDOCSLINKS.base}${appendLang}${path}`;
};
