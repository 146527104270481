import {
  createPlugin,
  createRoutableExtension,
  createApiFactory,
  configApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef, newAppRouteRef } from './routes';
import { MtfujiApi, mtfujiApiRef } from './fetcher';

export const mtfujiPlugin = createPlugin({
  id: 'mtfuji',
  routes: {
    root: rootRouteRef,
    new: newAppRouteRef,
  },
  apis: [
    createApiFactory({
      api: mtfujiApiRef,
      deps: {
        configApi: configApiRef,
        microsoftAuthApi: microsoftAuthApiRef,
      },
      factory({ configApi, microsoftAuthApi }) {
        return new MtfujiApi({ configApi, microsoftAuthApi });
      },
    }),
  ],
});

export const MtfujiPage = mtfujiPlugin.provide(
  createRoutableExtension({
    name: 'MtfujiNamespaces',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
