import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useApi } from '@backstage/core-plugin-api';
import { useDownloadFile } from '../../downloadFile';
import { mtfujiApiRef } from '../../fetcher';
import { green } from '@material-ui/core/colors';
import { CodeSnippet } from '@backstage/core-components';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '20%',
      marginTop: -12,
    },
  }),
);

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const nonprodHelp = `# Download the KUBECONFIG and store it as:
~/.kube/config

# List the non-prod namespaces you have access to:
kubectl config get-contexts`;

const prodHelp = `# Download the KUBECONFIG and store it as:
~/.kube/stargate-k8s-prod

# List the prod namespaces you have access to:
KUBECONFIG=~/.kube/stargate-k8s-prod kubectl config get-contexts`;

interface KubeconfigButtonProps {
  fetcher: () => Promise<string>;
  filename: string;
  btnStyle: string;
  setAlertMessage: (error: Error | undefined) => void;
}

const KubeConfigButton = (props: KubeconfigButtonProps) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  const { download, ref, url } = useDownloadFile({
    apiRequest: props.fetcher,
    preDownload: () => setLoading(true),
    postDownload: () => setLoading(false),
    onError: (error: Error | undefined) => {
      setLoading(false);
      props.setAlertMessage(error);
    },
    getFileName: () => props.filename,
  });

  return (
    <div className={classes.wrapper}>
      <a
        href={url}
        download={props.filename}
        className="hidden"
        ref={ref}
        title="download"
        aria-label="download"
      >
        &nbsp;
      </a>
      <Button
        color="primary"
        startIcon={<GetAppIcon />}
        variant="contained"
        onClick={download}
        disabled={loading}
      >
        Download KubeConfig
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};

const EnvTabs = () => {
  const [value, setValue] = React.useState<number>(0);
  const [alertMessage, setAlertMessage] = React.useState<string>();

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleSetAlertMessage = (error: Error | undefined) => {
    setAlertMessage(error?.message);
  };

  const mtfujiApi = useApi(mtfujiApiRef);

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={alertMessage === undefined}>
        <Paper square>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label="Non-Prod"
              id="simple-tab-0"
              aria-controls="simple-tabpanel-0"
            />
            <Tab
              label="Production"
              id="simple-tab-1"
              aria-controls="simple-tabpanel-1"
            />
          </Tabs>
        </Paper>
        <TabPanel value={value} index={0}>
          <CodeSnippet text={nonprodHelp} language="sh" />
          <KubeConfigButton
            fetcher={mtfujiApi.getKubeConfNonProd}
            filename="non-prod"
            btnStyle="default"
            setAlertMessage={handleSetAlertMessage}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CodeSnippet text={prodHelp} language="sh" />
          <KubeConfigButton
            fetcher={mtfujiApi.getKubeConfProd}
            filename="prod"
            btnStyle="secondary"
            setAlertMessage={handleSetAlertMessage}
          />
        </TabPanel>
      </Collapse>
      <Collapse in={alertMessage !== undefined}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setAlertMessage('')}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Downloading the kubeconfig is not possible at the moment, please try
          again later.
          {alertMessage}
        </Alert>
      </Collapse>
    </Box>
  );
};

interface KubeconfigDialogProps {
  open: boolean;
  close: () => void;
}

export const KubeconfigDialog = (props: KubeconfigDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.close}
      aria-labelledby="download-kubeconfig-dialog"
    >
      <DialogTitle id="download-kubeconfig-dialog">
        Download Kubeconfig
      </DialogTitle>
      <DialogContent>
        <EnvTabs />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.close}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
