import { IconButton } from '@material-ui/core';
import OpenInNew from '@material-ui/icons/OpenInNew';
import React from 'react';

export const LinkAction = () => (
  <IconButton
    aria-label="Go to our statuspage"
    role="button"
    title="Go to statuspage(.io)"
    href="https://stargate.statuspage.io/"
    target="_blank"
  >
    <OpenInNew />
  </IconButton>
);
