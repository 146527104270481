import React from 'react';
import { useParams } from 'react-router';

import { Progress } from '@backstage/core-components';

import { useViewEditResourceContext, ViewEditResourceContext } from './context';
import useFeatureFlag from './hooks/useFeatureFlag';
import { NewViewEditResourceComponent } from './NewViewEditResourceComponent';
import { OldViewEditResourceComponent } from './OldViewEditResourceComponent';

const ResourcePageComponent = () => {
  const { projectId } = useParams() as { projectId: string };
  const { resourceId } = useParams() as { resourceId: string };

  const resourceContext = useViewEditResourceContext();

  // in the future if additional data is added in the context then the Progress must be shown when waiting for that extra data too
  if (resourceContext.isNewVaultUiEnabled === undefined) {
    return <Progress />;
  }

  if (resourceContext.isNewVaultUiEnabled) {
    return (
      <NewViewEditResourceComponent
        projectId={projectId}
        resourceId={resourceId}
      />
    );
  }
  return (
    <OldViewEditResourceComponent
      projectId={projectId}
      resourceId={resourceId}
    />
  );
};

export const ViewEditResourcePage = () => {
  const { isNewVaultUiEnabled, loadingFeatureFlags } = useFeatureFlag();

  if (loadingFeatureFlags) {
    return <Progress />;
  }

  // For now it is just feature flag for vault ui but in the future we can add all other data
  return (
    <ViewEditResourceContext.Provider value={{ isNewVaultUiEnabled }}>
      <ResourcePageComponent />
    </ViewEditResourceContext.Provider>
  );
};
