import React, { PropsWithChildren } from 'react';
import {
  Popover,
  Typography,
  withStyles,
  Grid,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './styles';

const StyledMuiPopover = withStyles(theme => ({
  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(3),
    maxWidth: '430px',
  },
}))(Popover);

type Props = {
  isOpened: boolean;
  onClose: () => void;
  anchorEl?: Element;
};

export const SupportPopover = ({
  isOpened,
  onClose,
  anchorEl,
  children,
}: PropsWithChildren<Props>) => {
  const classes = useStyles();

  return (
    <StyledMuiPopover
      open={isOpened}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={onClose}
      marginThreshold={40}
    >
      <Grid
        container
        spacing={2}
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h3">Support</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={onClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      {children}
    </StyledMuiPopover>
  );
};
