import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { projectApiRef, ProjectsApi } from './api';
import {
  rootRouteRef,
  projectPageRouteRef,
  pendingRequestPageRouteRef,
  profilePageRouteRef,
  projectGroupRouteRef,
  projectResourceRouteRef,
  projectGroupSharingRouteRef,
  projectContractPageRouteRef,
  assignUpdateContractPageRouteRef,
} from './routes';

export const projectsPlugin = createPlugin({
  id: 'projects',
  routes: {
    root: rootRouteRef,
    // articlePage: projectPageRouteRef,
  },
  apis: [
    createApiFactory({
      api: projectApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ configApi, identityApi }) =>
        new ProjectsApi({ configApi, identityApi }),
    }),
  ],
});

export const ProjectsPage = projectsPlugin.provide(
  createRoutableExtension({
    name: 'ProjectsPage',
    component: () =>
      import('./pages/ProjectPage/ProjectPage').then(m => m.ProjectPage),
    mountPoint: rootRouteRef,
  }),
);

export const ProjectDetailsPage = projectsPlugin.provide(
  createRoutableExtension({
    name: 'ProjectDetails',
    component: () =>
      import('./pages/ProjectDetails').then(m => m.ProjectDetailsPage),
    mountPoint: projectPageRouteRef,
  }),
);

export const pendingRequestPage = projectsPlugin.provide(
  createRoutableExtension({
    name: 'PendingRequest',
    component: () =>
      import('./components/PendingRequest').then(m => m.PendingRequestPage),
    mountPoint: pendingRequestPageRouteRef,
  }),
);

export const ViewUserGroups3 = projectsPlugin.provide(
  createRoutableExtension({
    name: 'ViewUserGroups3',
    component: () =>
      import('./components/ViewUserGroups3').then(m => m.ViewUserGroups3),
    mountPoint: projectGroupRouteRef,
  }),
);

export const ViewEditResource = projectsPlugin.provide(
  createRoutableExtension({
    name: 'ViewEditResources',
    component: () =>
      import('./components/ViewEditResources').then(
        m => m.ViewEditResourcePage,
      ),
    mountPoint: projectResourceRouteRef,
  }),
);

export const UserManagmentDetailsPage = projectsPlugin.provide(
  createRoutableExtension({
    name: 'UserManagmentDetails',
    component: () =>
      import('./pages/UserManagmentDetails').then(
        m => m.UserManagmentDetailsPage,
      ),
    mountPoint: projectPageRouteRef,
  }),
);

export const ProfilePage = projectsPlugin.provide(
  createRoutableExtension({
    name: 'ProfilePage',
    component: () =>
      import('./components/ProfilePage').then(m => m.ProfilePage),
    mountPoint: profilePageRouteRef,
  }),
);

export const UserGroupSharingSettings = projectsPlugin.provide(
  createRoutableExtension({
    name: 'UserGroupSharingSettings',
    component: () =>
      import('./components/UserGroupSharingSettings').then(
        m => m.UserGroupSharingSettings,
      ),
    mountPoint: projectGroupSharingRouteRef,
  }),
);

export const ProjectContractsPage = projectsPlugin.provide(
  createRoutableExtension({
    name: 'ProjectContracts',
    component: () =>
      import('./components/ProjectContracts').then(m => m.ProjectContractsPage),
    mountPoint: projectContractPageRouteRef,
  }),
);

export const AssignUpdateProjectContractPage = projectsPlugin.provide(
  createRoutableExtension({
    name: 'AssignUpdateContract',
    component: () =>
      import('./components/AssignUpdateProjectContract').then(
        m => m.AssignUpdateProjectContract,
      ),
    mountPoint: assignUpdateContractPageRouteRef,
  }),
);
