import { Config } from '@backstage/config';
import { NotFoundError } from '@backstage/errors';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { generateHeaders } from 'sg-utils-frontend';
import { QuicksightEmbeddedUrl } from './types/type-definitions';

export const stargateInsightsApiRef = createApiRef<StargateInsightsInterface>({
  id: 'plugin.stargate-insights',
});

interface StargateInsightsInterface {
  getQuicksightEmbeddedUrl(
    params: getQuicksightEmbeddedUrlParams,
  ): Promise<QuicksightEmbeddedUrl>;
}

interface getQuicksightEmbeddedUrlParams {
  projectKey: string;
  dashboardName: string;
  ref?: string;
}

interface StargateInsightsApiOptions {
  configApi: Config;
  identityApi: IdentityApi;
  gacApi: any;
}

export class StargateInsightsApi implements StargateInsightsInterface {
  private configApi: Config;
  private identityApi: IdentityApi;
  private gacApi: any;
  private backendUrl: string;

  constructor(options: StargateInsightsApiOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
    this.gacApi = options.gacApi;
    this.backendUrl = this.configApi.getString('backend.baseUrl');
  }

  public async getQuicksightEmbeddedUrl(
    params: getQuicksightEmbeddedUrlParams,
  ): Promise<QuicksightEmbeddedUrl> {
    const tokenData = await this.identityApi.getCredentials();
    const token = tokenData?.token;
    const gacToken = await this.gacApi.getIdToken();
    const requestUrl = new URL(
      `${this.backendUrl}/api/stargate-insights/embedded-url/${params.dashboardName}/${params.projectKey}`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      {
        gacIdToken: gacToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }
}
