import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((_: USGTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  wrapperDiv: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 16,
  },
}));
