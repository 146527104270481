import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import createMixins from '@material-ui/core/styles/createMixins';
import { pageTheme as defaultPageThemes } from '@backstage/theme';
import {
  SimpleThemeOptions,
  USGTheme,
  USGThemeMixins,
  USGThemeOptions,
} from './types';
import {
  createUSGThemeOverrides,
  USGMixins,
  USGThemeProps,
  USGTypography,
} from './themeOverrides';
import { Breakpoints } from '@material-ui/core/styles/createBreakpoints';
import { Spacing } from '@material-ui/core/styles/createSpacing';

export function createThemeOptions(
  options: SimpleThemeOptions,
): USGThemeOptions {
  const {
    palette,
    fontFamily,
    defaultPageTheme,
    pageTheme = defaultPageThemes,
  } = options;

  if (!pageTheme[defaultPageTheme]) {
    throw new Error(`${defaultPageTheme} is not defined in pageTheme.`);
  }

  return {
    palette,
    props: USGThemeProps,
    typography: {
      fontFamily,
      ...USGTypography,
    },
    page: pageTheme[defaultPageTheme],
    getPageTheme: ({ themeId }) =>
      pageTheme[themeId] ?? pageTheme[defaultPageTheme],
  };
}

const createThemeMixins = (
  breakpoints: Breakpoints,
  spacing: Spacing,
): USGThemeMixins => {
  return {
    ...createMixins(breakpoints, spacing, {}),
    ...USGMixins,
  };
};

export function createTheme(options: SimpleThemeOptions): USGTheme {
  const themeOptions = createThemeOptions(options);
  const baseTheme = createMuiTheme(themeOptions) as USGTheme;
  const mixins = createThemeMixins(baseTheme.breakpoints, baseTheme.spacing);
  const overrides = createUSGThemeOverrides(baseTheme);
  const theme = { ...baseTheme, overrides, mixins };
  return theme;
}
