import { Config } from '@backstage/config';
import { IdentityApi, createApiRef } from '@backstage/core-plugin-api';
import { generateHeaders } from 'sg-utils-frontend';

export const serviceCatalogApiRef = createApiRef<ServiceCatalogApi>({
  id: 'plugin.catalog-manager',
});

type ServiceCatalogApiOptions = {
  configApi: Config;
  identityApi: IdentityApi;
  gacApi: any;
};

export interface ServiceCatalogApiInterface {
  registerService: (params: ServiceRegistrationParams) => Promise<Response>;
}

export class ServiceCatalogApi implements ServiceCatalogApiInterface {
  private configApi: Config;
  private identityApi: IdentityApi;
  private gacApi: any;
  private backendUrl: string;

  constructor(options: ServiceCatalogApiOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
    this.gacApi = options.gacApi;
    this.backendUrl = this.configApi.getString('backend.baseUrl');
  }

  public async registerService(
    params: ServiceRegistrationParams,
  ): Promise<Response> {
    const requestUrl = new URL(
      `${this.backendUrl}/api/catalog-manager/service`,
    );

    const token = (await this.identityApi.getCredentials()).token;
    const gacToken = await this.gacApi.getIdToken();

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: gacToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(params),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data?.error?.message || response.statusText);
    }
    return data;
  }
}
