import React from 'react';

import { useEntity } from '@backstage/plugin-catalog-react';
import { ComponentCard } from './ComponentCard';
import { useComponent } from '../hooks/useComponent';

export const StatuspageEntityCard = () => {
  const { entity } = useEntity();
  const componentId =
    entity.metadata.annotations?.['statuspage.io/component-id'] || null;

  const { loading, error, value: component } = useComponent(componentId);

  return (
    <ComponentCard component={component} isLoading={loading} error={error} />
  );
};
