import { newsPageRouteRef, homePageExternalRouteRef } from './routes';
import { NewsApi, newsApiRef } from './api';

import {
  configApiRef,
  createApiFactory,
  createPlugin,
  identityApiRef,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

export const newsPlugin = createPlugin({
  id: 'news',
  routes: {
    root: newsPageRouteRef,
  },
  externalRoutes: {
    home: homePageExternalRouteRef,
  },
  apis: [
    createApiFactory({
      api: newsApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ configApi, identityApi }) =>
        new NewsApi({ configApi, identityApi }),
    }),
  ],
});

export const NewsPage = newsPlugin.provide(
  createRoutableExtension({
    name: 'NewsPage',
    component: () => import('./pages/NewsPage').then(m => m.NewsPage),
    mountPoint: newsPageRouteRef,
  }),
);
