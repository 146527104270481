import React from 'react';
import { useEffect, useRef, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';

interface DownloadFileProps {
  readonly apiRequest: () => Promise<string>;
  readonly preDownload: () => void;
  readonly postDownload: () => void;
  readonly onError: (error: Error | undefined) => void;
  readonly getFileName: () => string;
}

interface DownloadedFileInfo {
  readonly download: () => Promise<void> | undefined;
  readonly ref: React.MutableRefObject<HTMLAnchorElement | null>;
  readonly filename: string | undefined;
  readonly url: string | undefined;
}

export const useDownloadFile = ({
  apiRequest,
  preDownload,
  postDownload,
  onError,
  getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [url, setFileUrl] = useState<string>();
  const [filename, setFileName] = useState<string>();

  const { value, loading, error } = useAsync(apiRequest, []);

  const download = async () => {
    try {
      preDownload();
      if (!loading) {
        let blob = new Blob(value !== undefined ? [value] : []);
        // setting new mime type as blob init above somehow turns it to text/plain
        blob = blob.slice(0, blob.size, 'application/yaml');
        const urlBlob = URL.createObjectURL(blob);
        setFileName(getFileName());
        setFileUrl(urlBlob);
        postDownload();
      }
    } catch (ex) {
      onError(Error('Encountered an unexpected error.'));
    }
  };

  useEffect(() => {
    if (url !== '') {
      ref.current?.click();
    }
  }, [url]);

  if (error) {
    onError(error);
  }

  return { download, ref, url, filename };
};
