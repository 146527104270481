import React from 'react';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { useEffectOnce } from 'react-use';
import * as Sentry from '@sentry/react';

const SentryInitializer = () => {
  const configApi = useApi(configApiRef);

  useEffectOnce(() => {
    const env = configApi.getOptionalString('sentry.environment') ?? 'local';

    if (env !== 'local') {
      Sentry.init({
        environment: env,
        dsn: configApi.getOptionalString('sentry.dsn'),
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
          }),
        ],
        tracesSampleRate:
          configApi.getOptionalNumber('sentry.tracesSampleRate') ?? 0.05,
        replaysSessionSampleRate:
          configApi.getOptionalNumber('sentry.replaysSessionSampleRate') ?? 0.1,
        replaysOnErrorSampleRate:
          configApi.getOptionalNumber('sentry.replaysOnErrorSampleRate') ?? 1.0,
      });
    }
  });

  return <></>;
};

export default SentryInitializer;
