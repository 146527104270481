import { Config } from '@backstage/config';
import { NewsMetadata, MarkdownCategory } from 'usg-types';
import { NotFoundError } from '@backstage/errors';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';

export const newsApiRef = createApiRef<NewsApiInterface>({
  id: 'plugin.news',
});

type NewsApiOptions = {
  configApi: Config;
  identityApi: IdentityApi;
};

interface NewsApiInterface {
  getNewsMetadata(): Promise<NewsMetadata[]>;
  getNewsContent(id: string): Promise<string>;
}

export class NewsApi implements NewsApiInterface {
  private configApi: Config;
  private identityApi: IdentityApi;

  private markdownCategory: MarkdownCategory.NEWS = MarkdownCategory.NEWS;

  constructor(options: NewsApiOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
  }

  async getNewsMetadata() {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = await (await this.identityApi.getCredentials()).token;
    const requestUrl = `${backendUrl}/api/markdown/${this.markdownCategory}/metadata`;
    const response = await fetch(requestUrl, {
      credentials: 'include',
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  async getNewsContent(id: string) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = await (await this.identityApi.getCredentials()).token;
    const requestUrl = `${backendUrl}/api/markdown/${this.markdownCategory}/content/${id}`;
    const response = await fetch(requestUrl, {
      credentials: 'include',
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return response.text();
  }
}
