import { makeStyles } from '@material-ui/core';

const useCustomDialogStyles = makeStyles(() => ({
  dialog: {
    position: 'absolute',
    left: 10,
    top: 50,
  },
  commonDialog: {
    position: 'absolute',
    left: 10,
    top: 50,
    '& .common-dialog-paper': {
      width: '634px',
      borderRadius: '4px',
      padding: '24px',
      margin: '0px',
      overflowY: 'visible',
    },
    '& .common-dialog-content': {
      height: 'auto',
      width: '586.97px',
      padding: '0px',
    },
    '& .common-dialog-actions': {
      marginTop: '24px',
      justifyContent: 'flex-end',
      rowGap: '16px',
    },
  },
  copyText: {
    background: '#F3F3F4',
    padding: '20px',
    borderRadius: '10px',
    fontSize: '14px',
    fontWeight: 400,
    border: '1px solid #dddde5',
  },
  copyBtn: {
    float: 'right',
    bottom: 40,
    marginLeft: 24,
  },
  closeBtn: {
    float: 'right',
    margin: '20px 16px',
    cursor: 'pointer',
  },
  commonDialogActions: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default useCustomDialogStyles;
