import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useStyles } from '../../../styles';

type DialogArchiveConfirmationProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onConfirm: () => Promise<void>;
};

export const DialogArchiveConfirmation = ({
  isOpen,
  setIsOpen,
  onConfirm,
}: DialogArchiveConfirmationProps) => {
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      maxWidth="sm"
      style={{ top: '5' }}
      className={classes.dialog}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginX: 2 }}>
          Confirm Archive
        </Typography>
      </DialogTitle>
      <IconButton
        onClick={() => setIsOpen(!isOpen)}
        sx={theme => ({
          position: 'absolute',
          right: 12,
          top: 12,
          color: theme.palette.grey[800],
        })}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <DialogContent
        id="vault-environment-archive-confirmation-dialog"
        style={{ fontWeight: 'bold' }}
      >
        <Typography variant="body1" sx={{ marginX: 1, marginY: 1 }}>
          Are you sure you want to archive this environment?
        </Typography>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'flex-start',
          marginLeft: 20,
          marginBottom: 10,
        }}
      >
        <Button
          variant="outlined"
          size="small"
          onClick={() => setIsOpen(!isOpen)}
          id="dialog-vault-environment-cancel-button"
        >
          cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={async () => {
            setIsOpen(!isOpen);
            await onConfirm();
          }}
          id="dialog-vault-environment-archive-button"
        >
          confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
