import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  alertMsg: {
    padding: '7px',
    justifyContent: 'center',
    margin: '5px 0px',
  },
  alertSubHeading: {
    fontSize: '14px',
    fontWeight: 400,
  },
}));
