import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'dev-forums-frontend',
});
export const questionPageRouteRef = createRouteRef({
  id: 'dev-forums-frontend-question',
  params: ['questionId'],
});
export const questionCreateRouteRef = createRouteRef({
  id: 'dev-forums-frontend-question-create',
});
export const questionEditRouteRef = createRouteRef({
  id: 'dev-forums-frontend-question-edit',
});
