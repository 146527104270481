import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import React from 'react';
import { ARTIFACTORY_SAAS_DEV_TOOL_ID } from 'usg-types';
import { useStyles } from '../../styles';
import { DialogResourceErrorMessageProps } from '../types';

export const DialogResourceErrorMessage = ({
  devToolId,
  openEditSucessMsg,
  fetchContent,
  setEditMode,
  setOpenEditSucessMsg,
  fetchResourceManagers,
  userEmail,
}: DialogResourceErrorMessageProps) => {
  const classes = useStyles();

  const resourceManagerErrorMessages = () => {
    switch (devToolId) {
      case ARTIFACTORY_SAAS_DEV_TOOL_ID:
        return (
          <div>
            Resource update request submitted, please wait 2hrs whilst we update
            it to your project workspace.
          </div>
        );
      default:
        return (
          <div>
            Resource update request submitted, please wait whilst we update it
            to your project workspace.
          </div>
        );
    }
  };

  const handleClose = () => {
    setOpenEditSucessMsg(false);
    setEditMode(false);
    fetchContent();
    fetchResourceManagers(userEmail, 0);
  };

  return (
    <Dialog
      open={openEditSucessMsg}
      onClose={handleClose}
      maxWidth="xs"
      style={{ top: '5' }}
      className={classes.dialog}
    >
      <DialogContent
        id="resource-update-confirmed-dialog"
        style={{ fontWeight: 'bold' }}
      >
        {resourceManagerErrorMessages()}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          id="dialog-close-button"
          variant="contained"
          size="small"
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
