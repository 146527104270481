import React, { ReactNode } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import { GridSize, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      padding: '8px',
      marginRight: '4px',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  }),
);

export type OverviewItemGroup = {
  name: string;
  size?: GridSize;
  renderItems?: (index: number) => OverviewItem[];
  items?: OverviewItem[];
  actionsHidden?: boolean;
};

export type OverviewItem = {
  name?: string;
  values?: Array<string>;
  valueComponent?: ReactNode;
  emptyDescription?: string;
  hasErrors: boolean;
  description?: string;
  editComponent: ReactNode;
};

export type OverviewCardProps = {
  id: string;
  items: OverviewItem[];
  onEdit?: () => void;
  onDescription?: () => void;
  actionsDisabled?: boolean;
  actionsHidden?: boolean;
  children?: React.ReactNode;
};

export default function OverviewCard(props: OverviewCardProps) {
  const classes = useStyles();

  const {
    id,
    items,
    onEdit,
    onDescription,
    actionsDisabled,
    actionsHidden,
    children,
  } = props;

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getItem = (item: OverviewItem) => {
    if (!!item.values && item.values?.length > 0) {
      return (
        <>
          {item.values.map((value, i) => (
            <pre key={i}>
              <code>{value}</code>
            </pre>
          ))}
        </>
      );
    } else if (!!item.valueComponent) {
      return item.valueComponent;
    }

    return (
      <>
        <pre>
          <code>not configured</code>
        </pre>
      </>
    );
  };

  return (
    <Card variant="outlined" id={id}>
      {items.length <= 0 ? (
        <CardContent />
      ) : (
        items.map((item, i) => (
          <CardContent
            key={i}
            className={`overview-item ${
              i >= items.length - 1 ? 'last-item' : ''
            }`}
          >
            {!!item.name && (
              <Typography
                variant="subtitle1"
                color={item.hasErrors ? 'error' : 'textSecondary'}
              >
                {item.name}
              </Typography>
            )}
            {getItem(item)}
            {!!item.emptyDescription &&
            (!!!item.values || item.values.length <= 0) &&
            !!!item.valueComponent ? (
              <Typography variant="overline" component="p" color="error">
                {item.emptyDescription}
              </Typography>
            ) : (
              <Typography
                variant="overline"
                component="p"
                color="textSecondary"
              >
                {item.description}
              </Typography>
            )}
          </CardContent>
        ))
      )}
      {!actionsHidden && (!!onEdit || !!onDescription || !!children) && (
        <CardActions disableSpacing>
          {!!onEdit && (
            <Button
              variant="contained"
              color="primary"
              aria-label="edit"
              onClick={onEdit}
              startIcon={<SettingsTwoToneIcon />}
              disabled={actionsDisabled}
            >
              Modify
            </Button>
          )}
          {!!onDescription && (
            <Tooltip title="Read more">
              <IconButton
                onClick={onDescription}
                aria-label="description"
                disabled={actionsDisabled}
              >
                <InfoTwoToneIcon />
              </IconButton>
            </Tooltip>
          )}

          {!!children && (
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          )}
        </CardActions>
      )}
      {!!children && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>{!!children && children}</CardContent>
        </Collapse>
      )}
    </Card>
  );
}
