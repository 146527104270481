import * as React from 'react';

import stc from 'string-to-color';

import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import ContentCopy from '@mui/icons-material/ContentCopy';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getFirstTwoLetters } from 'sg-utils-frontend';
import { useStyles } from './styles';

/**
 * This is not yet supported with current Material UI V4.
 * Once we migrate to Material UI v5, then this is usable.
 * DRAFT version.
 */
type SGPeopleAvatarGroupValuesType = { value: string; stringForShade: string };
export interface PeopleAvatarProps {
  /**
   * List of strings that can be shown as 2 characters in the avatar. <br/><br/>
   * Examples: <br/>
   * - withoneword@email.com -> WI <br/>
   * - with.twowords@email.com -> WT <br/>
   * - with.three.words@email.com -> WT <br/>
   * - John Smith -> JS <br/>
   * - JohnSmith -> J <br/>
   */
  values: SGPeopleAvatarGroupValuesType[];

  /**
   * Displays the text as capitalized.  Otherwise, it is lower cased.
   */
  capitalized?: boolean;

  /**
   * Max avatars to show before +x.
   */
  max?: number;

  /**
   * Displays the expand button to show more details about the people avatar group.
   */
  expand?: boolean;

  /**
   * Color is assigned based on the string but close to this color shading.
   */
  shade?: string;

  /**
   * Shows an icon that can make the value copyable to clipboard.
   */
  copyable?: boolean;
  title: string;
}

export const SGPeopleAvatarGroup = ({
  values = [],
  capitalized = true,
  max = 5,
  expand = false,
  shade = '',
  copyable = false,
  title = '',
}: PeopleAvatarProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const open = Boolean(anchorEl);

  const getAvatarBgColor = (
    stringForShade: string,
    shadeOfColor: string | undefined,
  ) => {
    if (shade) {
      return stc(`${stringForShade} ${shadeOfColor}`);
    }
    return stc(`${stringForShade}`);
  };

  return (
    <>
      <Grid
        container
        xs={12}
        justifyContent="flex-end"
        item
        sx={{
          margin: 0,
        }}
      >
        <AvatarGroup max={max}>
          {values.map(
            (element: SGPeopleAvatarGroupValuesType, index: number) => {
              return (
                <Avatar
                  key={index}
                  sx={{
                    bgcolor: getAvatarBgColor(element.stringForShade, shade),
                    fontFamily: 'ToyotaType',
                  }}
                >
                  {getFirstTwoLetters(element.value, capitalized)}
                </Avatar>
              );
            },
          )}
        </AvatarGroup>
        {values?.length > 0 && expand && (
          <Button
            variant="text"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setAnchorEl(event.currentTarget);
            }}
            className={classes.popoverDownButton}
          >
            <ExpandMoreIcon />
          </Button>
        )}
      </Grid>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        sx={{
          marginTop: '8px',
        }}
      >
        <List>
          <ListItem disableGutters className={classes.title}>
            {title}
          </ListItem>
          <Divider className={classes.divider} />
          {values.map((element, index) => {
            return (
              <ListItem disableGutters key={index}>
                <ListItem className={classes.listItemMain}>
                  <ListItemAvatar>
                    <Avatar
                      key={index}
                      sx={{
                        bgcolor: getAvatarBgColor(
                          element.stringForShade,
                          shade,
                        ),
                        fontFamily: 'ToyotaType',
                      }}
                    >
                      {getFirstTwoLetters(element.value, capitalized)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    <Typography
                      fontFamily="ToyotaType"
                      style={{ wordBreak: 'break-word' }}
                    >
                      {element.value}
                    </Typography>
                  </ListItemText>
                  {copyable && (
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(element.value);
                      }}
                    >
                      <ContentCopy className={classes.contentCopy} />
                    </Button>
                  )}
                </ListItem>
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
};
