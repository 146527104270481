import type { Transformer } from './transformer';

export const updateLastViewed = (): Transformer => {
  return dom => {
    const headerEle = dom.querySelector('h1');
    const contentEle = dom.querySelectorAll('p');

    if (!headerEle || !contentEle) {
      return dom;
    }

    const max = 4;
    const key = 'techdocRecentlyViewed';
    const header = headerEle.innerText;
    const content = Array.from(contentEle).find(
      ele => ele.innerText,
    )?.innerText;
    const pathname = window.location.pathname;
    const recentlyViewed = JSON.parse(localStorage.getItem(key) || '[]');

    if (recentlyViewed.some((entry: any) => entry.header === header)) {
      const existingEntryIndex = recentlyViewed.findIndex(
        (entry: any) => entry.header === header,
      );
      const existingEntry = recentlyViewed.splice(existingEntryIndex, 1)[0];
      recentlyViewed.unshift(existingEntry);
      localStorage.setItem(key, JSON.stringify(recentlyViewed));
      return dom;
    }
    // remove the first entry if the list is full
    if (recentlyViewed.length === max) {
      recentlyViewed.pop();
    }

    const newEntry = { header, content, pathname };
    const newRecentlyViewed = [newEntry, ...recentlyViewed];
    localStorage.setItem(key, JSON.stringify(newRecentlyViewed));
    return dom;
  };
};
