import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';

import { Progress } from '@backstage/core-components';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { IconButton, Link, Paper } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { projectApiRef } from '../../../../../api';
import { DialogArchiveConfirmation } from './DialogArchiveConfirmation';
import { DialogUnArchiveConfirmation } from './DialogUnArchiveConfirmation';
import { SuccessBar } from '../../Snackbar/Success';
import { ENVIRONMENTS, LinkedResource, VaultViewEditTabProps } from './types';
import { VaultResourceManagers } from './VaultResourceManagers';
import { VaultTable } from './VaultTable';
import { VaultTabs } from './VaultTabs';
import { VAULT_DEV_TOOL_ID } from 'usg-types';
import { useStyles } from './styles';

export const VaultViewEditTab = ({
  projectId,
  resourceData,
  fetchContent,
  attachableUserGroups,
  setIsContentVisible,
  isContentVisible,
  isAdmin,
  isProjectOwner,
  isResourceManager,
  isResourceCreator,
  resourceManagerData,
  isAddUserEnable,
  setIsAddUserEnable,
  setIsAddUserLoading,
  resourceManagerList,
  fetchResourceManagers,
  userEmail,
  refresh,
  value,
  setValue,
}: VaultViewEditTabProps) => {
  const classes = useStyles();
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);
  const authref = useApi(microsoftAuthApiRef);

  const [linkedResource, setLinkedResource] = useState<LinkedResource>({
    id: 0,
    url: '',
    environment: '',
  });
  const [isUrlCopied, setIsUrlCopied] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [isSuccessBoxOpen, setIsSuccessBoxOpen] = useState<boolean>(false);
  const [successBoxMsg, setSuccessBoxMsg] = useState<string>(
    'Environment has been archived.',
  );
  const [isOpenArchiveConfirmationDialog, setIsOpenArchiveConfirmationDialog] =
    useState<boolean>(false);

  const [
    isOpenUnArchiveConfirmationDialog,
    setIsOpenUnArchiveConfirmationDialog,
  ] = useState<boolean>(false);
  const [isArchived, setIsArchived] = useState<boolean>(false);

  useEffect(() => {
    if (resourceData.linkedResources[value]) {
      setLinkedResource(resourceData.linkedResources[value]);
      if (resourceData.linkedResources[value]?.deleted_on) {
        setIsArchived(true);
      } else {
        setIsArchived(false);
      }
    }

    let val = 0;
    const localStorageVal = localStorage.getItem(
      'vault_environment_lastselectedtab',
    );

    const linkResources = resourceData.linkedResources ?? [];
    if (localStorageVal && linkResources.length > 0) {
      const environments = linkResources.map(
        (item: { environment: string }) =>
          ENVIRONMENTS.filter(i => i.name === item.environment)[0],
      );
      for (let i = 0; i < environments.length; i++) {
        if (environments[i].name === localStorageVal) {
          val = i;
        }
      }
      setValue(val);
      localStorage.removeItem('vault_environment_lastselectedtab');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, resourceData.linkedResources]);

  useEffect(() => {
    (async () => {
      if (userEmail) {
        setIsContentVisible(true);
        await fetchResourceManagers(
          userEmail,
          resourceData.linkedResources[value].id,
        );
        setIsContentVisible(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, resourceData.linkedResources]);

  const handleChange = async (_event: any, newValue: number, formik: any) => {
    formik.resetForm({});
    setValue(newValue);
    setIsEdit(false);
  };

  const handleUnArchiveDialog = async () => {
    const idToken = await authref.getIdToken();
    const data: any = await projectApi.unArchiveResources(
      projectId,
      Number(resourceData.linkedResources[value].id),
      {
        idToken: idToken,
      },
    );
    const code = data?.response?.status;
    if (code === 200) {
      setIsContentVisible(true);
      setIsSuccessBoxOpen(true);
      setSuccessBoxMsg('Environment has been unarchived.');
      await fetchContent();
      setIsContentVisible(false);
    } else if (code >= 400) {
      const errorMsg = data?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
    }
  };

  const onSaveResource = async (values: any, formik: any) => {
    const inputEmails = [];
    for (const email of values.addResourceManagers) {
      inputEmails.push({ user_email: email.value.trim() });
    }
    const idToken = await authref.getIdToken();
    const resourceManagersparams = {
      resource_managers: inputEmails,
    };

    const addResourceData: any = await projectApi.addGroupManagers(
      projectId,
      resourceData.linkedResources[value].id,
      idToken,
      resourceManagersparams,
    );
    const code = addResourceData?.status;
    if (code === 200 || code === 201) {
      setIsContentVisible(true);
      setIsSuccessBoxOpen(true);
      setIsEdit(false);
      formik.resetForm({});
      setSuccessBoxMsg('Resource managers successfully updated');
      await fetchResourceManagers(
        userEmail,
        resourceData.linkedResources[value].id,
      );
      await refresh();
      setIsContentVisible(false);
    } else if (code >= 400) {
      const errorMsg = addResourceData?.data?.error?.message;
      errorApi.post(new Error(`${errorMsg}`));
    }
  };
  const disableEditResourceManager = (resourceID: any) => {
    if (!isProjectOwner(Number(projectId)) && !isAdmin) {
      return !isResourceManager(Number(resourceID));
    }

    return false;
  };
  const isResourceManagerOfAnyEnv = () => {
    let isAnyResourceManager = false;
    if (resourceData.linkedResources?.length) {
      for (let i = 0; i < resourceData.linkedResources.length; i++) {
        if (isResourceManager(Number(resourceData.linkedResources[i].id))) {
          isAnyResourceManager = true;
        }
      }
      return isAnyResourceManager;
    }
    return false;
  };

  const resourceIdForCreatingNewEnv = () => {
    let rId = resourceData.linkedResources[0].id;
    for (let i = 0; i < resourceData.linkedResources?.length; i++) {
      if (isResourceManager(Number(resourceData.linkedResources[i].id))) {
        rId = Number(resourceData.linkedResources[i].id);
      }
    }
    return rId;
  };
  const isAddEnvEnabled = () => {
    if (
      isProjectOwner(Number(projectId)) ||
      isAdmin ||
      (isResourceCreator(Number(projectId), VAULT_DEV_TOOL_ID) &&
        isResourceManagerOfAnyEnv())
    ) {
      return true;
    }
    return false;
  };

  const disableGroupListChanges = (resourceID: any) => {
    if (!isProjectOwner(Number(projectId)) && !isAdmin) {
      return !isResourceManager(Number(resourceID));
    }

    return false;
  };
  const onHandleArchive = async () => {
    const idToken = await authref.getIdToken();
    const data: any = await projectApi.archiveProjectResource(
      projectId,
      resourceData.linkedResources[value].id,
      {
        idToken: idToken,
      },
    );
    const code = data?.response?.status;
    if (code === 200) {
      setIsContentVisible(true);
      setIsSuccessBoxOpen(true);
      setSuccessBoxMsg('Environment has been archived.');
      await fetchContent();
      setIsContentVisible(false);
    } else if (code >= 400) {
      const errorMsg = data?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
    }
  };

  const displayTopRightButtons = (formik: any) => {
    if (isEdit) {
      return (
        <Stack direction="row" sx={{ mt: 0.5 }}>
          <Button
            variant="text"
            onClick={() => {
              formik.resetForm({});
              setIsEdit(false);
            }}
          >
            <Typography
              variant="button"
              color="textPrimary"
              sx={{ fontWeight: 'bold' }}
            >
              Cancel
            </Typography>
          </Button>
          <Divider
            orientation="vertical"
            variant="inset"
            flexItem
            sx={{ mr: 2, ml: 2 }}
          />
          <Button variant="text" disabled={!isAddUserEnable}>
            <Typography
              variant="button"
              sx={{ fontWeight: 'bold' }}
              onClick={() => {
                return onSaveResource(formik.values, formik);
              }}
              className={
                isAddUserEnable ? classes.enabledBtn : classes.disabledBtn
              }
            >
              Save
            </Typography>
          </Button>
        </Stack>
      );
    }

    return (
      <Stack direction="row" sx={{ mt: 0.5 }}>
        {!isArchived && (
          <Button
            variant="text"
            onClick={() => setIsOpenArchiveConfirmationDialog(true)}
            disabled={!isAdmin}
          >
            <Typography
              variant="button"
              sx={{ fontWeight: 'bold' }}
              className={isAdmin ? classes.enabledBtn : classes.disabledBtn}
            >
              Archive
            </Typography>
          </Button>
        )}
        {isArchived && (
          <Button
            variant="text"
            onClick={() => setIsOpenUnArchiveConfirmationDialog(true)}
            disabled={!isAdmin}
          >
            <Typography
              variant="button"
              sx={{ fontWeight: 'bold' }}
              className={isAdmin ? classes.enabledBtn : classes.disabledBtn}
            >
              Unarchive
            </Typography>
          </Button>
        )}

        <Divider
          orientation="vertical"
          variant="inset"
          flexItem
          sx={{ mr: 2, ml: 2 }}
        />

        <Button
          variant="text"
          disabled={
            isArchived ||
            disableEditResourceManager(resourceData.linkedResources[value].id)
          }
          onClick={() => setIsEdit(true)}
        >
          <Typography
            variant="button"
            sx={{ fontWeight: 'bold' }}
            className={
              !(
                isArchived ||
                disableEditResourceManager(
                  resourceData.linkedResources[value].id,
                )
              )
                ? classes.enabledBtn
                : classes.disabledBtn
            }
          >
            Edit
          </Typography>
        </Button>
      </Stack>
    );
  };

  return (
    <Paper>
      <Stack sx={{ pt: 2, pb: 2, ml: 3, mr: 3 }} spacing={0}>
        <Typography variant="h6" fontWeight="bold">
          Environments
        </Typography>
        <Formik
          initialValues={{
            addResourceManagers: resourceManagerList,
          }}
          onSubmit={() => {}}
          enableReinitialize
        >
          {formik => {
            return (
              <>
                <Form onKeyDown={e => e.key === 'Enter' && e.preventDefault()}>
                  <>
                    <VaultTabs
                      onChange={(e, newValue) => {
                        handleChange(e, newValue, formik);
                      }}
                      value={value}
                      formik={formik}
                      setIsEdit={setIsEdit}
                      resourceData={resourceData}
                      fetchContent={fetchContent}
                      projectId={projectId}
                      setIsContentVisible={setIsContentVisible}
                      isAddEnvEnabled={isAddEnvEnabled()}
                      resourceIdForCreatingNewEnv={resourceIdForCreatingNewEnv}
                    />
                    {isContentVisible ? <Progress /> : <></>}
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="baseline"
                    >
                      <Grid item>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          alignItems="flex-start"
                        >
                          <Grid item sx={{ mt: 1, mr: 2 }}>
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 'bold' }}
                            >
                              Link:
                            </Typography>
                          </Grid>
                          <Grid item sx={{ mt: 1 }}>
                            {linkedResource.url ? (
                              <Link
                                underline="always"
                                href={linkedResource.url}
                              >
                                <Typography variant="body1">
                                  {linkedResource.url}
                                </Typography>
                              </Link>
                            ) : (
                              <Typography
                                variant="body1"
                                sx={{ marginTop: 0, marginBottom: '12px' }}
                              >
                                No link for this environment
                              </Typography>
                            )}
                          </Grid>
                          {linkedResource.url && (
                            <Grid item sx={{ ml: 1 }}>
                              {isUrlCopied ? (
                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: 'bold', mt: 1, mb: 1.5 }}
                                  color="primary"
                                >
                                  Copied!
                                </Typography>
                              ) : (
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      `${window.location.protocol}//${window.location.hostname}/projects/${projectId}?resource=${linkedResource.id}&path=`,
                                    );
                                    setIsUrlCopied(true);
                                    setTimeout(() => {
                                      setIsUrlCopied(false);
                                    }, 2000);
                                  }}
                                >
                                  <ContentCopyIcon fontSize="small" />
                                </IconButton>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item>{displayTopRightButtons(formik)}</Grid>
                    </Grid>
                    <VaultResourceManagers
                      projectId={projectId}
                      editMode={isEdit}
                      resourceManagerData={resourceManagerData}
                      isAddUserEnable={isAddUserEnable}
                      setIsAddUserEnable={setIsAddUserEnable}
                      setIsAddUserLoading={setIsAddUserLoading}
                    />
                  </>
                </Form>
              </>
            );
          }}
        </Formik>
        {isArchived ? (
          <Alert severity="info">
            This environment has been archived. Group list is not available for
            archived resources.
          </Alert>
        ) : (
          <VaultTable
            resourceData={resourceData}
            attachableUserGroups={attachableUserGroups}
            projectId={projectId}
            selectedTabIndex={value}
            disableGroupListChanges={disableGroupListChanges(
              resourceData.linkedResources[value].id,
            )}
          />
        )}
      </Stack>
      <DialogArchiveConfirmation
        isOpen={isOpenArchiveConfirmationDialog}
        setIsOpen={setIsOpenArchiveConfirmationDialog}
        onConfirm={onHandleArchive}
      />
      <DialogUnArchiveConfirmation
        isOpen={isOpenUnArchiveConfirmationDialog}
        setIsOpen={setIsOpenUnArchiveConfirmationDialog}
        onConfirm={handleUnArchiveDialog}
      />
      <SuccessBar
        isOpen={isSuccessBoxOpen}
        setIsSuccessBoxOpen={setIsSuccessBoxOpen}
      >
        <Grid container>
          <Grid item xs={1}>
            <CheckCircleIcon className={classes.successBoxIcon} />
          </Grid>
          <Grid item className={classes.successBoxMsgItem} xs={11}>
            <Typography
              variant="h5"
              className={classes.successBoxHeading}
              sx={{ fontWeight: 'bold' }}
            >
              Success!
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.successBoxSubHeading}
            >
              {successBoxMsg}
            </Typography>
          </Grid>
        </Grid>
      </SuccessBar>
    </Paper>
  );
};
