import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  emailItem: {
    background: 'rgba(16, 89, 205, 0.2)',
    color: 'rgba(14, 66, 149, 1)',
    borderRadius: '12px',
    padding: '6px 14px 10px !important',
    fontSize: '12px',
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  mailsContainer: {
    alignItems: 'center',
  },

  resourceManagersEditModeContainer: {
    marginBottom: '40px',
  },
  resourceManagersNonEditModeContainer: {
    marginBottom: '10px',
  },
  grid20: {
    maxWidth: '20% !important',
    flexBasis: '20% !important',
  },
  resourceManagerHeadingContainer: {
    paddingBottom: '0 !important',
  },
  labelHeader: {
    marginBottom: '4px',
    marginTop: '24px',
  },
  noManagers: {
    marginTop: 0,
  },
  resourceManagerGrid: {
    width: '75% !important',
  },
}));
