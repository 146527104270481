import React from 'react';
import { TextField } from '@material-ui/core';
import {
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  RESOURCE_DESCRIPTION_MAX_LENGTH,
  SPECIAL_RESOURCE_NAME_REGEX,
} from 'usg-types';
import { transformResourceName } from 'sg-utils-frontend';
import { ArtifactoryGitHubWorkflowAccessEditTable } from '../artifactory-github-workflow-access/ArtifactoryGitHubWorkflowAccessEditTable';
import { GenricResourcePropsType } from './Types';

type specificPropsType = {
  dataData: any;
  nameDirty: boolean;
  setNameDirty: React.Dispatch<React.SetStateAction<boolean>>;
  descriptionDirty: boolean;
  onDescriptionClick: () => void;
  showArtifactoryGithubWorkflowAccess: boolean;
  artifactoryProperties: any;
  setArtifactoryProperties: React.Dispatch<any>;
};

const ArtifactoryViewEdit = (
  props: React.PropsWithChildren<GenricResourcePropsType<specificPropsType>>,
) => {
  const { eventType, resourceData, formik, specificProps, classes } = props;
  const {
    nameDirty,
    setNameDirty,
    descriptionDirty,
    onDescriptionClick,
    showArtifactoryGithubWorkflowAccess,
    artifactoryProperties,
    setArtifactoryProperties,
  } = specificProps;

  const viewCase = (
    <>
      <h4 className={classes.labelHeader}>
        {transformResourceName('artifactory')} Repo Key
      </h4>
      <p id="resource-key-value" className={classes.viewTag}>
        {resourceData?.key}
      </p>
      <h4 className={classes.labelHeader}>
        {transformResourceName('artifactory')} Repo Name
      </h4>
      <p id="resource-name-value" className={classes.viewTag}>
        {resourceData?.name}
      </p>
      {resourceData?.description ? (
        <>
          <h4 className={classes.labelHeader}>Description</h4>
          <p id="resource-name-description" className={classes.viewTag}>
            {resourceData?.description ? resourceData.description : ''}
          </p>
        </>
      ) : null}
      {resourceData.config && Object.keys(resourceData.config).length > 0 ? (
        <>
          <h4 className={classes.labelHeader}>Config</h4>
          <div style={{ paddingLeft: '24px' }}>
            <h4 className={classes.labelHeader}>Package Type</h4>
            {resourceData.config.packageType ? (
              <p className={classes.viewTag}>
                {resourceData.config.packageType}
              </p>
            ) : (
              'NA'
            )}
            <h4 className={classes.labelHeader}>R Class</h4>
            {resourceData.config.rclass ? (
              <p className={classes.viewTag}>{resourceData.config.rclass}</p>
            ) : (
              'NA'
            )}
          </div>
        </>
      ) : null}
    </>
  );

  const editCase = (
    <>
      <h4 className={classes.labelHeader}>
        {transformResourceName('artifactory')} Repo Key
      </h4>
      <p className={classes.viewTag}>{resourceData?.key}</p>
      <h4 className={classes.labelHeader}>
        {transformResourceName('artifactory')} Repo Name*
      </h4>
      <TextField
        style={{ paddingBottom: '0%' }}
        type="text"
        id="resourceName"
        name="resourceName"
        required
        error={
          (formik.values.resourceName.length <
            RESOURCE_NAME_MIN_LENGTH.artifactory &&
            nameDirty === true) ||
          (formik.values.resourceName.length >
            RESOURCE_NAME_MAX_LENGTH.artifactory &&
            nameDirty === true) ||
          (!formik.values.resourceName.match(
            SPECIAL_RESOURCE_NAME_REGEX.artifactory,
          ) &&
            nameDirty === true)
        }
        onChange={formik.handleChange}
        value={formik.values.resourceName}
        onClick={() => setNameDirty(true)}
        fullWidth
        helperText={`Provide a unique name for your ${transformResourceName(
          'artifactory',
        )} repo. Only letters, numbers and single hyphens allowed. Must not end with hyphen and cache (-cache). No. of characters allowed Min=${
          RESOURCE_NAME_MIN_LENGTH.artifactory
        }, Max=${RESOURCE_NAME_MAX_LENGTH.artifactory}`}
      />
      <h4 className={classes.labelHeader}>Description</h4>
      <TextField
        id="resource-description"
        name="resourceDescription"
        type="text"
        onChange={formik.handleChange}
        value={formik.values.resourceDescription}
        minRows={3}
        multiline
        fullWidth
        error={
          formik.values.resourceDescription.length >
            RESOURCE_DESCRIPTION_MAX_LENGTH && descriptionDirty
        }
        helperText={`Provide a brief description for your Artifactory repo. Number of characters allowed Max = ${RESOURCE_DESCRIPTION_MAX_LENGTH}`}
        placeholder="Example : Repository for storing the artifacts for development within the One-click delivery automation solutions project."
        onClick={onDescriptionClick}
      />
      {resourceData.config && Object.keys(resourceData.config).length > 0 ? (
        <>
          <h4 className={classes.labelHeader}>Config</h4>
          <div style={{ paddingLeft: '24px' }}>
            <h4 className={classes.labelHeader}>Package Type</h4>
            {resourceData.config.packageType ? (
              <p className={classes.viewTag}>
                {resourceData.config.packageType}
              </p>
            ) : (
              'NA'
            )}
            <h4 className={classes.labelHeader}>R Class</h4>
            {resourceData.config.rclass ? (
              <p className={classes.viewTag}>{resourceData.config.rclass}</p>
            ) : (
              'NA'
            )}
          </div>
        </>
      ) : null}
      {showArtifactoryGithubWorkflowAccess && (
        <ArtifactoryGitHubWorkflowAccessEditTable
          name={resourceData?.name}
          artifactoryProperties={artifactoryProperties}
          setArtifactoryProperties={setArtifactoryProperties}
        />
      )}
    </>
  );

  switch (eventType) {
    case 'edit':
      return editCase;
    default:
      return viewCase;
  }
};

export default ArtifactoryViewEdit;
