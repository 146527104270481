import React, { useEffect, useState } from 'react';
import { CodeSnippet, LinkButton, Progress } from '@backstage/core-components';
import { Tabs, Tab } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { NamespaceTable } from '../Namespaces';
import { NamespacesResponse } from '../../fetcher';
import { definitions } from '../../api';

type ApplicationResponse = definitions['handlers.ApplicationResponse'];

export type PreviewProps = {
  applicationResponse: ApplicationResponse;
  loading: boolean;
  error: Error | undefined;
};

export const Preview = (props: PreviewProps) => {
  const { applicationResponse, loading, error } = props;
  const [tabId, setTabId] = useState<number>(0);
  const [namespaces, setNamespaces] = useState<NamespacesResponse[]>([]);
  const [code, setCode] = useState<string[]>(['', '']);

  const getNamespaces = (appResp: ApplicationResponse) => {
    const namespacesResp: NamespacesResponse[] = [];

    if (error || !appResp || Object.keys(appResp).length === 0) {
      return namespacesResp;
    }

    for (const cluster of appResp.active_clusters) {
      for (const env of Object.keys(appResp.configuration)) {
        if (
          (env === appResp.configuration_base_key &&
            cluster.infra_env !== 'apps-prod') ||
          (env !== appResp.configuration_base_key &&
            cluster.infra_env === 'apps-prod')
        ) {
          continue;
        }
        namespacesResp.push({
          application_name: appResp.app_name,
          cluster_name: cluster.id,
          namespace_name: `${appResp.app_name}-${env}`,
          state: appResp.status,
        } as NamespacesResponse);
      }
    }

    return namespacesResp;
  };

  const getCodeSyntax = (appResp: ApplicationResponse) => {
    let payload: string = '';
    let language: string = '';
    try {
      payload = JSON.stringify(appResp || '{}', null, 4);
      language = 'json';
    } catch {
      payload = '';
      language = 'text';
    }

    return [payload, language];
  };

  useEffect(
    () => {
      setNamespaces(getNamespaces(applicationResponse));
      setCode(getCodeSyntax(applicationResponse));
      return;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [applicationResponse],
  );

  let progressEL = <></>;
  if (loading) {
    progressEL = <Progress />;
  }

  let contentEl = <></>;
  if (tabId === 0) {
    contentEl = (
      <NamespaceTable namespaces={namespaces} showActions={false} showSearch />
    );
  }

  if (tabId === 1) {
    contentEl = <CodeSnippet text={code[0]} language={code[1]} />;
  }

  const slackChannelUrl =
    'https://toyotaglobal.enterprise.slack.com/archives/C02JB3YLR1U';
  const errorEl = (
    <Alert
      severity="error"
      key="errorEl"
      style={{ marginTop: 10, overflow: 'scroll' }}
    >
      Unfortunately, there was an error when updating your application.
      <CodeSnippet
        text={JSON.stringify(error, null, 2)}
        language="json"
        showCopyCodeButton
      />
      Please provide the above error to the Stargate Multicloud team in Slack
      and we will help!
      <br />
      <br />
      <LinkButton color="secondary" variant="contained" to={slackChannelUrl}>
        Get help on Slack
      </LinkButton>
    </Alert>
  );

  return (
    <div id="mtfuji_wizard_preview">
      <Tabs value={tabId} onChange={(_, id: number) => setTabId(id)}>
        <Tab label="Summary" />
        <Tab label="Code" />
      </Tabs>
      {progressEL}
      {contentEl}
      {error ? errorEl : <></>}
    </div>
  );
};
