import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  identityApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';

import {
  catalogItemRouteRef,
  marketplaceGitHubItemRouteRef,
  marketplaceImageItemRouteRef,
  marketplacePageRouteRef,
  rootRouteRef,
} from './routes';
import { ActionsCatalogApi, actionsCatalogApiRef } from './api';

export const actionsCatalogPlugin = createPlugin({
  id: 'actions-catalog',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: actionsCatalogApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
        gacApi: microsoftAuthApiRef,
      },
      factory: ({ configApi, identityApi, gacApi }) =>
        new ActionsCatalogApi({ configApi, identityApi, gacApi }),
    }),
  ],
});

export const ActionsCatalogPage = actionsCatalogPlugin.provide(
  createRoutableExtension({
    name: 'ActionsCatalogPage',
    component: () =>
      import('./pages/ActionsCatalogPage').then(m => m.ActionsCatalogPage),
    mountPoint: rootRouteRef,
  }),
);

export const ActionsCatalogItemPage = actionsCatalogPlugin.provide(
  createRoutableExtension({
    name: 'ActionsCatalogItemPage',
    component: () =>
      import('./pages/ActionsCatalogItemPage').then(
        m => m.ActionsCatalogItemPage,
      ),
    mountPoint: catalogItemRouteRef,
  }),
);

export const MarketplaceGitHubItemPage = actionsCatalogPlugin.provide(
  createRoutableExtension({
    name: 'MarketplaceGitHubItemPage',
    component: () =>
      import('./pages/MarketplaceItemPage').then(m => m.MarketplaceItemPage),
    mountPoint: marketplaceGitHubItemRouteRef,
  }),
);

export const MarketplaceImageItemPage = actionsCatalogPlugin.provide(
  createRoutableExtension({
    name: 'MarketplaceImageItemPage',
    component: () =>
      import('./pages/MarketplaceItemPage').then(m => m.MarketplaceItemPage),
    mountPoint: marketplaceImageItemRouteRef,
  }),
);

export const MarketplacePage = actionsCatalogPlugin.provide(
  createRoutableExtension({
    name: 'MarketplacePage',
    component: () =>
      import('./pages/MarketplacePage').then(m => m.MarketplacePage),
    mountPoint: marketplacePageRouteRef,
  }),
);
