import { has } from 'lodash';

export function getRoles(data: any) {
  const roles: any[] = [];
  data.filter((i: any) => {
    // if expired_on is null or undefined or not present, role has infinite expiry date
    if (
      !has(i, 'expired_on') ||
      i.expired_on === null ||
      i.expired_on === undefined
    ) {
      return roles.push(i?.role);
    }
    if (i?.expired_on && new Date() > new Date(i?.expired_on)) {
      return false;
    }
    return roles.push(i?.role);
  });
  return roles;
}

/**
 * returns true if response status is 2XX successful response
 * @param status Status number
 */
export function isResponseStatus2XX(status: number) {
  return status >= 200 && status < 300;
}
