import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';
import CloseIcon from '@material-ui/icons/Close';
import { projectApiRef } from '../../api';
import { useAsyncFn } from 'react-use';
import { useApi, microsoftAuthApiRef } from '@backstage/core-plugin-api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  IconButton,
  DialogTitle,
  Typography,
} from '@material-ui/core';

export const DetachUserGroup = (props: {
  isVisible: boolean;
  handleClose: any;
  projectId: string;
  groupId: string;
  groupName: string;
  navigate: any;
}) => {
  const { isVisible, handleClose, projectId, groupId, groupName, navigate } =
    props;

  const classes = useStyles();
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const [detachError, setDetachError] = useState('');

  const [{ loading: reqLoading = false as boolean }, detachUserGroup] =
    useAsyncFn(async () => {
      const res = (await authref.getIdToken().then(async (token: any) => {
        const authToken = {
          idToken: token,
        };

        // detach api for userGroup
        return await projectApi.detachUserGroup(authToken, projectId, groupId);
      })) as any;

      const code = res?.response?.status;
      if (code === 200) {
        // close modal
        handleClose();
        navigate(`/projects/${projectId}`);
      } else {
        setDetachError(res?.response?.data?.message);
      }
    }, []);

  useEffect(() => {
    setTimeout(() => {
      setDetachError('');
    }, 1000);
  }, [isVisible]);

  return (
    <Dialog open={isVisible} onClose={handleClose}>
      <DialogTitle>
        <div className={classes.dialogHeader}>
          Detach User Group
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
            arrow
            placement="top"
            title="Close Button"
          >
            <IconButton
              aria-label="close"
              className={classes.iconContainer}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent>
        Do you want to detach <b>“{groupName}”</b> from your project?
        <br /> You’ll be detaching this user group from this project, all access
        to the resources attached may be lost for its members.
        {detachError && (
          <Typography
            style={{ marginTop: '8px', marginBottom: '8px', color: 'red' }}
          >
            Error: {detachError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          size="small"
          onClick={handleClose}
          disabled={reqLoading}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => detachUserGroup()}
          disabled={reqLoading}
        >
          DETACH User Group
        </Button>
      </DialogActions>
    </Dialog>
  );
};
