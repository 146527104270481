import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'rover',
});

export const onBoardingRouteRef = createSubRouteRef({
  id: 'onboarding',
  parent: rootRouteRef,
  path: '/onboarding',
});

export const uiRouteRef = createSubRouteRef({
  id: 'ui',
  parent: rootRouteRef,
  path: '/ui',
});
