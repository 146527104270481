import { InfoContent } from '@backstage/plugin-devtools';
import { DevToolsLayout } from '@backstage/plugin-devtools';
import React, { useEffect, useState } from 'react';
import { BuildInfo } from './BuildInfo';
import { usePermissions } from '@internal/plugin-projects';
import { Progress } from '@backstage/core-components';
import { UnprocessedEntitiesContent } from '@backstage/plugin-catalog-unprocessed-entities';

export const DevToolsPage = () => {
  const { isAnyAdmin, isLoading: rolesCheckLoading } = usePermissions();
  const [showDevTools, setShowDevTools] = useState(false);

  useEffect(() => {
    setShowDevTools(isAnyAdmin);
  }, [isAnyAdmin]);

  if (rolesCheckLoading) {
    return <Progress />;
  }

  return (
    <DevToolsLayout title="DevTools">
      {showDevTools ? (
        <>
          <DevToolsLayout.Route path="info" title="Info">
            <div>
              <BuildInfo />
              <InfoContent />
            </div>
          </DevToolsLayout.Route>
          <DevToolsLayout.Route
            path="unprocessed-entities"
            title="Unprocessed Entities"
          >
            <UnprocessedEntitiesContent />
          </DevToolsLayout.Route>
          {/* <DevToolsLayout.Route path="config" title="Config">
            <ConfigContent />
          </DevToolsLayout.Route> */}
        </>
      ) : (
        <DevToolsLayout.Route path="info" title="Info">
          <div>
            <h2>Permission Denied</h2>
            <p>You need to have admin role to view this page!</p>
          </div>
        </DevToolsLayout.Route>
      )}
    </DevToolsLayout>
  );
};

export const customDevToolsPage = <DevToolsPage />;
