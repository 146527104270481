import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles<any>(theme => ({
  link: {
    display: 'inline-block',

    '&:hover': {
      textDecoration: 'none',
    },
  },
  text: {
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
