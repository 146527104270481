import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogActions: {
    justifyContent: 'right',
    paddingBottom: '20px',
    marginRight: '16px',
  },
  dialogContent: {
    wordBreak: 'break-word',
  },
  iconContainer: {
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '1px',
    paddingRight: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '26px',
  },
  tooltip: {
    color: '#000000',
    backgroundColor: '#E6E6E6',
  },
  tooltipArrow: {
    color: '#E6E6E6',
  },
}));
