import React from 'react';
import { Table, TableColumn } from '@backstage/core-components';
import EditIcon from '@material-ui/icons/Edit';
import { AppEnvKey, AppEnvs, ApplicationEnvValue } from './types';
import {
  appEnvValueString,
  isAppEnvValueEmpty,
  isHiddenEnvironmentConfigKey,
  isOptionalEnvironmentConfigKey,
  normalizeName,
} from './FormHelpers';
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AvailableEnvironmentEdit from './AvailableEnvironmentEdit';

type EditCallback = (
  envVarName: string,
  envName: string,
  mainEnvName: string,
) => void;

type EnvironmentConfigurationProps = {
  appEnvs: AppEnvs;
  mainEnvName?: string;
  enableEditableButton?: boolean;
  onEdit?: EditCallback;
  onEnvChange?: (appEnvs: AppEnvs, mainEnvName: string) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    envValueButton: {
      position: 'relative',
      display: 'block',
      width: '100%',
      minHeight: '24px',
      lineHeight: '24px',
      border: 'none',
      background: 'transparent',
      margin: '0 0 0 -5px',
      padding: '0 5px',
      boxSizig: 'border-box',
      textAlign: 'left',
      borderRadius: 5,
      cursor: 'pointer',

      '& .edit-icon': {
        width: '1em',
        height: '1em',
        position: 'absolute',
        right: '-1.1em',
        top: 0,
        display: 'none',
      },

      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        '& .edit-icon': {
          display: 'inherit',
        },
      },

      '&:disabled': {
        cursor: 'default',
        opacity: 0.75,

        '&:hover': {
          backgroundColor: 'transparent',
          '.edit-icon': {
            display: 'none',
          },
        },
      },
    },
    errorBox: {
      marginTop: 10,
    },
  }),
);

export function EnvironmentValueTableCell({
  rowData,
  envName,
  mainEnvName,
  disabled,
  onEdit,
}: {
  rowData: any;
  envName: string;
  mainEnvName: string;
  disabled?: boolean;
  onEdit?: EditCallback;
}) {
  const classes = useStyles();

  const handleClick = () => {
    if (onEdit && !disabled) {
      onEdit(rowData.name, envName, mainEnvName);
    }
  };

  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      className={classes.envValueButton}
    >
      {appEnvValueString(rowData[envName] || '')}
      <span className="edit-icon">
        <EditIcon />
      </span>
    </button>
  );
}

function errorMessagesForAppEnvs(
  appEnvs: AppEnvs,
  mainEnvName: string,
): string[] {
  const mainEnvs = appEnvs[mainEnvName] as {
    [key: string]: ApplicationEnvValue;
  };
  if (mainEnvs === undefined || Object.keys(mainEnvs).length === 0) {
    return [
      `Environment configurations for the ${normalizeName(
        mainEnvName,
      )} are empty`,
    ];
  }
  return Object.keys(mainEnvs)
    .filter(
      envVarName =>
        !isOptionalEnvironmentConfigKey(envVarName) &&
        !isHiddenEnvironmentConfigKey(envVarName) &&
        isAppEnvValueEmpty(mainEnvs[envVarName]),
    )
    .map(
      envVarName =>
        `Empty "${envVarName}" value in the ${normalizeName(
          mainEnvName,
        )} environment`,
    );
}

export default function EnvironmentConfiguration({
  appEnvs,
  mainEnvName,
  enableEditableButton,
  onEdit,
  onEnvChange,
}: EnvironmentConfigurationProps) {
  const classes = useStyles();

  const [envEditOpen, setEnvEditOpen] = React.useState(false);

  if (!mainEnvName) {
    return (
      <Typography variant="overline" component="p" color="error">
        Main environment could not be determined
      </Typography>
    );
  }

  const handleEnvChange = (envs: AppEnvs, mainEnv: string) => {
    if (onEnvChange) {
      onEnvChange(envs, mainEnv);
    }
    setEnvEditOpen(false);
  };

  const envNames = envNamesFromAppEnvs(appEnvs, mainEnvName);
  const envVarNames = envVarNamesFromAppEnvs(appEnvs, envNames);

  const columns: TableColumn[] = envNames.reduce(
    (col, envName) => {
      return [
        ...col,
        {
          title: envName,
          field: envName,
          render: (row: any) => (
            <EnvironmentValueTableCell
              rowData={row}
              envName={envName}
              mainEnvName={mainEnvName}
              onEdit={onEdit}
              disabled={!enableEditableButton}
            />
          ),
        },
      ];
    },
    [{ title: 'Name', field: 'name' }],
  );

  const tableData: any[] = envVarNames
    .filter(key => !isHiddenEnvironmentConfigKey(key))
    .map(envVarName =>
      envNames.reduce(
        (row, envName) => {
          const envValues = (appEnvs[envName] || {}) as {
            [key in AppEnvKey]?: ApplicationEnvValue;
          };
          if (Object.hasOwn(envValues, envVarName)) {
            return { ...row, [envName]: envValues[envVarName] };
          }
          const mainEnvValues = (appEnvs[mainEnvName] || {}) as {
            [key in AppEnvKey]?: ApplicationEnvValue;
          };
          return { ...row, [envName]: mainEnvValues[envVarName] || '' };
        },
        { id: envVarName, name: envVarName } as any,
      ),
    );

  const errorMessages = errorMessagesForAppEnvs(appEnvs, mainEnvName);

  return (
    <>
      <Table
        columns={columns}
        data={tableData as {}[]}
        options={{
          paging: false,
          filtering: false,
          search: false,
          toolbar: undefined,
        }}
      />
      {errorMessages.length > 0 && (
        <Box className={classes.errorBox}>
          {errorMessages.map(errorMessage => (
            <Typography variant="overline" component="p" color="error">
              {errorMessage}
            </Typography>
          ))}
        </Box>
      )}
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setEnvEditOpen(true)}
        >
          Edit environments
        </Button>
      </Box>
      <AvailableEnvironmentEdit
        appEnvs={appEnvs}
        open={envEditOpen}
        mainEnvName={mainEnvName}
        onApply={handleEnvChange}
        onClose={() => setEnvEditOpen(false)}
      />
    </>
  );
}

function envNamesFromAppEnvs(
  appEnvs: AppEnvs,
  configurationBaseKey: string,
): string[] {
  const envs = Object.keys(appEnvs);
  envs.sort((e1, e2) => {
    if (e1 === e2) {
      return 0;
    }
    if (e1 === configurationBaseKey) {
      return -1;
    }
    if (e2 === configurationBaseKey) {
      return 1;
    }
    return e1 < e2 ? -1 : 1;
  });
  return envs;
}

function envVarNamesFromAppEnvs(appEnvs: AppEnvs, envs: string[]): AppEnvKey[] {
  const envVarNames: AppEnvKey[] = [];
  envs.forEach(env => {
    const envVars = appEnvs[env];
    if (envVars !== undefined) {
      Object.keys(envVars).forEach(rawEnvVarName => {
        const envVarName = rawEnvVarName as AppEnvKey;
        if (envVarNames.indexOf(envVarName) < 0) {
          envVarNames.push(envVarName);
        }
      });
    }
  });
  envVarNames.sort();
  return envVarNames;
}
