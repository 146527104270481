import React from 'react';
import {
  Paper,
  Typography,
  Grid,
  InputLabel,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  RESOURCE_KEY_MIN_LENGTH,
  RESOURCE_KEY_MAX_LENGTH,
  RESOURCE_NAME_REGEX,
  RESOURCE_DESCRIPTION_MAX_LENGTH,
} from 'usg-types';
import { useStyles } from '../styles';

interface Props {
  onResourceSubmit: (values: any) => Promise<void>;
  keyDirty: boolean;
  projectData: any;
  onResourceKeyClick: () => void;
  nameDirty: boolean;
  onResourceNameClick: () => void;
  descriptionDirty: boolean;
  onDescriptionClick: () => void;
  projectTemplates: string[];
  apiLoader: boolean;
  handleCreateResource: () => void;
}

export const CreateJiraResourceForm = ({
  onResourceSubmit,
  keyDirty,
  projectData,
  onResourceKeyClick,
  nameDirty,
  onResourceNameClick,
  descriptionDirty,
  onDescriptionClick,
  projectTemplates,
  apiLoader,
  handleCreateResource,
}: Props) => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Formik
        initialValues={{
          jira_project_key: projectData?.key ?? '',
          jira_project_name: '',
          jira_project_description: '',
          project_template: '',
        }}
        onSubmit={onResourceSubmit}
      >
        {formik => (
          <Form>
            <Paper className={classes.formContainer}>
              <Grid item xs={6} className={classes.selectedResourceItem}>
                <Typography
                  variant="subtitle2"
                  className={classes.selectedResourceText}
                >
                  Jira Project
                </Typography>
                <InputLabel
                  htmlFor="jira_project_key"
                  style={{
                    color:
                      (formik.values.jira_project_key.length < 1 &&
                        keyDirty === true) ||
                      (formik.values.jira_project_key.length > 10 &&
                        keyDirty === true)
                        ? 'red'
                        : 'black',
                  }}
                  className={classes.inputLabelText}
                >
                  Jira Project Key*
                </InputLabel>
                <TextField
                  style={{ paddingBottom: '0%' }}
                  type="text"
                  id="jira_project_key"
                  name="jira_project_key"
                  required
                  error={
                    (formik.values.jira_project_key.length <
                      RESOURCE_KEY_MIN_LENGTH.jira &&
                      keyDirty === true) ||
                    !formik.values.jira_project_key
                      .toUpperCase()
                      .match('^[A-Z0-9]*$') ||
                    (formik.values.jira_project_key.length >
                      RESOURCE_KEY_MAX_LENGTH.jira &&
                      keyDirty === true)
                  }
                  onChange={formik.handleChange}
                  value={formik.values.jira_project_key.toUpperCase()}
                  onClick={onResourceKeyClick}
                  fullWidth
                  helperText={`Provide a key for your Jira Project. Only capital letters and numbers allowed. No. of characters allowed Min=${RESOURCE_KEY_MIN_LENGTH.jira}, Max=${RESOURCE_KEY_MAX_LENGTH.jira}. Hyphens(-) are not allowed.`}
                />

                <br />
                <br />
                <InputLabel
                  htmlFor="jira_project_name"
                  style={{
                    color:
                      (formik.values.jira_project_name.length < 3 &&
                        nameDirty === true) ||
                      (formik.values.jira_project_name.length >
                        RESOURCE_NAME_MAX_LENGTH.jira &&
                        nameDirty === true)
                        ? 'red'
                        : 'black',
                  }}
                  className={classes.inputLabelText}
                >
                  Jira Project Name*
                </InputLabel>
                <TextField
                  style={{ paddingBottom: '0%' }}
                  type="text"
                  id="jira_project_name"
                  name="jira_project_name"
                  required
                  error={
                    (formik.values.jira_project_name.length <
                      RESOURCE_NAME_MIN_LENGTH.jira &&
                      nameDirty === true) ||
                    (!formik.values.jira_project_name.match(
                      RESOURCE_NAME_REGEX,
                    ) &&
                      nameDirty) ||
                    (formik.values.jira_project_name.length >
                      RESOURCE_NAME_MAX_LENGTH.jira &&
                      nameDirty === true)
                  }
                  onChange={formik.handleChange}
                  value={formik.values.jira_project_name}
                  onClick={onResourceNameClick}
                  fullWidth
                  helperText={`Provide a unique name for your Jira Project. Only letters, numbers and spaces allowed. No. of characters allowed Min=${RESOURCE_NAME_MIN_LENGTH.jira}, Max=${RESOURCE_NAME_MAX_LENGTH.jira}. Spaces at start and end are not allowed.`}
                />

                <br />
                <br />
                <InputLabel
                  htmlFor="jira_project_description"
                  className={classes.inputLabelText}
                >
                  Description
                </InputLabel>
                <TextField
                  type="text"
                  id="jira_project_description"
                  name="jira_project_description"
                  onChange={formik.handleChange}
                  value={formik.values.jira_project_description}
                  minRows={4}
                  multiline
                  fullWidth
                  error={
                    formik.values.jira_project_description.length >
                      RESOURCE_DESCRIPTION_MAX_LENGTH && descriptionDirty
                  }
                  helperText={`Provide a brief description for your Jira Project. Number of characters allowed Max = ${RESOURCE_DESCRIPTION_MAX_LENGTH}`}
                  placeholder="Example : The Jira Project will be used to track the milestones for TPM related activities"
                  onClick={onDescriptionClick}
                />
                <br />
                <br />
                <InputLabel
                  style={{ marginBottom: '18px' }}
                  className={classes.inputLabelText}
                >
                  Config *
                </InputLabel>
                <div style={{ paddingLeft: '24px' }}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="project_template">
                      Project Template
                    </InputLabel>
                    <Select
                      id="project_template"
                      name="project_template"
                      value={formik.values.project_template}
                      input={<OutlinedInput label="Tag" />}
                      onChange={formik.handleChange}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {projectTemplates.map((value: any) => {
                        return (
                          <MenuItem
                            value={value}
                            style={{ textTransform: 'capitalize' }}
                            key={value}
                          >
                            {value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Paper>
            <Paper className={classes.btnContainer}>
              <Grid item xs={12} className={classes.btnItem}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      type="button"
                      id="cancel-jira"
                      variant="outlined"
                      onClick={handleCreateResource}
                      className={classes.newBtnOutlined}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      id="submit-jira"
                      disabled={
                        formik.values.jira_project_name.length <
                          RESOURCE_NAME_MIN_LENGTH.jira ||
                        formik.values.jira_project_key.length <
                          RESOURCE_KEY_MIN_LENGTH.jira ||
                        formik.values.jira_project_key.length >
                          RESOURCE_KEY_MAX_LENGTH.jira ||
                        !formik.values.jira_project_key
                          .toUpperCase()
                          .match('^[A-Z0-9]*$') ||
                        !formik.values.jira_project_name.match(
                          RESOURCE_NAME_REGEX,
                        ) ||
                        formik.values.jira_project_name.length >
                          RESOURCE_NAME_MAX_LENGTH.jira ||
                        apiLoader ||
                        formik.values.project_template === '' ||
                        formik.values.jira_project_description.length >
                          RESOURCE_DESCRIPTION_MAX_LENGTH
                      }
                      variant="contained"
                      className={classes.newBtnContained}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
