import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  mainContainer: {
    width: 'calc(100% - 80px)',
    margin: '0 40px',
  },
  newBtnOutlined: {
    color: theme.palette.usgColors.secondary.blue,
    backgroundColor: theme.palette.usgColors.primary.white,
    boxShadow: 'none',
    border: `2px solid ${theme.palette.usgColors.secondary.blue}`,
    '&:hover': {
      color: theme.palette.usgColors.primary.white,
      backgroundColor: theme.palette.usgColors.primary.blue,
      border: `2px solid ${theme.palette.usgColors.primary.blue}`,
    },
  },
  newBtnContained: {
    '&:hover': {
      color: theme.palette.usgColors.primary.white,
      backgroundColor: theme.palette.usgColors.primary.blue,
    },
  },
  formContainer: {
    padding: '16px 16px 24px 16px',
    width: 'calc(100% + 16px)',
    margin: '-8px',
    marginBottom: '1px',
  },
  container: {
    padding: theme.spacing(5),
    minHeight: 'calc(100vh - 310px)',
  },
  selectResourceContainer: {
    background: theme.palette.usgColors.primary.white,
    padding: '24px !important',
    width: '100%',
  },
  displayFormContainer: {
    background: theme.palette.usgColors?.secondary.lightGray,
    marginTop: '16px',
  },

  headerDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      color: theme.palette.usgColors?.primary.blue,
    },
  },
  support: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  artifactoryOwnerAlertText: {
    lineHeight: '16px',
    fontSize: '12px',
    marginTop: '10px',
    '& a': {
      cursor: 'pointer',
      fontWeight: 700,
    },
  },
  hyperLink: {
    color: theme.palette.usgColors?.primary.blue,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  resourceList: {
    width: 'auto',
    height: 'auto',
    paddingRight: '3.5%',
  },
  resourceGray: {
    width: 'auto',
    height: 'auto',
    paddingRight: '3%',
    color: '#8b8b8b',
    display: 'flex',
    alignItems: 'center',
  },
  btnContainer: {
    background: theme.palette.usgColors?.secondary.lightGray,
    width: 'calc(100% + 16px)',
    margin: '-8px',
    marginBottom: '8px',
    marginTop: '16px',
  },
  selectResourceItem: {
    paddingTop: '0 !important',
  },
  selectResourceText: {
    fontSize: '18px',
    lineHeight: '32px',
    fontWeight: 600,
  },
  inputLabelText: {
    fontSize: '14px',
    fontWeight: 700,
  },
  selectedResourceText: {
    fontSize: '18px',
    lineHeight: '32px',
    fontWeight: 600,
    paddingBottom: '16px',
  },
  createResourceItem: {
    padding: '19px 0 16px 0 !important',
  },
  selectedResourceItem: {
    paddingTop: '0 !important',
  },

  btnItem: {
    background: theme.palette.usgColors.primary.white,
    padding: '16px 24px !important',
    borderRadius: '4px',
  },
  enviField: {
    paddingBottom: '12px',
  },
  comingSoon: {
    fontSize: 12,
  },
  accordion: {
    display: 'block',
  },
  cursor: {
    cursor: 'default !important',
  },
  formControl: {
    marginBottom: '20px',
  },
  infoIcon: {
    fontSize: '19px',
  },
  hyperlink: {
    color: '#0D6AFF',
    textDecoration: 'underline',
  },
  infoDiv: {
    display: 'flex',
    marginBottom: '10px',
    '& svg': {
      marginRight: '5px',
      color: '#1976d2',
    },
  },
}));
