import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import {
  useController,
  UseControllerProps,
  FieldValues,
} from 'react-hook-form';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { definitions } from '../../api';
import { ARTIFACTORY_SAAS_DEV_TOOL_ID } from 'usg-types';
import {
  ARTIFACTORY_SAAS_NON_PROD_HOST,
  ARTIFACTORY_SAAS_PROD_HOST,
  HIDDEN_ENVIRONMENT_CONFIG_KEYS,
  OPTIONAL_ENVIRONMENT_CONFIG_KEYS,
} from './Constants';
import { isEmpty, isNil } from 'lodash';
import {
  AADGroup,
  ApplicationEnv,
  ApplicationEnvValue,
  ApplicationRequestProps,
  ArtifactoryRegistry,
  KeyValue,
  ServiceAccountAdmin,
} from './types';

// Schemas
type ApplicationGithubRepository = definitions['handlers.GitHubRepository'];

type NamedObject = { name: string };

interface ControlFieldProps<T extends FieldValues>
  extends UseControllerProps<T> {
  label?: React.ReactNode;
  placeholder: string;
  helperText?: React.ReactNode;
  InputProps?: Partial<StandardInputProps>;
}

export const StyledTextField = (props: TextFieldProps) => {
  const { label, placeholder, helperText, ...rest } = props;

  return (
    <TextField
      label={label}
      placeholder={placeholder}
      helperText={helperText}
      fullWidth
      variant="outlined"
      margin="dense"
      {...rest}
    />
  );
};

type ControlTextFieldProps<T extends FieldValues> = ControlFieldProps<T> &
  TextFieldProps;

export const ControlTextField = (
  props: ControlTextFieldProps<ApplicationRequestProps>,
) => {
  const { name, control, rules, ...rest } = props;
  const {
    field,
    fieldState: { error },
  } = useController({ name: name, control: control, rules: rules });

  return (
    <StyledTextField
      {...field}
      ref={null}
      error={error ? true : false}
      {...rest}
    />
  );
};

type ControlAutocompleteProps<
  TS extends FieldValues,
  TO,
> = UseControllerProps<TS> &
  AutocompleteProps<TO, boolean, undefined, undefined>;

export const ControlAutocomplete = (
  props: ControlAutocompleteProps<ApplicationRequestProps, any>,
) => {
  const {
    multiple,
    options,
    filterSelectedOptions,
    getOptionLabel,
    filterOptions,
    renderOption,
    getOptionSelected,
    disabled,
    loading,
    renderInput,
    ...rest
  } = props;
  const {
    field: { onChange, onBlur, ref, value },
  } = useController(props);

  return (
    <Autocomplete
      onChange={(_, data) => onChange(data)}
      onBlur={onBlur}
      ref={ref}
      value={value}
      loading={loading}
      disabled={disabled}
      multiple={multiple}
      options={options}
      filterSelectedOptions={filterSelectedOptions}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      renderOption={renderOption}
      getOptionSelected={getOptionSelected}
      renderInput={renderInput}
      {...rest}
    />
  );
};

export const getRepo = (repo: ApplicationGithubRepository) => {
  if (
    !repo ||
    !repo.owner ||
    !repo.name ||
    repo.owner === '' ||
    repo.name === ''
  ) {
    return '';
  }
  return `${repo.owner}/${repo.name}`;
};

export const getRepoHTTPS = (repo: ApplicationGithubRepository) => {
  if (!repo || !repo.host || repo.host === '') {
    return '';
  }
  return `https://${repo.host}/${getRepo(repo)}`;
};

export const getArtifactoryRegistryFQDN = (
  artifactory_repository: ArtifactoryRegistry,
) => {
  if (
    !artifactory_repository ||
    !artifactory_repository.key ||
    artifactory_repository.key === ''
  ) {
    return '';
  }

  if (artifactory_repository.dev_tool_id === ARTIFACTORY_SAAS_DEV_TOOL_ID) {
    return `${artifactory_repository.host}/${artifactory_repository.key}`;
  }

  return `${artifactory_repository.key}.${artifactory_repository.host}`;
};

export const isMatchArtifactorySaaSHost = (host: string) => {
  const pattern = new RegExp(
    String.raw`(${ARTIFACTORY_SAAS_PROD_HOST}|${ARTIFACTORY_SAAS_NON_PROD_HOST})`,
  );

  return pattern.test(host);
};

export const isEmptyOrNil = (item: any) => {
  return isEmpty(item) || isNil(item);
};

export const valueForVarNameAndEnv = (
  applicationEnvs: { [key: string]: ApplicationEnv },
  varName?: string,
  envName?: string,
) => {
  if (!varName || !envName) {
    return undefined;
  }
  return (
    (applicationEnvs[envName] || {}) as { [key: string]: ApplicationEnvValue }
  )[varName];
};

export const isAADGroup = (value: any): boolean => {
  return (
    typeof value === 'object' &&
    (value satisfies AADGroup) &&
    value.name !== undefined &&
    value.uuid !== undefined
  );
};

export const isServiceAccountAdmin = (value: any): boolean => {
  return (
    typeof value === 'object' &&
    (value satisfies ServiceAccountAdmin) &&
    value.name !== undefined &&
    value.namespace !== undefined
  );
};

export const isKeyValue = (value: any): boolean => {
  return typeof value === 'object' && (value satisfies KeyValue);
};

export const isString = (value: any): boolean => {
  return typeof value === 'string';
};

export const isNamedObject = (value: any): boolean => {
  return typeof value === 'object' && Object.hasOwn(value, 'name');
};

export const appEnvValueString = (value: ApplicationEnvValue): string => {
  if (Array.isArray(value)) {
    return value
      .map(item => {
        if (isString(item)) {
          return item;
        } else if (isServiceAccountAdmin(item)) {
          const serviceAccountAdmin = item as ServiceAccountAdmin;
          return [serviceAccountAdmin.namespace, serviceAccountAdmin.name]
            .filter(Boolean)
            .join('/');
        } else if (isNamedObject(item)) {
          const namedObject = item as NamedObject;
          return namedObject.name;
        }
        return item.toString();
      })
      .join(', ');
  }
  if (isString(value)) {
    return value as string;
  }
  if (isNamedObject(value)) {
    return (value as NamedObject).name;
  }
  if (isKeyValue(value)) {
    return Object.getOwnPropertyNames(value)
      .map(k => `${k}:${(value as KeyValue)[k]}`)
      .join(', ');
  }
  return (value as any).toString;
};

export const isAppEnvValueEmpty = (
  value: ApplicationEnvValue | undefined,
): boolean => {
  if (!value) {
    return true;
  }
  if (Array.isArray(value) || isString(value)) {
    return value.length === 0;
  }
  return Object.keys(value).length === 0;
};

export const normalizeName = (name: string | undefined): string => {
  if (!name) {
    return '';
  }
  return name
    .split(/[\-_]/)
    .map(
      part =>
        part.substring(0, 1).toUpperCase() + part.substring(1).toLowerCase(),
    )
    .join(' ');
};

export const isHiddenEnvironmentConfigKey = (configKey: string): boolean => {
  return HIDDEN_ENVIRONMENT_CONFIG_KEYS.includes(configKey);
};

export const isOptionalEnvironmentConfigKey = (configKey: string): boolean => {
  return OPTIONAL_ENVIRONMENT_CONFIG_KEYS.includes(configKey);
};
