import {
  createExternalRouteRef,
  createRouteRef,
} from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'feature-flags',
});

export const homePageExternalRouteRef = createExternalRouteRef({
  id: 'home',
});
