import {
  configApiRef,
  createApiFactory,
  createPlugin,
  identityApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { YakubiqueApi, yakubiqueApiRef } from './api';

export const yakubiquePlugin = createPlugin({
  id: 'yakubique',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: yakubiqueApiRef,

      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
        gacApi: microsoftAuthApiRef,
      },
      factory: ({ configApi, identityApi, gacApi }) => {
        return new YakubiqueApi({ configApi, identityApi, gacApi });
      },
    }),
  ],
});
