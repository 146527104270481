import React, { PropsWithChildren } from 'react';
import { List, ListItem } from '@material-ui/core';

import { useStyles } from './styles';

export const SupportTextList = ({ children }: PropsWithChildren<{}>) => {
  const classes = useStyles();

  return (
    <List className={classes.textList}>
      {React.Children.map(children, (child, i) => (
        <ListItem key={i} alignItems="flex-start" disableGutters>
          <div className={classes.listIcon} />
          {child}
        </ListItem>
      ))}
    </List>
  );
};
