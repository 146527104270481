import React from 'react';
import { microsoftAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { SGEmailChipInput } from '@internal/sg-ui-kit';
import { isResponseStatus2XX } from '@internal/sg-utils-common';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import { EMAIL_REGEX, INVALID_EMAIL_ERROR } from 'usg-types';
import { projectApiRef } from '../../../api';
import { ResourceManagersProps } from './types';
import { useStyles } from '../styles';

export const ResourceManagers = ({
  projectId,
  editMode,
  resourceManagerData,
  setIsAddUserEnable,
  isAddUserEnable,
  setIsAddUserLoading,
}: ResourceManagersProps) => {
  const classes = useStyles();
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);

  const checkUserValidity = async (email: string) => {
    try {
      const token = await authref.getIdToken();
      const isValidRes = await projectApi.checkIfUserIsValid(
        projectId,
        email,
        token,
      );
      return isResponseStatus2XX(isValidRes);
    } catch (e) {
      return false;
    }
  };

  const checkAddUserValidity = async (email: string) => {
    const isAddUserValid = await checkUserValidity(email);
    setIsAddUserEnable(isAddUserValid && isAddUserEnable);

    return isAddUserValid;
  };

  const ResourceManagerFieldView = () => {
    return (
      <>
        <h4 className={classes.labelHeader}>Resource Managers</h4>
        {resourceManagerData?.length === 0 && <p>Not Specified</p>}
        <p>
          <Grid container spacing={2}>
            {resourceManagerData?.map((res: any) => {
              return (
                <Grid item xs={5}>
                  {res.user_email}
                </Grid>
              );
            })}
          </Grid>
        </p>
      </>
    );
  };

  if (editMode) {
    return (
      <Grid>
        <h4 className={classes.labelHeader}>Resource Manager(s)</h4>
        <Field
          name="addResourceManagers"
          data-testid="resource-managers-field"
          className={classes.resourceManagerGrid}
          component={SGEmailChipInput}
          required
          variant="outlined"
          patternMatch={EMAIL_REGEX}
          helperText="Enter user emails to assign resource managers. Press
                enter or space key to add email id to the list. Press
                ‘Save’ to save the changes."
          errorText={INVALID_EMAIL_ERROR}
          checkValidity={checkAddUserValidity}
          handleDeleteCallBack={(isError: boolean) => {
            setIsAddUserEnable(!isError);
          }}
          handleBeforeAddCallBack={(isError: boolean) => {
            setIsAddUserEnable(!isError);
          }}
          handleLoadingCallBack={(addUserloading: boolean) => {
            setIsAddUserLoading(addUserloading);
          }}
          handleBlurInputCallBack={(isError: boolean) => {
            setIsAddUserEnable(!isError);
          }}
        />
      </Grid>
    );
  }

  return <ResourceManagerFieldView />;
};
