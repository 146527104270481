import {
  BackstageIdentityApi,
  ErrorApi,
  OAuthApi,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi,
} from '@backstage/core-plugin-api';
import { ProjectsApiInterface } from '../api';
import { NotFoundError } from '@backstage/errors';

type AuthRef = OAuthApi &
  OpenIdConnectApi &
  ProfileInfoApi &
  BackstageIdentityApi &
  SessionApi;

/**
 * Get users in a group.
 * @param {string} norenUUID - The Noren UUID.
 * @param {string} groupId - The group ID.
 * @returns {Promise<{ totalCount: number }>} - Total number of users in the group.
 */
export async function getUsersInGroup(
  projectApi: ProjectsApiInterface,
  errorApi: ErrorApi,
  norenUUID: string,
): Promise<{ totalCount: number }> {
  try {
    const pageSize = 20;
    const startIndex = 0;

    const getUserMemberListRes: any = await projectApi.getUserMemberList(
      norenUUID,
      pageSize,
      startIndex,
      'username',
      false,
    );

    if (getUserMemberListRes) {
      if (getUserMemberListRes.error) {
        throw new NotFoundError(getUserMemberListRes.error.message);
      }

      return {
        totalCount: getUserMemberListRes.total_results,
      };
    }
    return { totalCount: 0 };
  } catch (err: any) {
    errorApi.post(new Error(`Error fetching users: ${err.message}`));
    return { totalCount: 0 };
  }
}

/**
 * Get total user count in a group by group ID and project ID.
 * @param {string} groupId - The group ID.
 * @param {string} projectId - The project ID.
 * @returns {Promise<number>} - Total number of users in the group.
 */
export async function getTotalUserCountByGroupAndProject(
  projectApi: ProjectsApiInterface,
  authRef: AuthRef,
  errorApi: ErrorApi,
  groupId: string,
  projectId: string,
): Promise<number> {
  try {
    const idToken = await authRef.getIdToken();

    // Fetch group details
    const { data }: any = await projectApi.getGroupByIDFromProject(
      groupId.toString(),
      projectId,
      { idToken },
    );

    // The team has decided that if noren_uuid is undefined, we will not throw any error.
    if (data?.noren_uuid) {
      // Fetch users within the group
      const members = await getUsersInGroup(
        projectApi,
        errorApi,
        data.noren_uuid,
      );
      return members.totalCount;
    }

    return 0;
  } catch (err: any) {
    errorApi.post(new Error(`Error fetching members: ${err.message}`));
    return 0;
  }
}
