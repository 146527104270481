import React from 'react';
import { usgTheme } from 'usg-theme';
import { ROUTE_THEME } from 'usg-types';
import { useEffectOnce } from 'react-use';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import { useApi } from '@backstage/core-plugin-api';
import { CssBaseline, ThemeProvider } from '@material-ui/core';

interface RootElementCreatorProps {
  themeToUse: any;
  children: React.ReactNode;
}

export const RootElementCreator = ({
  themeToUse,
  children,
}: RootElementCreatorProps) => {
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const [routeLevelThemeEnabled, setRouteLevelThemeEnabled] =
    React.useState(false);
  const [flagCalled, setFlagCalled] = React.useState(false);

  useEffectOnce(() => {
    (async () => {
      const routeThemeResponse = await featureFlagsApi.getBinaryFlag(
        ROUTE_THEME,
      );
      setRouteLevelThemeEnabled(routeThemeResponse.data);
      setFlagCalled(true);
    })();
  });

  if (flagCalled) {
    return themeToUse && routeLevelThemeEnabled ? (
      <ThemeProvider theme={themeToUse}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    ) : (
      <ThemeProvider theme={usgTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    );
  }
  return <></>;
};
