import { useApi } from '@backstage/core-plugin-api';
import { statuspageApiRef } from '../api';
import useAsync from 'react-use/lib/useAsync';
import { Component } from '../types';

export const useComponent = (componentId: string | null) => {
  const statuspageApi = useApi(statuspageApiRef);

  return useAsync(async (): Promise<Component | null> => {
    if (!componentId) {
      return null;
    }
    return await statuspageApi.getComponentStatus(componentId);
  }, []);
};
