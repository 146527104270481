import React from 'react';

import { ArtifactoryGitHubWorkflowAccessHeader } from './ArtifactoryGitHubWorkflowAccessHeader';
import { BaseArtifactoryTable } from './BaseArtifactoryTable';

/**
 * ArtifactoryGitHubWorkflowAccessEditTable Component
 *
 * This component renders a table for managing GitHub Workflow access configuration
 * for the Resource Self Hosted Artifactory.
 *
 * @param {Object} props - The component props
 * @param {string} props.name - The name of the component
 * @param {Array} props.artifactoryProperties - The array of artifactory properties
 * @param {Function} props.setArtifactoryProperties - The function to update artifactory properties
 * @returns {JSX.Element} - Returns the JSX element for the component
 */
export const ArtifactoryGitHubWorkflowAccessEditTable = (props: {
  name: string;
  artifactoryProperties: any;
  setArtifactoryProperties: (artifactoryProperties: any) => void;
}) => {
  const { name, artifactoryProperties, setArtifactoryProperties } = props;

  return (
    <>
      <ArtifactoryGitHubWorkflowAccessHeader />

      <BaseArtifactoryTable
        name={name}
        artifactoryProperties={artifactoryProperties}
        setArtifactoryProperties={setArtifactoryProperties}
        editMode
      />
    </>
  );
};
