import React from 'react';
import { Grid } from '@material-ui/core';
import { getCompoundEntityRef } from '@backstage/catalog-model';

import { techInsightsApiRef } from '@backstage-community/plugin-tech-insights';
import { BottomLinkProps, GaugeCard } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';

export const ScoreCard = (props: { linkInfo?: BottomLinkProps }) => {
  const { linkInfo } = props;
  const techInsightsApi = useApi(techInsightsApiRef);
  const { entity } = useEntity();
  const checks = useAsync(async () => await techInsightsApi.getAllChecks());
  const checksList = checks.value?.map(check => check.id) ?? [];
  const { value } = useAsync(
    async () => await techInsightsApi.runChecks(getCompoundEntityRef(entity)),
  );

  const trueCount =
    value?.reduce((count, obj) => (obj.result ? count + 1 : count), 0) ?? 0;
  let score = 0;
  if (checksList?.length > 0) score = trueCount / checksList?.length;
  return (
    <Grid item md={2}>
      <GaugeCard
        title="Scorecard"
        progress={score}
        deepLink={linkInfo ? linkInfo : undefined}
      />
    </Grid>
  );
};
